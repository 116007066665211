// @ts-nocheck
const handler = {
    get: function (target: any, name: string) {
      return (name in target) && target[name] ? target[name] : APP_URLS.base_api_gateway_url;
    }
};

const Urls = {
    base_api_gateway_url: APP_URLS?.base_api_gateway_url,
    expo_base_api_gateway_url: APP_URLS?.expo_base_api_gateway_url,
    cms_base_api_gateway_url: APP_URLS?.cms_base_api_gateway_url,
    curriculum_base_api_gateway_url: APP_URLS?.curriculum_base_api_gateway_url,
    reporting_base_api_gateway_url: APP_URLS?.reporting_base_api_gateway_url,
    omniauth_gateway_url: APP_URLS?.omniauth_gateway_url,
    cms_image_asset_url: APP_URLS?.cms_image_asset_url
}

const BaseUrls: typeof Urls = new Proxy(Urls, handler);

export default BaseUrls;