import { combineReducers } from "redux";
import { appFlags } from "./appFlags";
import { assessmentData } from "./assessmentData";
import { assignedFactPractices } from './assignedFactPractices';
import { assignedFactPracticesToKlass } from "./assignedFactPracticesToKlass";
import { assignmentData } from "./assignmentData";
import { classPasswordSettings } from "./classPasswordSettings";
import { classReportData } from "./classReportData";
import { classroom_settings } from "./classroom_settings";
import { classRosterData } from "./classRosterData";
import { commonPopupState } from "./commonPopupState";
import { contentWiseLoSeedData } from "./contentWiseLoSeedData";
import { currentKlass } from "./currentKlass";
import { curriculumData } from "./curriculumData";
import { curriculumSeedData } from "./curriculumSeedData";
import { customTestReport } from "./customTestReport";
import { dailyActivityReportData } from "./dailyActivityReportData";
import { dashboardData } from "./dashboardData";
import { gradeSeedData } from "./gradeSeedData";
import { klassStudents } from "./klassStudents";
import { mathFactReportingData } from "./mathFactReportingData";
import { mathFactSeedData } from "./mathFactSeedData";
import { seed_data_cache } from "./seed_data_cache";
import { selectedCurrentData } from "./selectedCurrentData";
import { settingsData } from "./settingsData";
import { studentReportData } from "./studentReportData";
import { studentsList } from "./studentsList";
import { teacherProfile } from "./teacherProfile";
import { teacherSurveySeedData } from "./teacherSurveySeedData";
import { abTestData } from "./abTestData";
import { worksheetValue } from "./worksheetValue";
import { feedbackData } from "./feedbackData";
import { feedbackSeedData } from "./feedbackSeedData";
import { feedbackEligibility } from "./feedbackEligibility";
import { contest } from "./contest";
import { fullScreen } from "./fullScreen";
import {teacherProperties} from './teacherProperties';
import {klassProperties} from './klassProperties';

const REDUCERS = {
    assignedFactPractices,
    assignedFactPracticesToKlass,
    mathFactSeedData,
    mathFactReportingData,
    commonPopupState,
    assessmentData,
    assignmentData,
    classReportData,
    curriculumData,
    dashboardData,
    studentReportData,
    dailyActivityReportData,
    classRosterData,
    classPasswordSettings,
    settingsData,
    classroom_settings,
    klassStudents,
    curriculumSeedData,
    contentWiseLoSeedData,
    teacherProfile,
    gradeSeedData,
    selectedCurrentData,
    currentKlass,
    seed_data_cache,
    studentsList,
    appFlags,
    customTestReport,
    teacherSurveySeedData,
    abTestData,
    worksheetValue,
    feedbackSeedData,
    feedbackData,
    feedbackEligibility,
    contest,
    fullScreen,
    teacherProperties,
    klassProperties
}

export const rootReducer = (state = {}, action) => {
    const finalState = Object.keys(REDUCERS).reduce((acc, reducerName)=> {
        acc[reducerName] = REDUCERS[reducerName](state[reducerName], action, state);
        return acc;
    }, {})
    return finalState;
}
