import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {};

export const classroom_settings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_CLASSROOM_SETTINGS":
            return {
                ...state,
                ...action.payload
            };
        default:
            return state
    }
}