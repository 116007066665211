import { Themes, ThemesName } from "./themes";

// Hexadecimal to Decimal
function hexToDecimal(hex: string) {
    return parseInt(toggleHash(hex), 16);
}

// Decimal to Hexadecimal
function decimalToHex(decimal: number) {
    return toggleHash(decimal.toString(16).toUpperCase());
}

function toggleHash(hexColor: string) {
    if (hexColor.startsWith("#")) {
        return hexColor.substring(1, 7); // Remove "#" if it exists
    } else {
        return "#" + hexColor; // Add "#" if it's missing
    }
}

export const generateTheme = (primaryBackground: string) => {
    const defaultTheme = Themes[ThemesName.default];
    const distance =  hexToDecimal(defaultTheme.primaryBackground) - hexToDecimal(primaryBackground);
    const theme = Object.keys(defaultTheme).reduce((acc: any, key: any) => {
        const newColor = hexToDecimal(defaultTheme[key]) - distance;
        const temp = newColor < 0 ? newColor + hexToDecimal(defaultTheme.primaryBackground) : newColor
        acc[key] = decimalToHex(Math.abs(temp))
        return acc;
    }, {}); 

    return theme;
}

