import React from "react";
import { assetPath } from "../../utils/school-ui-helpers";
import DownloadWorksheetImage from "TEACHER_ASSETS/images/worksheet/download-worksheet.png";
import BackArrow from '../../assets/common/backArrowGrey.svg'
import Button, {ButtonTypes} from "../common/Button";
import LazyLoadImage from "../common/LazyLoadImage";

const EmptyDownloadHistory = ({onGoToDownloadWorksheets = null}) => {

  const goBack = () => {
    location.hash="";

  }
  return (
    <div className="empty-saved-worksheets">
      <div className="empty-saved-worksheets-body">
        <LazyLoadImage src={assetPath(DownloadWorksheetImage)} alt="No downloaded worksheet" height="116" width="116" />
        <div className="main-text">There’s no worksheet downloaded yet</div>
        <p className="fixed-width">Download history will be shown here once you download some worksheets.</p>
        {!onGoToDownloadWorksheets ? (
          <Button type={ButtonTypes.primary} onClick={goBack}>
            Download worksheets
          </Button>
        ): (
          <Button type={ButtonTypes.primary} onClick={onGoToDownloadWorksheets}>
            Download worksheets
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyDownloadHistory;