namespace Fonts {
    export enum Family {
        GRAPHIE = "Graphie"
    }

    export enum Style {
        NORMAL = "Normal"
    }

    export enum Size {
        S10 = 10,
        S12 = 12,
        S14 = 14,
        S16 = 16,
        S18 = 18,
        S20 = 20,
        S24 = 24,
        S32 = 32,
        S36 = 36,
        S44 = 44
    }

    export enum Height {
        H58 = 58,
        H52 = 52,
        H46 = 46,
        H42 = 42,
        H32 = 32,
        H26 = 26,
        H24 = 24,
        H20 = 20,
        H18 = 18,
        H16 = 16,
        H14 = 14,
    }

    export enum Weight {
        W400 = 400,
        W600 = 600,
        W700 = 700
    }
}

export default Fonts;