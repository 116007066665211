namespace Device {
    export enum types {
        Desktop = 'desktop',
        Tablet = 'tablet',
        Mobile = 'mobile',
        MobileLandscape = 'mobile-landscape',
    }
    
    export const breakpoints: {[k in types]?: {min?: number, max?: number}} = {
        [types.Desktop]:  {min: 1280},
        [types.Tablet]:  {min: 1024, max: 1279},
        [types.Mobile]:  {min: 320, max: 1023},
    };
}

export default Device;