import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    klass_id: "",
    message: "Welcome to Daily Activity Reports",
  };

export const dailyActivityReportData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state
    }
}