import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {}

export const classRosterData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_CLASS_ROSTER_DATA":
            return action.payload || INITIAL_STATE;
          
        default:
            return state
    }
}