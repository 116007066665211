import { BoxPropsType } from "..";

export enum FlexStyles {
    DEFAULT = "DEFAULT"
}

export namespace FlexProps {
    export enum FlexDirection {
        ROW = 'row',
        ROW_REVERSE = 'row-reverse',
        COLUMN = 'column',
        COLUMN_REVERSE = 'column-reverse',
    }

    export enum JustifyContent {
        FLEX_START = 'flex-start',
        FLEX_END = 'flex-end',
        CENTER = 'center',
        SPACE_BETWEEN = 'space-between',
        SPACE_AROUND = 'space-around',
        SPACE_EVENLY = 'space-evenly',
    }

    export enum AlignItems {
        FLEX_START = 'flex-start',
        FLEX_END = 'flex-end',
        CENTER = 'center',
        BASELINE = 'baseline',
        STRETCH = 'stretch',
    }

    export enum AlignContent {
        FLEX_START = 'flex-start',
        FLEX_END = 'flex-end',
        CENTER = 'center',
        SPACE_BETWEEN = 'space-between',
        SPACE_AROUND = 'space-around',
        STRETCH = 'stretch',
    }

    export enum FlexWrap {
        NOWRAP = 'nowrap',
        WRAP = 'wrap',
        WRAP_REVERSE = 'wrap-reverse',
    }
}

export type FlexBoxPropsType = BoxPropsType & {
    column?: boolean;
    alignStart?: boolean;
    spaceBetween?: boolean;
    noWrap?: boolean;
    justifyContent?: FlexProps.JustifyContent;
    alignItems?: FlexProps.AlignItems;
    alignContent?: FlexProps.AlignContent;
    flexWrap?: FlexProps.FlexWrap;
    gap?: number;
    center?: boolean;
    ref?: any;
}