import axios from 'axios';
import { CommonMessages } from '../../utils/constants';

const axiosClient = axios.create({
    baseURL: "",
    credentials: "same-origin",
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
        "X-Requested-With": "XMLHttpRequest",
    }
});

// Interceptor will be written here
// axiosClient.interceptors.request.use();
axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let errorMessage = '';
        let reloadFlag = false;
        let noReloadOnError = false;
        let noFlashMessageOnError = checkForNoFlashMessageOnError(error);

        switch(error.response.status) {
            case 401:
                reloadFlag = true;
            break;
            case 403: // will handle
            case 402: // will handle
            case 500: // will handle
            case 412:
            case 0:
                noFlashMessageOnError = true;
                break;
            default:
                errorMessage = CommonMessages.commonErrorMessage;
        }

        if(error.response.data) {
            const errorData = error.response.data || {};
            const message = errorData.debug_message || errorData.debugMessage || errorData.message || errorData.errorData;
            if(typeof message === 'string') {
                errorMessage = message;
            }
        }

        if(errorMessage && !noFlashMessageOnError) {
            showFlashMessage(errorMessage, 'error');
        }

        noReloadOnError = checkForNoReloadOnError(error);

        console.error("API Response Error ====", error.message, errorMessage, JSON.stringify(error?.response?.data), JSON.stringify(error?.response));

        if(reloadFlag && !noReloadOnError) {
            window.location.reload(true);
        }

        return Promise.reject(error);
    }
);

const checkForNoReloadOnError = (error) => {
    let flag = false;
    if(error.config.url.includes('verify_password'))
        flag = true;
    return flag;
}

const checkForNoFlashMessageOnError = (error) => {
    let flag = false;
    if(error.config.url.includes('verify_password'))
        flag = true;
    return flag; 
}

export {
    axiosClient as AxiosClient
};