const FallbackFonts = `'Helvetica', 'Arial', 'sans-serif'`;

export const styledFont = (fontStyle, fs=1, lh, fw=6, unit='px') =>{
  return `
      font-size: ${fs}${unit};
      line-height: ${lh ? lh+unit: "normal"};
      font-weight: ${fw*100};
      font-family: ${fontStyle}, ${FallbackFonts};
      font-style: normal;
    `
}

export const fontGraphie = (fs=1, lh=0, fw=6, unit='px') => {
    return styledFont('Graphie', fs, lh, fw, unit);
}