import Device from "baseStyles/enums/device";
import { SuiStylesType, useSuiStyles } from "baseStyles/useSuiStyles";
import { FlexBoxPropsType } from "schoolUiComponents/basic/Box/FlexBox/types";
import { InputPropsType } from "schoolUiComponents/basic/Input/types";

type PropsType = {}

type StylesType = {
    mainBox: FlexBoxPropsType;
}

const Styles: SuiStylesType<PropsType, StylesType> = {
    [Device.types.Desktop]: ({theme}) => {
        return {
            mainBox: {
                width: 421,
            }
        }
    },
    [Device.types.Tablet]: (props) => {
        return {
            mainBox: {
                width: 400,
            }
        }
    },
    [Device.types.Mobile]: (props) => {
        return {
            mainBox: {
                width: 327
            }
        }
    }
}

const LoginStyles = useSuiStyles(Styles);

export default LoginStyles;