import { CapitalizeFirstLetter, DecodeCharFromHTMLEntity } from "apps/teacher/config/class_left_nav_container/common";
import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = [];

export const klassStudents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_KLASS_STUDENTS_DETAILS" :
            return (action.payload ? action.payload.sort((a,b)=>a.first_name > b.first_name ? 1 : -1).map((student) => ({
                ...student,
                first_name:  student?.first_name?.length ? CapitalizeFirstLetter(DecodeCharFromHTMLEntity(student?.first_name)) : student?.first_name, 
                last_name: student?.last_name?.length ?  CapitalizeFirstLetter(DecodeCharFromHTMLEntity(student?.last_name)) : student?.last_name
            })) : [])
        default:
            return state
    }
}