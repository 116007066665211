import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = []

export const studentsList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_STUDENTS_LIST":
            return action.payload || []
        default:
            return state
    }
}