import { useState, useEffect, RefObject } from 'react'
import 'intersection-observer';

// This custom hook is used to determine if an element is in the visible viewport of the user.
const useIsInViewport = (callback: () => void, element: RefObject<HTMLDivElement | HTMLInputElement>, rootMargin: string = '0px', fireOnce: boolean = true, fullView: boolean = true) => {
    let observer: IntersectionObserver;

    useEffect(() => {
        if(element?.current) {
            observer = new IntersectionObserver(
                ([entry]) => {
                    if(fireOnce){
                        if (entry?.isIntersecting) {
                            callback();
                            observer?.unobserve(element?.current);
                        }
                    } else {
                        callback();
                    }
                }, {
                    rootMargin,
                    threshold: fullView ? 1.0 : null
                 }
            );
            observer?.observe(element.current);
        }

        return () => {
            element?.current && observer?.unobserve(element?.current);
        }
    }, [element]);
};

export default useIsInViewport;