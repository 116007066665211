import React from "react";

export const SearchDropdownFallback = () => {

  const renderSearchDropdown = () => {
    let steps = [1,2];
    return steps.map(step => {
      return (
        <div className="individual-row" key ={step}>
          <div className="column-1 animate"/>
          <div className="column-2 animate"/>
        </div>
      )
    })
  }

  return (
    <div className="search-dropdown-wrap">
      <div className="search-dropdown-fallback">
        {renderSearchDropdown()}
      </div>
    </div>
  )
}