import { deepCopyObject } from "../../../common/common_utility";
import ReducerConstants from "../../config/ReduxConstant";
import { DataModels } from "../models";

const INITIAL_STATE = DataModels.MathFactReportingModel.initialState();

export const mathFactReportingData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_FACTS_REPORTING_DATA:
            return deepCopyObject(action.payload);
        default:
            return state
    }
}