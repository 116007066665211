export enum Colors {
    DARK_GREY = "#1A1A1A",
    DARK_GREY_2 = "#575757",
    MEDIUM_GREY_1 = "#6C6C73",
    MEDIUM_GREY_2 = "#8F8F9B",
    LIGHT_GREY_1 = "#CACAD8",
    LIGHT_GRAY_1 = "#D6D6D6",
    LIGHT_GREY_2 = "#DCDCEA",
    LIGHT_GRAY_2 = "#EDEDED",
    LIGHT_GRAY_3 = "#EEEFF3",
    LIGHT_GREY_3 = "#EDEDF9",
    LIGHT_GREY_4 = "#F8F8FD",
    LIGHT_GREY_5 = "#E7E7F2",
    WHITE = "#FFFFFF",
    DARK_NAVY_BLUE = "#090A4A",
    NAVY_BLUE = "#141975",
    NAVY_BLUE_1 = "#34389F",
    NAVY_BLUE_2 = '#264AB6',
    GRAY_NAVY_BLUE = "#2B2C63",
    GRAY_NAVY_BLUE_2 = "#494A88",
    PURE_BLUE = "#2284E2",
    LIGHT_BLUE_1 = "#A7CEF3",
    LIGHT_BLUE_2 = "#D3E6F9",
    LIGHT_BLUE_3 = "#EDF5FD",
    LIGHT_BLUE_4 = "#EAF4FF",
    LIGHT_BLUE_5 = '#4274F6',
    PURE_PURPLE = "#4F52C3",
    PURE_PURPLE_7 = "#2E379E",
    DARK_PURPLE = "#38397B",
    MEDIUM_PURPLE = "#797CDB",
    DULL_PURPLE = "#989BEC",
    PURPLE = "#E6E7FD",
    LIGHT_PURPLE_1 = "#BDBFF9",
    LIGHT_PURPLE_2 = "#DEDFFC",
    LIGHT_PURPLE_3 = "#E6E7FD",
    MAGENTA_3 = "#8535A9",
    MAGENTA_4 = "#B746E6",
    MAGENTA_8 = "#F1DAFA",
    MINT_GREEN = "#537D74",
    DARK_GREEN = "#4CAB78",
    PURE_GREEN = "#69D59B",
    LIGHT_GREEN = "#F3FCF7",
    LIGHT_GREEN_1 = "#83C034",
    LIGHT_GREEN_2 = "#68a526",
    DARK_GREEN_1 = "#537d74",
    DARK_GREEN_2 = "#2F8973",
    SUCCESS_GREEN = '#DCFAE9',
    DARK_YELLOW = "#D7A22B",
    PURE_YELLOW = "#FFC543",
    LIGHT_YELLOW_1 = "#FFE8B4",
    LIGHT_YELLOW_2 = "#FFF3D9",
    LIGHT_YELLOW_3 = "#FFFAF0",
    DARK_ORANGE = "#C55314",
    PURE_ORANGE = "#EE7532",
    PURE_ORANGE_1 = "#FD9B7D",
    PURE_ORANGE_2 = "#F9805D",
    LIGHT_ORANGE = "#FFF5F2",
    DARK_RED = "#D23446",
    PURE_RED = "#F1465A",
    LIGHT_RED = "#FDF1F1",
    LIGHT_RED_2 = '#F5E1E1',
    DARK_RED_2 = "#B84040",
    PURE_BLACK = "#000000",
    HOVER_PURPLE = "#9d9ffc1a",
    FADED_BLUE = "#D3E6F9",
    PRIMARY_BLUE = "#2284E2",
    FADED_PURPLE = "#e5e5fd",
    FULL_PURPLE = "#5052c3",
    CONTENT_TEXT_PRIMARY = "#1a1a1a",
    CONTENT_TEXT_SECONDARY = "#484848",
    CONTENT_TEXT_TERTIARY = "#767676",
    CONTENT_NOTICE_INFO = '#1A63A6',
    WHITE_1 = "#FAFAFF",
    WHITE_2 = "#F7F7FA",
    LIGHT_PURPLE_4 = "#d7d9fb",
    LIGHT_PURPLE_5 = "#e4e4f9",
    LIGHT_GREY_6 = "#f5f5ff",
    LIGHT_PINK = "#FFE6DF",
    TRANSPARENT = "transparent",
    TEXT_FIELD_BORDER_DEFAULT = "#A2A2AD",
    CONTENT_TEXT_DISABLED = "#1a1a1a80",
    CONTENT_SECONDARY_HYPERLINK = "#1a1a1acc",
    DARK_BLUE_1 = '#2C3195',
    CONTENT_NOTICE_ERROR = '#901818',
    CONTENT_NOTICE_ALERT = '#533B03',
    DARK_BLACK = '#171717',
    DARK_BLACK_1 = '#0A0A0A',
    LIGHT_GREY = '#757575',
    TEXT_ERROR = '#E94E4E',
    PURPLE_PINK_TEXT = "#B146E6",
    BLACK = "#000000",
    CONTENT_NOTICE_ALERT_WBG = '#865106',
    LIGHT_DULL_TEXT = '#CCE6FF',
    LIGHT_TEXT = '#F2F9FF'
}