import React from "react";
import { BoxPropsType } from "..";

// Enum representing different styles of text
export enum PositionBoxTypes {
  RELATIVE = "relative",
  ABSOLUTE = "absolute",
  FIXED = "fixed",
  STICKY = "sticky"
}

// Type representing the properties of a text component
export type PositionBoxPropsType = BoxPropsType & {
  position?: PositionBoxTypes;
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
  zIndex?: number | string;
}

// Type representing an object that maps each text style to a React functional component
export type PositionBoxComponentsType = {
  [K in PositionBoxTypes]: React.FC<PositionBoxPropsType>;
};