export const FEATURE_SLUG_MAP = {
    DASHBOARD: "dashboard",
    ASSIGN_CURRICULUM: "assign",
    NEW_ASSIGNMENT: "curriculum",
    TRACK_ASSIGNMENT: "class_assignment",
    ASSIGN_FACT: "math_fact",
    ASSIGN_PRACTICE: "assign_practices",
    TRACK_PRACTICE: "track_practices",
    WORKSHEET: "worksheet",
    ASSESS: "assess",
    NEW_ASSESSMENT: "class_assessment",
    TRACK_ASSESSMENT: "track_assessment",
    REVIEW: "review",
    DAILY_ACTIVITY: "daily-activity",
    CLASS_PROGRESS: "performance-overview",
    STUDENT_PROGRESS: "performance-by-student",
    MANAGE_CLASS: "manage_class",
    MANAGE_ALL_CLASS: "manage",
    STUDENT_ROSTER: "roster",
    CLASS_SETTINGS: "classroom_setting",
    SPLASH_COMMUNITY: "splash-community",
    SUMMER_LEARNING_PROGRAM: "SummerLearning",
    MATH_WORKSHEET: "math_worksheet",
    ELA_WORKSHEET: "ela_worksheet",
    MATH_LESSON_PLANS: "math_lesson_plans",
    ENGLISH_LESSON_PLANS: "english_lesson_plans",
    REINFORCEMENT: "reinforcement",
    MATH_LIVE_CLASS: "math_live_classes",
    READING_LIVE_CLASS: "reading_live_classes",
    BOOK_LIBRARY: "book_library",
    BOOK_LIBRARY_P3: 'book_library_p3',
    EXPLORE_BOOKS: "explore_books",
    TRACK_BOOKS: "track_books",
    MY_LIBRARY: 'my_library',
}