import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    message: "Welcome to Student Reports",
    student_klass_details: {},
    studentList: {},
    studentListArr: [],
    curriculumList: [],
    individualStudentContentGroups: [],
    individualStudentStandardDetailedReport: [],
};

let studentReportParseStudentListToArray = (params) => {
    let returnData = Object.keys(params).map(function (individualData) {
        return {
            id: individualData,
            first_name: params[individualData].first_name,
            last_name: params[individualData].last_name,
            fullname: params[individualData].first_name + ' ' + params[individualData].last_name
        }
    });
    return returnData;
}

let updateIndividualStudentAssignmentStatusFunction = (data, payload) => {
    let params = payload.params;
    let loIndex = params.loIndex;

    //added fix for sw where assigned text was not getting updated.
    //loIndex is actually getting the value of groupingIndex but used as loIndex only and hence not updating the ui.
    //for the fix we are fetching the loIndex from groupingIndex and hence fixing the issue.
    if (params.grouping_id) {
        loIndex = data[params.standaredIndex].learning_objectives.findIndex(data => data.grouping.id == params.grouping_id)
    }
    data[params.standaredIndex].learning_objectives[loIndex].assigned = true;
    for (let key in payload.callbackData) {
        data[params.standaredIndex].learning_objectives[loIndex][key] = payload.callbackData[key];
    }
    return data;
}

export const studentReportData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_STUDENT_STUDENT_LIST:
            return { ...state, studentList: action.payload, studentListArr: studentReportParseStudentListToArray(action.payload) }
        case "SET_STUDENT_INDIVIDUAL_STUDENT_CONTENT_GROUPS":
            return { ...state, individualStudentContentGroups: action.payload };
        case "SET_STUDENT_INDIVIDUAL_STUDENT_STANDARD_DETAILED_REPORT":
            return { ...state, individualStudentStandardDetailedReport: action.payload };
        case "UPDATE_INDIVIDUAL_STUDENT_ASSIGNMENT_STATUS":
            return { ...state, individualStudentStandardDetailedReport: updateIndividualStudentAssignmentStatusFunction(state.individualStudentStandardDetailedReport, action.payload) }
        default:
            return state
    }
}