import * as Inspector from "avo-inspector";

export let inspector = new Inspector.AvoInspector({
  apiKey: APP_SECRETS.avo.apiKey,
  env: APP_MODE === "production" ? Inspector.AvoInspectorEnv.Prod : Inspector.AvoInspectorEnv.Dev,
  version: "1.0.0",
  appName: "Teacher Dashboard",
});

export default inspector;
