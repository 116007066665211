import React from "react";
import _ErrorBoundary from "./ErrorBoundary";

export const ErrorBoundary = _ErrorBoundary;

export const withErrorBoundary = (Component) => {
    return (props) => {
        return (
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        )
    }
}