import React from "react";
import { sendErrorToSentry } from "./sendErrorToSentry";
import DefaultEvents from "avo/events/global";

class _ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: {},
            errorInfo: null,
            eventId: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const eventId = sendErrorToSentry(error, errorInfo);
        this.setState({ hasError: true, error, errorInfo, eventId }, ()=>{
            const taskToDefer = () => {
                DefaultEvents.ErrorMessageDisplayedB2b(JSON.stringify({
                    eventId, 
                    error: error?.message,
                    stack: error?.stack,
                    errorInfo
                }));
              }
              deferTask({taskToDefer: taskToDefer, timeOut: 0})
        });
    }

    render() {
        const { hasError} = this.state;
        if (hasError) {
            return (
                <div className="text-center"><strong>Uh-oh! Something went wrong. <a onClick={() => { window.location.reload(true); }}>Refresh this page</a> or try again later.</strong></div>
            )
        }

        return this.props.children;
    }
}

export default _ErrorBoundary;
