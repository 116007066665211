import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    grades: []
};

export const gradeSeedData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_GRADE_SEED_DATA":
            return action.payload || INITIAL_STATE;
        default:
            return state
    }
}