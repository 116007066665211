import ReducerConstants from "../../config/ReduxConstant";
import { DataModels } from "../models";

const INITIAL_STATE = DataModels.MathFactSeedDataModel.initialState();

export const mathFactSeedData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_FACTS_SEED_DATA:
            return new DataModels.MathFactSeedDataModel(action.payload)
        default:
            return state
    }
}