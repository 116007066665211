import { useSuiStyles,SuiStylesType } from "baseStyles/useSuiStyles";
import Device from "baseStyles/enums/device";
import { Colors } from "baseStyles/enums/colors";
import { FlexBoxPropsType, FlexProps } from "schoolUiComponents/basic/Box/FlexBox/types";
import { TextPropsType } from "schoolUiComponents/basic/Text/types";

type StylesType = {
    scrollButton: FlexBoxPropsType
}

const Styles: SuiStylesType<{}, StylesType> = {
    [Device.types.Desktop]: ({ theme }) => {
        return {
            scrollButton: {
                padding: 6,
                width: 28, 
                height: 28,
                transition: true,
                center: true,
                round: true
            }
        }
    },
    [Device.types.Tablet]: ({ theme }) => {
        return {
            scrollButton: {
                width: 26, 
                height: 26
            }
        }
    }
}

const ScrollerStyles = useSuiStyles(Styles);

export default ScrollerStyles;