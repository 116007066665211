import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = ""

export const worksheetValue = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_WORKSHEET_VALUE:
            return action.payload
        default:
            return state
    }
}