import Store from 'apps/teacher/store';
import { dispatchAvoEvent } from '..';
import { AVO_EVENT_NAMES } from '../eventNames';

interface IPlayableClickedProps {
    recommendedRailName: any;
    recommendedTileRank: any;
    recommendedRailRank: any;
    gameType: string;
    gameCode: string;
    from: string;
    gameEngine: string;
    contentType: string;
    playableCode: string;
    gameName: string;
    playableName: string;
    playableType: string;
    pageName: string;
    assignmentType: string;
}

const sendEventUsingAvo = (props: any = {}) => {
    const initParams = {
        //@ts-ignore
        klassGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        currentGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        subjectCode: window.KLASS_DETAIL?.id ? "Math" : '', //@ts-ignore
        klass: window.KLASS_DETAIL,
    };

    dispatchAvoEvent(props, initParams);
};

const ErrorMessageDisplayedB2b = (valueName: string): void => {
    sendEventUsingAvo({eventName: AVO_EVENT_NAMES.errorMessageDisplayedB2b, props: {valueName}});
}

const firePlayableClickedEvent = (props: IPlayableClickedProps): void => {
    const {currentKlass} = Store.getState();
    const initParams = {
        //@ts-ignore
        grade: window.eventInitProps?.klassGrade?.toLowerCase() || currentKlass?.klass?.gradeCode?.toLowerCase(),
        mathGrade: window.eventInitProps?.currentGrade?.toLowerCase() || currentKlass?.current_course?.grade_code || "NA",
        elaGrade: window.eventInitProps?.currentGrade?.toLowerCase() || currentKlass?.current_course?.grade_code || "NA",
        subject: (window.eventInitProps?.subjectCode || currentKlass?.current_course?.subject_code) ? "math" : '',
    };
    props.recommendedRailName = [props.recommendedRailName || ""];
    props.recommendedTileRank = [props.recommendedTileRank || 0];
    props.recommendedRailRank = [props.recommendedRailRank || 0];
    sendEventUsingAvo({eventName: AVO_EVENT_NAMES.playableClicked, props: {...initParams, ...props}, teacherCoreProperties: false, studentCoreProperties: true});
}

const DefaultEvents = {
    ErrorMessageDisplayedB2b,
    firePlayableClickedEvent
}

export default DefaultEvents;