import { useCallback, useRef, useState } from "react";

export const useClickThrottle = (func: Function, delay: number = 0) => {
    const timeoutRef = useRef(null);

    if(!func || !delay) {
        return func;
    }

    const clearTimeoutRef  = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    } 

    return function(...args: any[]) {
        if(!timeoutRef.current) {
            timeoutRef.current = setTimeout(clearTimeoutRef, delay);
            func(...args)
        }
    };
}