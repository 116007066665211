import Fonts from "baseStyles/enums/fonts";
import { TextStylesTypes } from "./types";
import { useSuiStyles, SuiStylesType } from "baseStyles/useSuiStyles";
import Device from "baseStyles/enums/device";

type fontStyleType = {
    fontSize: Fonts.Size,
    lineHeight: Fonts.Height,
    fontWeight: Fonts.Weight
}

type StylesType = {
    [k in TextStylesTypes]: fontStyleType;
}

const getFontStyle = (size: Fonts.Size, weight: Fonts.Weight, height: Fonts.Height): fontStyleType => {
    return {
        fontSize: size,
        lineHeight: height,
        fontWeight: weight
    }
}

const Styles: SuiStylesType<{}, StylesType> = {
    [Device.types.Desktop]: ({theme}) => {
        return {
            [TextStylesTypes.HS1]: getFontStyle(Fonts.Size.S44, Fonts.Weight.W700, Fonts.Height.H58),
            [TextStylesTypes.HS2]: getFontStyle(Fonts.Size.S36, Fonts.Weight.W700, Fonts.Height.H46),
            [TextStylesTypes.H1]: getFontStyle(Fonts.Size.S32, Fonts.Weight.W700, Fonts.Height.H42),
            [TextStylesTypes.H2]: getFontStyle(Fonts.Size.S24, Fonts.Weight.W600, Fonts.Height.H32),
            [TextStylesTypes.H3]: getFontStyle(Fonts.Size.S20, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.H4]: getFontStyle(Fonts.Size.S18, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.NORMAL]: getFontStyle(Fonts.Size.S16, Fonts.Weight.W400, Fonts.Height.H24),
            [TextStylesTypes.SMALL]: getFontStyle(Fonts.Size.S14, Fonts.Weight.W400, Fonts.Height.H20),
            [TextStylesTypes.CAPTION]: getFontStyle(Fonts.Size.S14, Fonts.Weight.W400, Fonts.Height.H20),
            [TextStylesTypes.FOOTER]: getFontStyle(Fonts.Size.S12, Fonts.Weight.W400, Fonts.Height.H18),
        }
    },
    [Device.types.Tablet]: (props) => {
        return {
            [TextStylesTypes.HS1]: getFontStyle(Fonts.Size.S36, Fonts.Weight.W700, Fonts.Height.H46),
            [TextStylesTypes.HS2]: getFontStyle(Fonts.Size.S32, Fonts.Weight.W700, Fonts.Height.H42),
            [TextStylesTypes.H1]: getFontStyle(Fonts.Size.S24, Fonts.Weight.W700, Fonts.Height.H32),
            [TextStylesTypes.H2]: getFontStyle(Fonts.Size.S20, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.H3]: getFontStyle(Fonts.Size.S18, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.H4]: getFontStyle(Fonts.Size.S16, Fonts.Weight.W600, Fonts.Height.H24),
            [TextStylesTypes.NORMAL]: getFontStyle(Fonts.Size.S14, Fonts.Weight.W400, Fonts.Height.H20),
            [TextStylesTypes.SMALL]: getFontStyle(Fonts.Size.S14, Fonts.Weight.W400, Fonts.Height.H20),
            [TextStylesTypes.CAPTION]: getFontStyle(Fonts.Size.S12, Fonts.Weight.W400, Fonts.Height.H18),
            [TextStylesTypes.FOOTER]: getFontStyle(Fonts.Size.S10, Fonts.Weight.W400, Fonts.Height.H14),
        }
    },
    [Device.types.Mobile]: (props) => {
        return {
            [TextStylesTypes.HS1]: getFontStyle(Fonts.Size.S36, Fonts.Weight.W700, Fonts.Height.H46),
            [TextStylesTypes.HS2]: getFontStyle(Fonts.Size.S32, Fonts.Weight.W700, Fonts.Height.H42),
            [TextStylesTypes.H1]: getFontStyle(Fonts.Size.S24, Fonts.Weight.W700, Fonts.Height.H32),
            [TextStylesTypes.H2]: getFontStyle(Fonts.Size.S20, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.H3]: getFontStyle(Fonts.Size.S18, Fonts.Weight.W600, Fonts.Height.H26),
            [TextStylesTypes.H4]: getFontStyle(Fonts.Size.S16, Fonts.Weight.W600, Fonts.Height.H24),
            [TextStylesTypes.NORMAL]: getFontStyle(Fonts.Size.S14, Fonts.Weight.W400, Fonts.Height.H20),
            [TextStylesTypes.SMALL]: getFontStyle(Fonts.Size.S12, Fonts.Weight.W400, Fonts.Height.H18), // changed
            [TextStylesTypes.CAPTION]: getFontStyle(Fonts.Size.S12, Fonts.Weight.W400, Fonts.Height.H18),
            [TextStylesTypes.FOOTER]: getFontStyle(Fonts.Size.S10, Fonts.Weight.W400, Fonts.Height.H14),
        }
    }
}

const TextStyles = useSuiStyles(Styles);

export default TextStyles;