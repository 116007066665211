import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {}

export const classPasswordSettings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_PASSWORD_SETTINGS_DATA":
            return action.payload || {}
        default:
            return state
    }
}