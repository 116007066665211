
const formatDateToUTC = (date = new Date()) => {
  if (!(typeof date === "object")) {
    date = new Date(date);
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (month < 10) {
    month = "0" + month
  }

  if (day < 10) {
    day = "0" + day
  }

  return year + "-" + month + "-" + day
}

const calculateDateFromToday = (days) => {
  if (typeof (days) === "string") {
    days = parseInt(days)
  }
  let date = new Date();
  let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
  return formatDateToUTC(last);
}


export const secondsToHms = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);

  let hDisplay = h > 0 ? h + " h " : "";
  let mDisplay = m >= 0 ? m + " min" : "";
  return hDisplay + mDisplay;
}

export const getDayNamesFromDate = (date) => {
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return dayNames[new Date(date).getDay()]
}

function convertSecondsToHMS(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  let timeStr = "";
  if (hours > 0) {
      timeStr += `${hours}h `;
  }
  if (minutes > 0) {
      timeStr += `${minutes}m `;
  }
  if (remainingSeconds > 0 || (hours == 0 && minutes == 0)) {
      timeStr += `${remainingSeconds}s`;
  }

  return timeStr.trim();
}

export const getTZDate = (date) => {
  return date+'T00:00:00'
}

export const commonDate = {
  CalculateDateFromToday: calculateDateFromToday,
  FormatDateToUTC: formatDateToUTC,
  ConvertSecondsToHMS: convertSecondsToHMS,
  GetDayNamesFromDate: getDayNamesFromDate
}