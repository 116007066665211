import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = []

export const abTestData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.AB_TEST_DATA:
            return  {
                ...state.abTestData,
                ...action.payload,
            }
        default:
            return state
    }
}