import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    message: "Welcome to Curriculum",
    contentGroup: [],
    curriculumList: [],
    contentStandards: {},
    teachingTools: [],
    setStudents: {},
};

export const curriculumData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_CURRICULUM_CONTENT_GROUPS":
            return { ...state, contentGroup: action.payload };
        case "SET_CURRICULUM_CONTENT_STANDARDS":
            return { ...state, contentStandards: action.payload };
        case "SET_CURRICULUM_TEACHING_TOOLS":
            return { ...state, teachingTools: action.payload };
        case "SET_CURRICULUM_STUDENTS":
            return { ...state, setStudents: action.payload };
        default:
            return state
    }
}