import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE: any = {}

export interface IActionProps {
  type: string,
  payload: any
}

export const fullScreen = (state = INITIAL_STATE, action: IActionProps) => {
  switch (action.type) {
    case ReducerConstants.SET_FULL_SCREEN_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
