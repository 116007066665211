import axios from "axios";

const BASE_API_GATEWAY_URL = APP_URLS?.base_api_gateway_url

const WEB_SERVICE_ENDPOINT = "web-service/apig/sessions";

const COMMON_HEADERS = {
    "X-CSRF-Token": document.querySelector('meta[name=csrf-token]')?.getAttribute("content"),
    "X-Requested-With": "XMLHttpRequest",
};


const ShowSpinner = (flag: boolean) => {
  const spinner =  document.getElementById('spinner') || document.getElementById('ajax-spinner-id');
  if(spinner) {
    spinner.style.display = flag ? 'block' : 'none';
  }
}

const commonPasswordLogin = async (email: string, password: string, source: string, student_id?: string, referral_type?: string, referrer_code?: string, new_ptl_flow?: boolean, trd_id?: any) => {
  ShowSpinner(true);
  let res: any = {};
  const searchParams = new URLSearchParams();
  searchParams.append("user[login]", email);
  searchParams.append("user[password]", password);
  searchParams.append("user[remember_me]", "0");
  searchParams.append("commit", "Login");
  student_id && searchParams.append("user[student_id]", student_id);
  referral_type && searchParams.append("referral_type", referral_type);
  referrer_code && searchParams.append("referrer_code", referrer_code);
  new_ptl_flow && searchParams.append("new_ptl_flow", new_ptl_flow?.toString());
  trd_id && searchParams.append("trd_id", trd_id?.toString());

  try {
    res = await axios.post(
      `/users/sign_in.json?source=${encodeURIComponent(source)}&send_to_mixpanel=true`,
      searchParams,
      {
        headers: {
          ...COMMON_HEADERS,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      }
    );
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
};

const sendOTP = async (email: string, id: string, user_id: string): Promise<void> => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(
      `${BASE_API_GATEWAY_URL}/${WEB_SERVICE_ENDPOINT}/otp/create`,
      { email, user_id },
      {
        headers: COMMON_HEADERS,
        withCredentials: true,
      }
    );
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
};

const resendOTP = async (email: string, id: string, user_id: string): Promise<void> => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(
      `${BASE_API_GATEWAY_URL}/${WEB_SERVICE_ENDPOINT}/otp/resend`,
      { id, email, user_id },
      {
        headers: COMMON_HEADERS,
        withCredentials: true,
      }
    );
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
};

const verifyOTP = async (id: string, email: string, otp: string, user_id: string, user_type: string, student_id?: string, referral_type?: string, referrer_code?: string, new_ptl_flow?: boolean, trd_id?: any): Promise<void> => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(
      `/${WEB_SERVICE_ENDPOINT}/otp/verify`,
      { id, email, otp, user_id, student_id, referral_type, referrer_code, new_ptl_flow, trd_id, user_type },
      {
        headers: COMMON_HEADERS,
        withCredentials: true,
      }
    );
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
};

export const APIS = { 
  commonPasswordLogin, 
  sendOTP,
  resendOTP,
  verifyOTP
};
