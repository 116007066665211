import { FEATURE_SLUG_MAP } from "teacherDashboard/teacher/appFeatures/featureSlugMap";

const transferClassPath = (klassId) => `/teacher/class/${klassId}/transfer_class`;
const connectWithParentsPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/class_management_home_access`;
const refer_teacher_path = () => `/teacher/refer_teacher`;
const detailedReportPath = (klassSlug) => `/class/${klassSlug}/daily-activity`;
const assignment_path = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/class_assignment`;
const track_assessment = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/track_assessment`;
const manage_class_path = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/roster`;
const curriculum_path = (klassSlug) => `/class/${klassSlug}/curriculum`;
const teacher_reports_performance_overview_path = (klassSlug) => `/class/${klassSlug}/performance-overview`;
const class_report_path = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/performance-overview`;
const addStudentPath = (klassId) => `/classes/${klassId}/students/add`;
const addKlassPath = () => `/classes/add`;
const springboardTabPath = (klassSlug) => `/class/${klassSlug}/springboard`;
const springboardEnrollmentPath = () => `contests/enroll_klass_for_springboard`;
const assignFactsPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.ASSIGN_PRACTICE}`;
const defaultDashboardPath = () => `/teacher/dashboard`;
const dashboardPath = (klassSlug) => `/teacher/class/${klassSlug}/dashboard`;
const trackFactPracticePath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.TRACK_PRACTICE}`
const studentExperiencePath = (teacherId) => `/teacher/${teacherId}/student_center`;
const manageAllClassesPath = (source) => `/classes/manage?src=${source}`;
const mathWorksheetPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.MATH_WORKSHEET}`;
const elaWorksheetPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.ELA_WORKSHEET}`;
const assessmentPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.NEW_ASSESSMENT}`;
const curriculumPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.NEW_ASSIGNMENT}`;
const lessonPlansPath = (klassSlug, formattedGradeCode, type) => `/class/${klassSlug}/grades/${formattedGradeCode}/${type == "math" ? FEATURE_SLUG_MAP.MATH_LESSON_PLANS: FEATURE_SLUG_MAP.ENGLISH_LESSON_PLANS}`
const studentRosterPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/roster`
const mathLiveClassPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.MATH_LIVE_CLASS}`;
const readingLiveClassPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.READING_LIVE_CLASS}`;
const reinforcementPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.REINFORCEMENT}`;
const classSettingsPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/${FEATURE_SLUG_MAP.CLASS_SETTINGS}`
const springboardCertificatesPath = (klassSlug) => `/class/${klassSlug}/springboard#certificates-tab`;
const springboardLeaderboardPath = (klassSlug) => `/class/${klassSlug}/springboard#leaderboard-tab`;
const exploreBooks = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/explore_books`;
const myLibrary = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/my_library`;
const trackBooks = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/track_books#reading_log`;
const dailyActivityPath = (klassSlug, formattedGradeCode) => `/class/${klassSlug}/grades/${formattedGradeCode}/daily-activity`;
const studentExperienceIAPath = (teacherId, klassId) => `/teacher/${teacherId}/student_center?klass_id=${klassId}`;

export {
  transferClassPath,
  connectWithParentsPath,
  refer_teacher_path,
  detailedReportPath,
  assignment_path,
  manage_class_path,
  curriculum_path,
  teacher_reports_performance_overview_path,
  class_report_path,
  addStudentPath,
  addKlassPath,
  springboardTabPath,
  springboardEnrollmentPath,
  assignFactsPath,
  defaultDashboardPath,
  track_assessment,
  trackFactPracticePath,
  studentExperiencePath,
  manageAllClassesPath,
  mathWorksheetPath,
  elaWorksheetPath,
  assessmentPath,
  curriculumPath,
  lessonPlansPath,
  studentRosterPath,
  mathLiveClassPath,
  readingLiveClassPath,
  reinforcementPath,
  classSettingsPath,
  springboardCertificatesPath,
  springboardLeaderboardPath,
  exploreBooks,
  dashboardPath,
  myLibrary,
  trackBooks,
  dailyActivityPath,
  studentExperienceIAPath
};
