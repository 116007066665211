import { CapitalizeFirstLetter, DecodeCharFromHTMLEntity, FetchGradeCodeFromPath } from "../../config/class_left_nav_container/common";
import { addUTMparamsToLink } from "../../../common/common_utility";
import ReducerConstants from "../../config/ReduxConstant";
import { UTM_PARAMS_FOR_PTL_LINK } from "../../../utils/constants";
import { DateDiffInDays } from "apps/teacher/config/common";

const INITIAL_STATE = {
    klass: {
        id: "",
        gradeCode: "",
        klassName: "",
        gradeId: "",
        teacherId: "",
        studentsCount: 0,
        picturePasswordEnabled: "",
        schoolYear: 0,
        isSecondaryKlass: false,
        klassReferralLink: "",
        slug: "",
        name: ""
    },
    current_course: {
        code: "",
        curriculum_id: 0,
        grade_code: "",
        grade_id: 0,
        id: 0,
        subject_id: 0,
        subject_code: ""
    },
    enrolledCourses: [],
    klassCourses: [],
    curriculum: {
    },
    klass_preference: {},
    klass_settings: {},
    klass_properties: {
        isLoading: true,
    },
}

let currentCourse = (courses, curriculumId, gradeCode, subjectId, klassCourses, forceUpdateGrade = false) => {
    let currentGradeFromUrl = FetchGradeCodeFromPath(window.location.href);
    let modifiedCourses = [
        ...klassCourses,
        ...courses,
    ]
    let currentCourseObject = modifiedCourses.find(course => course.code != "ccss:k:games_math" && course.curriculum_id == curriculumId &&
    (course.grade_code.toString().toLowerCase() === ((currentGradeFromUrl && !forceUpdateGrade) ? currentGradeFromUrl?.toString().toLowerCase() : gradeCode?.toString().toLowerCase())) &&
    course.subject_id == subjectId);
    return currentCourseObject;
}

const extractDataFromKlass = (klass, storeData) => {
    let klass_referral_link = klass?.klass_referral_link;
    if(storeData?.abTestData?.assigned_ab_tests?.['Teacher::PTLSignupFlowRevamp']==="Variant") {
        klass_referral_link = `${window.location.origin}/ptl-setup/common?klass_code=${klass.encrypted_klass_code}`;
    }
    return {
        ...klass,
        id: klass.id,
        name: CapitalizeFirstLetter(DecodeCharFromHTMLEntity(klass.name)),
        gradeCode: storeData?.gradeSeedData?.grades?.find((gradeInstance) => gradeInstance.id === klass.grade_id)?.code,
        klassName: CapitalizeFirstLetter(DecodeCharFromHTMLEntity(klass.name)), // @TODO: replace it's usage with name
        gradeId: klass.grade_id,
        teacherId: klass.teacher_id,
        studentsCount: klass.students_count,
        picturePasswordEnabled: klass.picture_password_enabled,
        schoolYear: klass.school_year,
        isSecondaryKlass: klass.is_secondary_klass,
        klassReferralLinkWithoutParams: klass_referral_link,
        klassReferralLink: addUTMparamsToLink(klass_referral_link, UTM_PARAMS_FOR_PTL_LINK),
        slug: klass.slug,
        createdAt: klass.created_at,
        encryptedKlassCode: klass.encrypted_klass_code,
        onboarding_completion_date: klass.onboarding_widget_completion_date,
        daysSinceClassCreation: DateDiffInDays(klass?.created_at, new Date()),
    };
};

const filterActiveCourses = (courses, klass) => {
    return courses.filter(course => course.grade_code.toString().toLowerCase() === klass.gradeCode.toString().toLowerCase())
}

export const currentKlass = (state = INITIAL_STATE, action, storeData = {}) => {
    switch (action.type) {
        case "SET_SELECTED_CLASS":
            return {
                ...state,
                klass: {
                    ...extractDataFromKlass({...state.klass, ...action.payload.klass}, storeData)
                },
                current_course: action?.payload?.current_course || state?.current_course || {},
            }
        case "SET_KLASS_SELECTED_PROFILE_DATA":
            return {
                ...state,
                klass: {
                    ...extractDataFromKlass({...state.klass, ...action.payload.klass}, storeData)
                },
                enrolledCourses: filterActiveCourses(action.payload.courses, state.klass),
                klass_preference: action.payload.klass_preference,
                klass_settings: action.payload.klass_settings,
                current_course: action.payload.courses[0] || {},
                klassCourses: action.payload.courses
            }
        case "UPDATE_KLASS_SELECTED_PROFILE_DATA":
            return {
                ...state,
                klass: {
                    ...extractDataFromKlass({...state.klass, ...action.payload.klass}, storeData)
                },
                enrolledCourses: filterActiveCourses(action.payload.courses, state.klass),
                klass_preference: action.payload.klass_preference,
                klass_settings: action.payload.klass_settings,
                current_course: { ...state.current_course },
                klassCourses: action.payload.courses
            }


        case "SET_SELECTED_GRADE_CODE":

            return {
                ...state,
                current_course: currentCourse(storeData.curriculumSeedData.courses,
                    state.curriculum.id, action.payload.code,
                    state.current_course?.subject_id, state.klassCourses) || state.current_course
            }
        case "SET_ACTIVE_SUBJECT":
            let subject = storeData.curriculumSeedData.subjects.filter((subject) => {
                return (subject.code == action.payload);
            })[0];
            return {
                ...state,
                current_course: currentCourse(storeData.curriculumSeedData.courses,
                    state.curriculum.id,
                    state.klass.gradeCode,
                    subject.id, state.klassCourses) || state.current_course
            }

        case "UPDATE_SELECTED_KLASS_DATA":
            let klass = storeData.teacherProfile.klasses[action.payload.index];
            return {
                ...state,
                klass: {
                    ...extractDataFromKlass({...state.klass, ...klass}, storeData)
                },
            }
        case "SET_KLASS_CURRICULUM":
            return {
                ...state,
                curriculum: action.payload,
            }
        case "SET_KLASS_PROPERTIES_DATA":
            return {
                ...state,
                klass_properties: {
                    ...state.klass_properties,
                    ...action.payload
                }
            }
        case ReducerConstants.SET_ONBOARDING_COMPLETION_DATE:
            return {
                ...state,
                klass: {
                    ...state.klass,
                    onboarding_completion_date: action.payload
            }
        }
        default:
            return state
    }
}
