"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pushEventToGTM = void 0;
// This file inlcudes a common Function for pushing an event to GTM server via web custom_tracking

// Array of allowed events
var allowedEvents = ['login_failed', 'logged_in', 'Account Type Screen Shown', 'Onboarded Teacher', 'Activated Teacher', 'viewed_student_login_screen', 'viewed_qr_code_screen_no_camera_access', 'viewed_qr_code_screen_camera_access', 'viewed_class_code_screen', 'viewed_student_selection_screen', 'viewed_student_password_screen', 'viewed_saved_class_student_login_screen'];
var pushEventToGTM = function pushEventToGTM(eventName, eventData) {
  if (allowedEvents.includes(eventName) && window && window.dataLayer && typeof trackToGtm !== 'undefined') {
    trackToGtm(dataLayer, eventName, eventName, eventData);
  }
};
exports.pushEventToGTM = pushEventToGTM;