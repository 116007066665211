import { deepCopyObject } from "../../../../common/common_utility";
import { DateDiffInMinutes } from "../../../config/common";

const MathFactAssignmentDetailsInitialState = {
    allAssignedFluencies: [],
    allAssignedFluenciesLoading: false,
    klassStudentFluencyDetails: {},
    klassStudentFluencyDetailsLoading: false,
    assignedFactPractices: {
        auto: {},
        customTest: {
            data: {},
            completed: 0,
            active: 0,
            total: 0
        },
        length: 0
    }
}

class MathFactAssignmentDetailsModel {
    static __instance__ = new MathFactAssignmentDetailsModel();

    allAssignedFluencies = deepCopyObject(MathFactAssignmentDetailsInitialState.allAssignedFluencies);
    allAssignedFluenciesLoading = MathFactAssignmentDetailsInitialState.allAssignedFluenciesLoading;
    assignedFactPractices = deepCopyObject(MathFactAssignmentDetailsInitialState.assignedFactPractices);
    klassStudentFluencyDetails = deepCopyObject(MathFactAssignmentDetailsInitialState.klassStudentFluencyDetails);
    klassStudentFluencyDetailsLoading = MathFactAssignmentDetailsInitialState.klassStudentFluencyDetailsLoading;

    constructor() { }

    static initialState() {
        this.__instance__ = null;
        return this.singleInstance;
    }

    static get singleInstance() {
        if (!this.__instance__) {
            this.__instance__ = new MathFactAssignmentDetailsModel();
        }
        return this.__instance__;
    }

    static toggleAllAssignedFluenciesLoading(value) { this.singleInstance.allAssignedFluenciesLoading = value; return this.singleInstance; }
    static toggleKlassStudentFluencyDetailsLoading(value) { this.singleInstance.klassStudentFluencyDetailsLoading = value; return this.singleInstance; }

    static setAllAssignedFluencies(data) {
        this.singleInstance.allAssignedFluencies = data;
        this.singleInstance.assignedFactPractices = data.reduce((acc, item) => {
            if (item.mode === 'auto') {
                acc[item.mode] = item;
            } else {
                if (!acc['customTest']) {
                    acc['customTest'] = {
                        data: {},
                        completed: 0,
                        active: 0,
                        total: 0,
                    };
                }
                acc['customTest'].data[item.id] = item;
                acc['customTest'].total += 1;
                acc['customTest'].id="customTest";
                const isTeacherCompletedBeforeToday = item.completed_at ? DateDiffInMinutes(item.completed_at+"Z", new Date()) >= 0 :  false;
                const isTeacherFinishedBeforeToday = item.finished_at ? DateDiffInMinutes(item.finished_at+"Z", new Date()) >= 0 :  false;
                if(isTeacherCompletedBeforeToday || isTeacherFinishedBeforeToday) {
                    acc['customTest'].completed += 1;
                } else {
                    acc['customTest'].active += 1;
                }
            }
            return acc;
        }, {});
        this.singleInstance.assignedFactPractices.length = data.length;
        return this.singleInstance;
    }

    static setklassStudentFluencyDetails(data, factFluencyId) { this.singleInstance.klassStudentFluencyDetails[factFluencyId] = data; return this.singleInstance; }
}

export default MathFactAssignmentDetailsModel;
