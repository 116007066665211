import { deepCopyObject } from "../../../common/common_utility";
import ReducerConstants from "../../config/ReduxConstant";
import { DataModels } from "../models";

const INITIAL_STATE = DataModels.MathFactAssignmentDetailsModel.initialState();

export const assignedFactPractices = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_ASSIGNED_FACT_PRACTICES:
            return deepCopyObject(action.payload)
        default:
            return state
    }
}