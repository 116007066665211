import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    message: "Welcome to Class Reports",
    klass_details: {
        klassId: "",
        klassName: "",
        gradeCode: "",
        klassObj: "",
        klassGradeObj: "",
    },
    curriculumList: [],
    contentGroups: [],
    standardDetails: [],
    playables: [],
    studentsList: [],
    gridViewReportData: {},
    gridReportABTest: false,
    gridAssignABTest: false
};

export const classReportData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_CLASS_REPORT_STANDARD_DETAILS":
            return { ...state, standardDetails: action.payload };
        case "SET_CLASS_REPORT_PLAYABLES":
            return { ...state, playables: action.payload };
        case "SET_CLASS_REPORT_STUDENTS":
            return { ...state, studentsList: action.payload };
        case "SET_CLASS_REPORT_GRID_VIEW_REPORT_DATA":
            return { ...state, gridViewReportData: action.payload };
        default:
            return state
    }
}