namespace DataUtility {
    export const hasValue = (value: any) => {
        if ((value == null) || (value == undefined)) {
            return false;
        }
        if (typeof value === 'number') {
            return true;
        }
        return Object.keys(value)?.length > 0;
    }

    export const isValueChanged = (prevState: any, currentState: any) => {
        let flag = false;
        if (JSON.stringify(prevState) !== JSON.stringify(currentState)) {
            flag = true;
        }

        return flag;
    }

    export const deepMerge = (target: any = {}, source: any = {}): any => {
        if (typeof target !== 'object' || typeof source !== 'object') {
            return source;
        }
    
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (target.hasOwnProperty(key)) {
                    target[key] = deepMerge(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
    
        return target;
    }
}

export default DataUtility;