import AssignIcon from "TEACHER_ASSETS/images/teacher/assign-icon.svg";
import AssessIcon from "TEACHER_ASSETS/images/teacher/assess-icon.svg";
import ReviewIcon from "TEACHER_ASSETS/images/teacher/review-icon.svg";
import DashboardIcon from "TEACHER_ASSETS/images/teacher/dashboard-icon.svg";
import ManageIcon from "TEACHER_ASSETS/images/teacher/manage-icon.svg";
import WorksheetIcon from "TEACHER_ASSETS/images/teacher/worksheet-icon.svg";

export const TeacherIcons = {
    AssessIcon, 
    AssignIcon,
    ReviewIcon,
    DashboardIcon,
    ManageIcon,
    WorksheetIcon
}