import { AxiosClient } from './apiClient';
import { GlobalSpinner } from '../config/common';
import Store from '../store/index';

const EXT_SERVICE_MODE = 'cors';
const EXT_SERVICE_CREDENTIALS = 'include';

const prepareConfig = (dataObj, methodType) => {
    let configToReturn = {
        params: dataObj.params ? dataObj.params : {},
        callback: dataObj.callback ? dataObj.callback : () => {},
        errorCallback: dataObj.errorCallback ? dataObj.errorCallback : () => {},
        successCallback: dataObj.successCallback ? dataObj.successCallback : () => {},
        dontShowAjaxSpinner: dataObj.dontShowAjaxSpinner,
        dummyData: dataObj.dummyData || {},
        requestConfig: {
            method: methodType,
            credentials: "same-origin",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
                "X-Requested-With": "XMLHttpRequest",
                ...(dataObj.headers || {})
            }
        }
    }

    if(methodType !== 'get' && !!dataObj.params){
        configToReturn.requestConfig.body = JSON.stringify(dataObj.params);
    }
    return configToReturn;
}

const prepareExternalServiceConfig = (currentConfig = {}, dataObj) => {

    let externalServiceConfig = currentConfig;
    AxiosClient.defaults.withCredentials = false;
    let teacherIdFromDataObj = (dataObj && dataObj.teacherId) ? dataObj.teacherId : null;
    if(APP_MODE == "development") {
        const state = Store.getState();
        const userId = (state.teacherProfile && state.teacherProfile.teacher && state.teacherProfile.teacher.id) ? state.teacherProfile.teacher.id : teacherIdFromDataObj;
        externalServiceConfig.requestConfig.headers["user_id"] = userId;
    } else {
      externalServiceConfig.requestConfig['mode'] = EXT_SERVICE_MODE;
      externalServiceConfig.requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
    }
    return externalServiceConfig;
}

const get = async (endpoint, dataObj, config = {}) => {
    let preparedConfig = {};
    if(Object.keys(config || {}).length > 0) {
        preparedConfig = config;
    } else {
        preparedConfig = prepareConfig(dataObj, 'get');
    }
    if(Object.keys(preparedConfig.dummyData || {}).length > 0 && preparedConfig.dummyData.constructor === Object){
        preparedConfig.successCallback && preparedConfig.successCallback(preparedConfig.dummyData);
        return Promise.resolve(preparedConfig.dummyData);
    } else {
        if (!preparedConfig.dontShowAjaxSpinner) {
            GlobalSpinner.show();
        }
        return await AxiosClient.get(endpoint, {...preparedConfig.requestConfig, params: preparedConfig.params, withCredentials: preparedConfig.requestConfig.mode === "cors"})
        .then((res) => {
            preparedConfig.callback(res.data);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            preparedConfig.errorCallback(err);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.reject(err);
        });
    }
}

const post = async (endpoint, dataObj, config = {}) => {
    let preparedConfig = {};
    if(Object.keys(config || {}).length > 0) {
        preparedConfig = config;
    } else {
        preparedConfig = prepareConfig(dataObj, 'POST');
    }
    if(Object.keys(preparedConfig.dummyData || {}).length > 0 && dummyData.constructor === Object){
        successCallback && successCallback(preparedConfig.dummyData);
        return Promise.resolve(preparedConfig.dummyData);
    } else {
        if (!preparedConfig.dontShowAjaxSpinner) {
            GlobalSpinner.show();
        }
        return await AxiosClient.post(endpoint, preparedConfig.params, {...preparedConfig.requestConfig, withCredentials: preparedConfig.requestConfig.mode === "cors"})
        .then((res) => {
            preparedConfig.callback(res.data);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            preparedConfig.errorCallback(err);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.reject(err);
        });
    }
}

const put = async (endpoint, dataObj, config = {}) => {
    let preparedConfig = {};
    if(Object.keys(config || {}).length > 0) {
        preparedConfig = config;
    } else {
        preparedConfig = prepareConfig(dataObj, 'PUT');
    }
    if(Object.keys(preparedConfig.dummyData || {}).length > 0 && dummyData.constructor === Object){
        successCallback && successCallback(preparedConfig.dummyData);
        return Promise.resolve(preparedConfig.dummyData);
    } else {
        if (!preparedConfig.dontShowAjaxSpinner) {
            GlobalSpinner.show();
        }
        return await AxiosClient.put(endpoint, preparedConfig.params, {...preparedConfig.requestConfig, withCredentials: preparedConfig.requestConfig.mode === "cors"})
        .then((res) => {
            preparedConfig.callback(res.data);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            preparedConfig.errorCallback(err);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.reject(err);
        });
    }
}

const patch = async (endpoint, dataObj, config = {}) => {
    let preparedConfig = {};
    if(Object.keys(config || {}).length > 0) {
        preparedConfig = config;
    } else {
        preparedConfig = prepareConfig(dataObj, 'PATCH');
    }
    if(Object.keys(preparedConfig.dummyData || {}).length > 0 && dummyData.constructor === Object){
        successCallback && successCallback(preparedConfig.dummyData);
        return Promise.resolve(preparedConfig.dummyData);
    } else {
        if (!preparedConfig.dontShowAjaxSpinner) {
            GlobalSpinner.show();
        }
        return await AxiosClient.patch(endpoint, preparedConfig.params, {...preparedConfig.requestConfig, withCredentials: preparedConfig.requestConfig.mode === "cors"})
        .then((res) => {
            preparedConfig.callback(res.data);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            preparedConfig.errorCallback(err);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.reject(err);
        });
    }
}

const del = async (endpoint, dataObj, config = {}) => {
    let preparedConfig = {};
    if(Object.keys(config || {}).length > 0) {
        preparedConfig = config;
    } else {
        preparedConfig = prepareConfig(dataObj, 'DELETE');
    }
    if(Object.keys(preparedConfig.dummyData || {}).length > 0 && dummyData.constructor === Object){
        successCallback && successCallback(preparedConfig.dummyData);
        return Promise.resolve(preparedConfig.dummyData);
    } else {
        if (!preparedConfig.dontShowAjaxSpinner) {
            GlobalSpinner.show();
        }
        return await AxiosClient.delete(endpoint, {...preparedConfig.requestConfig, withCredentials: preparedConfig.requestConfig.mode === "cors"})
        .then((res) => {
            preparedConfig.callback(res.data);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            preparedConfig.errorCallback(err);
            if (!preparedConfig.dontShowAjaxSpinner) {
                GlobalSpinner.hide();
            }
            return Promise.reject(err);
        });
    }
}

const getExternalService = async (endpoint, dataObj) => {
    let requestConfig = {
      method: dataObj.method,
      credentials: "same-origin",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        "X-Requested-With": "XMLHttpRequest",
      }
    }


    if(APP_MODE == "development") {
      const state = Store.getState();
      const userId = (state.teacherProfile && state.teacherProfile.teacher && state.teacherProfile.teacher.id) ? state.teacherProfile.teacher.id : null;
      requestConfig.headers["user_id"] = userId;
    } else {
      requestConfig['mode'] = EXT_SERVICE_MODE;
      requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
    }

  return await fetch(endpoint, requestConfig)
    .then(async (res) => {
      let parsedResponse = await res.json();
        dataObj.callback(parsedResponse);
        if (!dataObj.dontShowAjaxSpinner) {
          GlobalSpinner.hide();
        }
        return Promise.resolve(parsedResponse);
      })
      .catch((err) => {
        dataObj.errorCallback(err);
        if (!dataObj.dontShowAjaxSpinner) {
          GlobalSpinner.hide();
        }
        return Promise.reject(err);
      })

}

const postExternalServiceAPI = async (endpoint, dataObj) => {
    let preparedConfig = prepareConfig(dataObj, 'POST');
    let externalServiceConfig = prepareExternalServiceConfig(preparedConfig);
    return await post(endpoint, null, externalServiceConfig);
}

const getExternalServiceAPI = async (endpoint, dataObj) => {
  let preparedConfig = prepareConfig(dataObj, 'get');
  let externalServiceConfig = prepareExternalServiceConfig(preparedConfig, dataObj);
  return await get(endpoint, null, externalServiceConfig);
};

const putExternalServiceAPI = async (endpoint, dataObj) => {
  let preparedConfig = prepareConfig(dataObj, 'PUT');
  let externalServiceConfig = prepareExternalServiceConfig(preparedConfig);
  return await put(endpoint, null, externalServiceConfig);
};

const patchExternalServiceAPI = async (endpoint, dataObj) => {
  let preparedConfig = prepareConfig(dataObj, 'PATCH');
  let externalServiceConfig = prepareExternalServiceConfig(preparedConfig);
  return await patch(endpoint, null, externalServiceConfig);
};

const delExternalServiceAPI = async (endpoint, dataObj) => {
  let preparedConfig = prepareConfig(dataObj, 'DELETE');
  let externalServiceConfig = prepareExternalServiceConfig(preparedConfig);
  return await del(endpoint, null, externalServiceConfig);
};


const getExternalAPI = async (endpoint, dataObj) => {
    let preparedConfig = prepareConfig(dataObj, 'get');
    let externalServiceConfig = prepareExternalServiceConfig(preparedConfig, dataObj);
    return await get(endpoint, dataObj, externalServiceConfig);
};

const API_CALLER = {
    get,
    post,
    put,
    patch,
    del,
    getExternalService,
    postExternalServiceAPI,
    getExternalAPI,
    getExternalServiceAPI,
    putExternalServiceAPI,
    patchExternalServiceAPI,
    delExternalServiceAPI
};

export default API_CALLER;
