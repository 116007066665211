import { FEATURE_SLUG_MAP } from "../teacherDashboard/teacher/appFeatures/featureSlugMap";
import { EventNames } from "../teacherDashboard/teacher/config/class_left_nav_container/events";


export const getFromPage = (stateFromPage?: string) => {
  let fromPage = sessionStorage.getItem('previous_path');
  try {
    const lastPath = fromPage?.split('/').pop();
    fromPage = FromPageEventMapping[lastPath] || fromPage;
    if(stateFromPage){
      fromPage = stateFromPage;
    } 
  } catch (error) {
    console.error(error);
  }
  return fromPage || "";
}

export const PageNames = {
  dashboard: "Dashboard",
  newAssignment: "Curriculum Assignments",
  trackAssignment: "Track Curriculum Assignments",
  assignPractice: "Math Facts",
  trackPractice: "Track Math Facts",
  newAssessment: "Assessments",
  trackAssessment: "Track Assessments",
  dailyActivity: "Daily Activity",
  classProgress: "Class Report",
  studentProgress: "Student Report",
  manageClass: "Manage All Classes",
  manageAllClass: "Manage All Classes",
  studentRoster: "Student Roster",
  classSettings: "Class Settings",
  splashCommunity: "Splash Community",
  summerLearningProgram: "Summer Learning Program",
  mathWorksheet: "Math Worksheet",
  elaWorksheet: "Ela Worksheet",
  mathLessonPlans: "Math Lesson Plans",
  englishLessonPlans: "English Lesson Plans",
  mathLiveClass: "Math Live Class",
  readingLiveClass: "Reading Live Class",
  exploreBooks: "Explore Library",
  trackBooks: "Track Books",
};


export const FromPageEventMapping = {
  [FEATURE_SLUG_MAP.DASHBOARD]: PageNames.dashboard,
  [FEATURE_SLUG_MAP.NEW_ASSIGNMENT]: PageNames.newAssignment,
  [FEATURE_SLUG_MAP.TRACK_ASSIGNMENT]: PageNames.trackAssignment,
  [FEATURE_SLUG_MAP.ASSIGN_PRACTICE]: PageNames.assignPractice,
  [FEATURE_SLUG_MAP.TRACK_PRACTICE]: PageNames.trackPractice,
  [FEATURE_SLUG_MAP.NEW_ASSESSMENT]: PageNames.newAssessment,
  [FEATURE_SLUG_MAP.TRACK_ASSESSMENT]: PageNames.trackAssessment,
  [FEATURE_SLUG_MAP.DAILY_ACTIVITY]: PageNames.dailyActivity,
  [FEATURE_SLUG_MAP.CLASS_PROGRESS]: PageNames.classProgress,
  [FEATURE_SLUG_MAP.STUDENT_PROGRESS]: PageNames.studentProgress,
  [FEATURE_SLUG_MAP.MANAGE_CLASS]: PageNames.manageClass,
  [FEATURE_SLUG_MAP.MANAGE_ALL_CLASS]: PageNames.manageAllClass,
  [FEATURE_SLUG_MAP.STUDENT_ROSTER]: PageNames.studentRoster,
  [FEATURE_SLUG_MAP.CLASS_SETTINGS]: PageNames.classSettings,
  [FEATURE_SLUG_MAP.SPLASH_COMMUNITY]: PageNames.splashCommunity,
  [FEATURE_SLUG_MAP.SUMMER_LEARNING_PROGRAM]: PageNames.summerLearningProgram,
  [FEATURE_SLUG_MAP.MATH_WORKSHEET]: PageNames.mathWorksheet,
  [FEATURE_SLUG_MAP.ELA_WORKSHEET]: PageNames.elaWorksheet,
  [FEATURE_SLUG_MAP.MATH_LESSON_PLANS]: PageNames.mathLessonPlans,
  [FEATURE_SLUG_MAP.ENGLISH_LESSON_PLANS]: PageNames.englishLessonPlans,
  [FEATURE_SLUG_MAP.MATH_LIVE_CLASS]: PageNames.mathLiveClass,
  [FEATURE_SLUG_MAP.READING_LIVE_CLASS]: PageNames.readingLiveClass,
  [FEATURE_SLUG_MAP.EXPLORE_BOOKS]: PageNames.exploreBooks,
  [FEATURE_SLUG_MAP.TRACK_BOOKS]: PageNames.trackBooks,
};

export const IATabNameToEventMapping = {
  [FEATURE_SLUG_MAP.TRACK_ASSIGNMENT]: "Clicked Assignment Reports"
};

export const IAFeatureAvoEventMapping = {
  [FEATURE_SLUG_MAP.NEW_ASSIGNMENT]: EventNames.visitedCurriculumTab,
  [FEATURE_SLUG_MAP.NEW_ASSESSMENT]: EventNames.clickedClassAssessments,
  [FEATURE_SLUG_MAP.TRACK_ASSESSMENT]: EventNames.clickedAssessmentReports,
  [FEATURE_SLUG_MAP.STUDENT_PROGRESS]: EventNames.navClickedStudentReport,
  [FEATURE_SLUG_MAP.TRACK_PRACTICE]: EventNames.navClickedMfTrackFluency,
  [FEATURE_SLUG_MAP.ASSIGN_PRACTICE]: EventNames.navClickedMfNewPractice,
  [FEATURE_SLUG_MAP.CLASS_PROGRESS]: EventNames.navClickedClassReport,
  [FEATURE_SLUG_MAP.REINFORCEMENT]: EventNames.interactedTeacherDashboard,
  [FEATURE_SLUG_MAP.EXPLORE_BOOKS]: EventNames.interactedTeacherDashboard,
  [FEATURE_SLUG_MAP.TRACK_BOOKS]: EventNames.interactedTeacherDashboard,
}