import { UrlShorteningServiceFetchShortUrl, UrlShorteningServiceFetchShortUrlAsync } from '../teacher/config/common';
export const calculateTotalAccuracyOfLO = (playables) => {
    let sum_asked_count = 0;
    let sum_correct_count = 0;

    return playables.reduce((acc, item) => {
        if (item.asked_count > 0 && item.correct_count > 0) {
            sum_asked_count += item.asked_count;
            sum_correct_count += item.correct_count;
        } else {
            sum_asked_count = 0;
            sum_correct_count = 0;
        }

        return sum_asked_count && sum_correct_count ? ((sum_correct_count / sum_asked_count) * 100).toFixed(2) : 0
    }, 0);
}

export const calculateTotalTimeSpentOfLO = (playables) => {
    return playables.reduce((acc, item) => {
        if (item.time_spent > 0) {
            return acc + item.time_spent;
        }
        return acc;
    }, 0);
}

export const calculateCompletedAtOfLO = (playables) => {
    return playables.reduce((acc, item) => {
        if (acc == -1) {
            acc = item.completed_at
        }
        else if (!item.completed_at) {
            acc = item.completed_at;
        }
        else if (acc && item.completed_at) {
            if (new Date(item.completed_at).getTime() > new Date(acc).getTime()) {
                acc = item.completed_at
            }
        }
        return acc;
    }, -1);
}

/**
 * This function can used to load a script dynamically from js file
 * @param {String} src - source url of script file
 */
export const loadScript = (src) => {
    return new Promise(function(resolve, reject){
      var script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.addEventListener('load', function () {
        resolve(script);
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    })
};

export const addUTMparamsToLink = (link, utmParams) => {
    if(link) {
        let linkObj =  new URL(link);
        Object.keys(utmParams).map((key)=>linkObj.searchParams.set(key, utmParams[key]))
        return linkObj.toString();
    }
    return link;
}

export const fetchShortUrl = (long_url, callback) => {
      if(long_url) {
        UrlShorteningServiceFetchShortUrl({
            long_url: long_url,
            callback: function(callbackData){
              callback(callbackData.short_url)
            },
            errorCallback: function(err){
              callback(long_url)
            }
          });
      } else {
        callback(long_url)
      }
  }
  export const fetchShortUrlAsync = async (long_url, callback) => {
    if(long_url) {
      let url = await UrlShorteningServiceFetchShortUrlAsync({
          long_url: long_url,
          dontShowAjaxSpinner: true
        });
        return url;
    } else {
      return long_url
    }
}
export const stringToUuid = (str) => {
  //this should ideally be done on the backend. To be removed once implemented from BE
  let regex = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/
  str = str.replace(regex, "$1-$2-$3-$4-$5")
  str = str.toLowerCase()
  return str;
}

export const deferTask = ({taskToDefer, timeOut}) => {
    setTimeout(taskToDefer, timeOut);
}

// deep clone object
export function deepCopyObject (obj) {
    return JSON.parse(JSON.stringify(obj))
}
export const convertToListFromRange = (array = []) => {
    array = array.map((item) => {
        let start = parseInt(item.split("-")[0]);
        let end = item.split("-")[1] ? parseInt(item.split("-")[1]) : start;
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    })
    return array.flat(1);
}

export const convertToRanges = (pieces) => {
    let ranges = [[pieces[0], pieces[0]]];
    let lastIndex = 0;
    for (let i = 1; i < pieces.length; i++) {
        if (pieces[i] - ranges[lastIndex][1] === 1) {
            ranges[lastIndex][1] = pieces[i];
        } else {
            ranges[++lastIndex] = [pieces[i], pieces[i]];
        }
    }
    return ranges;
}

// find accuracy for a learning objective
export const calculateAccuracy = (loTotalCorrectCount, loTotalAskedCount) => {
    return loTotalAskedCount && loTotalCorrectCount ? (( loTotalCorrectCount / loTotalAskedCount )*100) : 0
}