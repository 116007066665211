import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    assessData: { "ongoing": [], "finished": [], "unassigned": [] },
    klassId: '',
    gradeCode: '',
    showAssignModal: false,
    assignIntentSkillData: {},
    reportViewFlag: false,
    showReportSkillDetail: {},
    individualAssessmentTableData: {},
    studentReportSidebarList: {},
    studentsLearningPlanData: {},
    studentsLearningPlanPrintData: {},
    classReportGraphData: [],
    studentReportGraphData: [],
    classPrintReportData: {},
    studentPrintReportData: {},
    isMigratedToCurriculum3: true,
    hidePitchPage: false
};

var assessmentStatusUpdate = function (assessData, data) {
    let tempData = assessData[data.type] || [];
    for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].id == data.id) {
            assessData[data.type][i].assign_practice = data.skillObj.assign_practice;
            break;
        }
    }
    return assessData;
}


export const assessmentData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "INITIALIZE_ASSESSMENT_DATA":
            return { ...state, assessData: action.payload };
        case "SHOW_ASSIGN_MODAL":
            return { ...state, showAssignModal: true, assignIntentSkillData: action.payload }
        case "HIDE_ASSIGN_MODAL":
            return { ...state, showAssignModal: false }
        case "CHANGE_VIEW_TO_REPORT":
            return { ...state, reportViewFlag: true, showReportSkillDetail: action.payload, studentReportSidebarList: {}, classPrintReportData: {} }
        case "CHANGE_VIEW_TO_ASSESSMENTS_LIST":
            return { ...state, reportViewFlag: false, showReportSkillDetail: {}, studentReportSidebarList: {}, classPrintReportData: {} }
        case "SET_INDIVIDUAL_ASSESSMENT_TABLE_DATA":
            return { ...state, individualAssessmentTableData: action.payload }
        case "SET_CLASS_STUDENT_REPORT_SIDEBAR_LIST_DATA":
            return { ...state, studentReportSidebarList: action.payload }
        case "SET_STUDENT_LEARNING_DATA":
            return { ...state, studentsLearningPlanData: action.payload }
        case "SET_STUDENT_LEARNING_PRINT_DATA":
            return { ...state, studentsLearningPlanPrintData: action.payload }
        case "SET_CLASS_REPORT_GRAPH_DATA":
            return { ...state, classReportGraphData: action.payload }
        case "SET_STUDENT_REPORT_GRAPH_DATA":
            return { ...state, studentReportGraphData: action.payload }
        case "SET_CLASS_PRINT_REPORT_DATA":
            return { ...state, classPrintReportData: action.payload }
        case "SET_STUDENT_PRINT_REPORT_DATA":
            return { ...state, studentPrintReportData: action.payload }
        case "SET_DIAGNOSTIC_FAILED_ASSIGN_STATUS":
            return { ...state, assessData: assessmentStatusUpdate(state.assessData, action.payload), showReportSkillDetail: action.payload }
        default:
            return state
    }
}