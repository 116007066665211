import React, { MouseEventHandler } from "react";

export enum ButtonStylesType {
    XL = "XL",
    L = "L",
    M = "M",
    S = "S",
    XS = "XS"
}

export enum ButtonThemeType  {
    primary = "primary",
    secondary = "secondary",
    primaryFilled = "primaryFilled",
    secondaryFilled = "secondaryFilled",
    bordered = "bordered"
}

export type ButtonPropsType = {
    icon?: React.ReactElement;
    height?: string;
    padding?: string;
    type?: ButtonStylesType;
    onClick?: MouseEventHandler<HTMLButtonElement> & Function;
    backgroundColor?: string;
    width?: string;
    margin?: string;
    shadowWidth?: number;
    timeout?: number;
    hover?: {
        backgroundColor?: string;
        cursor?: string;
        border?: string;
    };
    disabled?: boolean;
    dataTestId?: string;
    border?: string;
    tabIndex?: number;
} & {[K in ButtonThemeType]?: boolean}

export type ButtonComponentsType = {
    [K in ButtonStylesType]: React.FC<ButtonPropsType>;
  };
  