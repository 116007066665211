import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE: any = {
  isLoading: true
}

export interface IActionProps {
  type: string,
  payload: any
}

export const contest = (state = INITIAL_STATE, action: IActionProps) => {
  switch (action.type) {
    case ReducerConstants.SET_CONTEST_DATA:
      return {
        ...state,
        ...(action.payload || {})
      }
    case ReducerConstants.TOGGLE_IS_CONTEST_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case ReducerConstants.UPDATE_CONTEST_POPUP_DATA: 
      return {
        ...state,
        springboardPopupData: {
          ...state.springboardPopupData,
          ...action.payload
        }
      }
    default:
      return state
  }
}
