import React from "react";
import { Colors } from "baseStyles/enums/colors";
import Fonts from "baseStyles/enums/fonts";

// Enum representing different styles of text
export enum InputTypes {
  TEXT = "text",
  PASSWORD = "password"
}

// Type representing the properties of a text component
export type InputPropsType = {
  type?: InputTypes;
  width?: number | string;
  fontSize?: Fonts.Size;
  fontWeight?: Fonts.Weight;
  lineHeight?: number;
  letterSpacing?: number;
  color?: Colors;
  disabled?: boolean;
  center?: boolean;
  emphasis?: boolean;
  placeholder?: string;
  padding?: string | number;
  value?: string;
  id?: string;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onClick?: () => void;
  borderRadius?: string | number;
  placeholderColor?: Colors;
  height?: number;
  hideBorder?: boolean;
  border?: string;
  outline?: string;
  focusBorder?: string;
  onPaste?: (event: any) => void
  placeholderOpacity?: number;
  maxCharacters?: number;
  autoFocus?: boolean;
  tabIndex?: number;
  backgroundColor?: string;
  onKeyDown?: (event: any) => void;
  dataTestId?: string;
  hasError?: boolean;
  name?: string;
  customPlaceholder?: boolean;
  icon?: any;
}

// Type representing an object that maps each text style to a React functional component
export type InputComponentsType = {
  [K in InputTypes]: React.FC<InputPropsType>;
};