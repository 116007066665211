"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLogin = void 0;
var _react = require("react");
var _api = require("./api");
var _pushEventToGTM = require("../../commonHelpers/pushEventToGTM");
var _sendErrorToSentry = require("ErrorBoundary/sendErrorToSentry");
var _events = require("../../ptlSignup/events");
var _constants = require("../../teacherDashboard/utils/constants");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var resendOTPTimer = null;
var getParams = function getParams() {
  var queryParams = new URLSearchParams(window.location.search);
  var token = queryParams.get("token");
  try {
    return token ? JSON.parse(atob(token)) : {};
  } catch (e) {
    (0, _sendErrorToSentry.sendErrorToSentry)(e, {
      token: token
    });
  }
  return {};
};
var useLogin = function useLogin(_ref) {
  var _ref$primaryInputLabe = _ref.primaryInputLabel,
    primaryInputLabel = _ref$primaryInputLabe === void 0 ? "Email" : _ref$primaryInputLabe,
    _ref$showSocialLogin = _ref.showSocialLogin,
    showSocialLogin = _ref$showSocialLogin === void 0 ? true : _ref$showSocialLogin,
    _ref$sendEvent = _ref.sendEvent,
    sendEvent = _ref$sendEvent === void 0 ? function (clickedOn) {} : _ref$sendEvent,
    _ref$source = _ref.source,
    source = _ref$source === void 0 ? null : _ref$source,
    _ref$isPtlFlow = _ref.isPtlFlow,
    isPtlFlow = _ref$isPtlFlow === void 0 ? false : _ref$isPtlFlow;
  var params = getParams();
  var _useState = (0, _react.useState)(false),
    _useState2 = _slicedToArray(_useState, 2),
    otpLogin = _useState2[0],
    setOTPLogin = _useState2[1];
  var _useState3 = (0, _react.useState)(false),
    _useState4 = _slicedToArray(_useState3, 2),
    otpSent = _useState4[0],
    setOTPSent = _useState4[1];
  var _useState5 = (0, _react.useState)(""),
    _useState6 = _slicedToArray(_useState5, 2),
    otp = _useState6[0],
    setOTP = _useState6[1];
  var _useState7 = (0, _react.useState)(0),
    _useState8 = _slicedToArray(_useState7, 2),
    timer = _useState8[0],
    setTimer = _useState8[1];
  var _useState9 = (0, _react.useState)(params === null || params === void 0 ? void 0 : params.masked_email),
    _useState10 = _slicedToArray(_useState9, 2),
    email = _useState10[0],
    setEmail = _useState10[1];
  var _useState11 = (0, _react.useState)(""),
    _useState12 = _slicedToArray(_useState11, 2),
    password = _useState12[0],
    setPassword = _useState12[1];
  var _useState13 = (0, _react.useState)(null),
    _useState14 = _slicedToArray(_useState13, 2),
    error = _useState14[0],
    setError = _useState14[1];
  var _useState15 = (0, _react.useState)(null),
    _useState16 = _slicedToArray(_useState15, 2),
    intentId = _useState16[0],
    setIntentId = _useState16[1];
  var _useState17 = (0, _react.useState)(false),
    _useState18 = _slicedToArray(_useState17, 2),
    showResendSuccessMsg = _useState18[0],
    setShowResendSuccessMsg = _useState18[1];
  var ifEmailExistInLS = localStorage.getItem('email-entered');
  var _useState19 = (0, _react.useState)((params === null || params === void 0 ? void 0 : params.referral_type) === _constants.InvitationSources.copy_link ? "Student selection screen" : "Sign up link"),
    _useState20 = _slicedToArray(_useState19, 2),
    fromPage = _useState20[0],
    setFromPage = _useState20[1];
  var _useState21 = (0, _react.useState)({
      typed: false,
      pasted: false
    }),
    _useState22 = _slicedToArray(_useState21, 2),
    emailEvents = _useState22[0],
    setEmailEvents = _useState22[1];
  var _useState23 = (0, _react.useState)({
      typed: false,
      pasted: false
    }),
    _useState24 = _slicedToArray(_useState23, 2),
    passwordEvents = _useState24[0],
    setPasswordEvents = _useState24[1];
  var _useState25 = (0, _react.useState)({
      referrerPage: "",
      invitationSource: ""
    }),
    _useState26 = _slicedToArray(_useState25, 2),
    referralData = _useState26[0],
    setReferralData = _useState26[1];
  (0, _react.useEffect)(function () {
    if (ifEmailExistInLS) {
      setEmail(ifEmailExistInLS);
      localStorage.removeItem('email-entered');
    }
    var referrerPage = _constants.REFERRER_PAGE_MAP[params === null || params === void 0 ? void 0 : params.referrer_code] || "default";
    var invitationSource = (params === null || params === void 0 ? void 0 : params.referral_type) || "";
    setReferralData({
      referrerPage: referrerPage,
      invitationSource: invitationSource
    });
    return function () {
      if (resendOTPTimer) {
        resendOTPTimer = null;
        clearInterval(resendOTPTimer);
      }
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (isPtlFlow) {
      if (otpLogin) {
        (0, _events.viewedLoginViaOtpByClassroomLinkedParentEvent)({
          fromPage: fromPage,
          referrerPage: (referralData === null || referralData === void 0 ? void 0 : referralData.referrerPage) || _constants.REFERRER_PAGE_MAP[params === null || params === void 0 ? void 0 : params.referrer_code] || "default",
          invitationSource: (referralData === null || referralData === void 0 ? void 0 : referralData.invitationSource) || (params === null || params === void 0 ? void 0 : params.referral_type)
        });
      } else {
        (0, _events.viewedPtlParentLoginScreenEvent)({
          fromPage: fromPage,
          ptlFlow: params !== null && params !== void 0 && params.user_id ? "PTL student login" : "PTL student linking",
          referrerPage: (referralData === null || referralData === void 0 ? void 0 : referralData.referrerPage) || _constants.REFERRER_PAGE_MAP[params === null || params === void 0 ? void 0 : params.referrer_code] || "default",
          invitationSource: (referralData === null || referralData === void 0 ? void 0 : referralData.invitationSource) || (params === null || params === void 0 ? void 0 : params.referral_type)
        });
      }
    }
  }, [otpLogin]);
  var setOTPTimer = function setOTPTimer() {
    var timeInSeconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 59;
    if (!resendOTPTimer) {
      setTimer(timeInSeconds);
      resendOTPTimer = setInterval(function () {
        setTimer(function (count) {
          if (count == 1) {
            clearInterval(resendOTPTimer);
            resendOTPTimer = null;
          }
          return count - 1;
        });
      }, 1000);
    }
  };
  var sendOTP = function sendOTP() {
    if (isPtlFlow && intentId) {
      (0, _events.interactedLoginViaOtpByClassroomLinkedParentEvent)(_objectSpread({
        clickedOn: "Resend code",
        interactionDetails: "",
        otpEntered: (otp === null || otp === void 0 ? void 0 : otp.length) === 6
      }, referralData));
    }
    if (email !== null && email !== void 0 && email.length) {
      setError(null);
      _api.APIS[intentId ? "resendOTP" : "sendOTP"](email, intentId, params === null || params === void 0 ? void 0 : params.user_id).then(function (res) {
        var _res$data;
        if (resendOTPTimer) {
          clearInterval(resendOTPTimer);
          resendOTPTimer = null;
        }
        setOTP("");
        setOTPTimer();
        setOTPSent(true);
        if (intentId) {
          setShowResendSuccessMsg(true);
          setTimeout(function () {
            setShowResendSuccessMsg(false);
          }, 5000);
        }
        if (res !== null && res !== void 0 && (_res$data = res.data) !== null && _res$data !== void 0 && _res$data.id) {
          var _res$data2;
          setIntentId(res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.id);
        }
      })["catch"](function (err) {
        var _err$response, _err$response$data, _err$response2, _err$response2$data;
        setError((err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : _err$response$data.debug_message) || (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : (_err$response2$data = _err$response2.data) === null || _err$response2$data === void 0 ? void 0 : _err$response2$data.error) || "something went wrong! try again");
      });
      sendEvent(intentId ? "Resend Verification Code" : "Send Verification Code");
    } else {
      setError("Can't be blank");
    }
  };
  var handleLogin = function handleLogin(event) {
    var _document$querySelect, _document$querySelect2, _document$querySelect3;
    var new_ptl_flow = window.location.pathname.includes("/ptl-login");
    event && event.stopPropagation();
    event && event.preventDefault();
    var userType = (_document$querySelect = (_document$querySelect2 = document.querySelector('.tab.active')) === null || _document$querySelect2 === void 0 ? void 0 : (_document$querySelect3 = _document$querySelect2.textContent) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.trim()) !== null && _document$querySelect !== void 0 ? _document$querySelect : '';
    var emailRegex = /^[a-zA-Z0-9,!#\$%&'\*\+\/=\?\^_`\{\|}~-]+(\.[a-zA-Z0-9,!#\$%&'\*\+\/=\?\^_`\{\|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,})$/;
    if (!(email !== null && email !== void 0 && email.length)) {
      setError("Can't be blank");
      return;
    }
    if (!(emailRegex !== null && emailRegex !== void 0 && emailRegex.test(email))) {
      setError("Invalid Email");
      return;
    }
    if (isPtlFlow) {
      if (otpLogin) {
        (0, _events.interactedLoginViaOtpByClassroomLinkedParentEvent)(_objectSpread({
          clickedOn: "Submit",
          interactionDetails: "",
          otpEntered: (otp === null || otp === void 0 ? void 0 : otp.length) === 6
        }, referralData));
      } else {
        (0, _events.interactedPtlParentLoginScreenEvent)(_objectSpread({
          clickedOn: "Log in",
          interactionDetails: ""
        }, referralData));
      }
    }
    if (otpLogin) {
      if (otpSent) {
        if (!(otp !== null && otp !== void 0 && otp.length)) {
          setError("Can't be blank");
          return;
        }
        if ((otp === null || otp === void 0 ? void 0 : otp.length) === 6 && intentId) {
          if (isPtlFlow) {
            (0, _events.ptlParentLoginAttemptedEvent)(_objectSpread({
              isEmailUpdated: (params === null || params === void 0 ? void 0 : params.masked_email) !== email,
              loginMethod: otpLogin ? "Verification code" : "Password"
            }, referralData));
          }
          var user_type = new_ptl_flow ? "ptl_parent" : "";
          _api.APIS.verifyOTP(intentId, email, otp, params.user_id, user_type, params.student_id, params.referral_type, params.referrer_code, new_ptl_flow, params === null || params === void 0 ? void 0 : params.trd_id).then(function (res) {
            var _res$data3;
            if (res !== null && res !== void 0 && (_res$data3 = res.data) !== null && _res$data3 !== void 0 && _res$data3.url) {
              var _res$data4;
              //@ts-ignore
              // showFlashMessage(`You have successfully logged in`, "success");
              (0, _pushEventToGTM.pushEventToGTM)('logged_in', {
                user_type: userType,
                label: 'Otp Login'
              });
              window.location.href = (res === null || res === void 0 ? void 0 : (_res$data4 = res.data) === null || _res$data4 === void 0 ? void 0 : _res$data4.url) || "/";
            }
          })["catch"](function (err) {
            var _err$response3, _err$response3$data, _err$response4, _err$response4$data;
            (0, _pushEventToGTM.pushEventToGTM)('login_failed', {
              user_type: userType,
              label: 'Otp Login'
            });
            setError((err === null || err === void 0 ? void 0 : (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : (_err$response3$data = _err$response3.data) === null || _err$response3$data === void 0 ? void 0 : _err$response3$data.debug_message) || (err === null || err === void 0 ? void 0 : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : (_err$response4$data = _err$response4.data) === null || _err$response4$data === void 0 ? void 0 : _err$response4$data.error) || "something went wrong! try again");
          });
        } else {
          setError("Incorrect verification code. Please double-check the code and try again");
        }
        sendEvent("Submit Login Details : Verification Code");
      } else {
        sendOTP();
      }
    } else {
      if (email && password) {
        if (isPtlFlow) {
          (0, _events.ptlParentLoginAttemptedEvent)(_objectSpread({
            isEmailUpdated: (params === null || params === void 0 ? void 0 : params.masked_email) !== email,
            loginMethod: otpLogin ? "Verification code" : "Password"
          }, referralData));
        }
        _api.APIS.commonPasswordLogin(params.user_id ? params.user_id : email, password, source, params.student_id, params.referral_type, params.referrer_code, new_ptl_flow, params === null || params === void 0 ? void 0 : params.trd_id).then(function (res) {
          var _res$data5;
          if (res !== null && res !== void 0 && (_res$data5 = res.data) !== null && _res$data5 !== void 0 && _res$data5.success) {
            var _res$data6;
            if (res !== null && res !== void 0 && (_res$data6 = res.data) !== null && _res$data6 !== void 0 && _res$data6["goto"]) {
              var _res$data7;
              //@ts-ignore
              // showFlashMessage(`You have successfully logged in`, "success");
              (0, _pushEventToGTM.pushEventToGTM)('logged_in', {
                user_type: userType,
                label: 'Email'
              });
              window.location.href = res === null || res === void 0 ? void 0 : (_res$data7 = res.data) === null || _res$data7 === void 0 ? void 0 : _res$data7["goto"];
            }
          }
        })["catch"](function (err) {
          var _err$response5, _err$response5$data, _err$response6, _err$response6$data;
          (0, _pushEventToGTM.pushEventToGTM)('login_failed', {
            user_type: userType,
            label: 'Email'
          });
          setError((err === null || err === void 0 ? void 0 : (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : (_err$response5$data = _err$response5.data) === null || _err$response5$data === void 0 ? void 0 : _err$response5$data.debug_message) || (err === null || err === void 0 ? void 0 : (_err$response6 = err.response) === null || _err$response6 === void 0 ? void 0 : (_err$response6$data = _err$response6.data) === null || _err$response6$data === void 0 ? void 0 : _err$response6$data.error) || "something went wrong! try again");
          if (isPtlFlow) {
            var _err$response7, _err$response7$data, _err$response8, _err$response8$data;
            // not able to send from BE in case of password failed as error is returned by warden
            (0, _events.ptlParentLoginFailedEvent)(_objectSpread({
              isEmailUpdated: (params === null || params === void 0 ? void 0 : params.masked_email) !== email,
              loginMethod: otpLogin ? "Verification code" : "Password",
              errorMessage: (err === null || err === void 0 ? void 0 : (_err$response7 = err.response) === null || _err$response7 === void 0 ? void 0 : (_err$response7$data = _err$response7.data) === null || _err$response7$data === void 0 ? void 0 : _err$response7$data.debug_message) || (err === null || err === void 0 ? void 0 : (_err$response8 = err.response) === null || _err$response8 === void 0 ? void 0 : (_err$response8$data = _err$response8.data) === null || _err$response8$data === void 0 ? void 0 : _err$response8$data.error)
            }, referralData));
          }
        });
        sendEvent("Submit Login Details : ".concat(primaryInputLabel, "/Password"));
      } else {
        setError("Can't be blank");
      }
    }
  };
  var socialLoginEnabled = (0, _react.useMemo)(function () {
    return showSocialLogin && !window.navigator.userAgent.toLocaleLowerCase().match(/fban|fbav|fbios/); //detects in app facebook browser by user agent
  }, [showSocialLogin, window.navigator.userAgent]);
  return {
    handleLogin: handleLogin,
    otpSent: otpSent,
    setOTPSent: setOTPSent,
    setIntentId: setIntentId,
    sendEvent: sendEvent,
    email: email,
    setEmail: setEmail,
    error: error,
    setError: setError,
    otpLogin: otpLogin,
    password: password,
    setPassword: setPassword,
    timer: timer,
    sendOTP: sendOTP,
    otp: otp,
    setOTP: setOTP,
    showResendSuccessMsg: showResendSuccessMsg,
    setOTPLogin: setOTPLogin,
    socialLoginEnabled: socialLoginEnabled,
    maskedEmail: params === null || params === void 0 ? void 0 : params.masked_email,
    params: params,
    emailEvents: emailEvents,
    setEmailEvents: setEmailEvents,
    passwordEvents: passwordEvents,
    setPasswordEvents: setPasswordEvents,
    setFromPage: setFromPage,
    referralData: referralData
  };
};
exports.useLogin = useLogin;