import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {};

export const teacherProperties = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TEACHER_PROPERTIES":
            return ({...state, ...action.payload}) || state;
        default:
            return state
    }
}