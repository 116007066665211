import * as Sentry from "@sentry/browser";

export const sendErrorToSentry = (err, errorInfo) => {
    if (err) {
        let eventId = null;
        Sentry.withScope((scope) => {
            if (errorInfo) {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
            }
            eventId = Sentry.captureException(err)
        });
        return eventId;
    }
    return null;
}