import ReducerConstants from "../../config/ReduxConstant";


const INITIAL_STATE = {
    // content_group_cache_map: {course_code: [content_groups], course_code2: [content_groups]}
    content_group_cache_map: {},
    curriculumList: []
}

let populateContentGroupCache = (cache, courseCode, data) => {
    cache[courseCode] = data
    return cache;
}

export const seed_data_cache = (state = INITIAL_STATE, action, storeData = {}) => {
    switch (action.type) {

        case ReducerConstants.SET_CURRICULUM_DROPDOWN:
            return {
                ...state,
                curriculumList: action.payload
            }

        case ReducerConstants.SET_CONTENT_GROUPS:
            let currentCourseCode = storeData.currentKlass.current_course.code
            let contentGroupData = state.content_group_cache_map[currentCourseCode]
            if (contentGroupData) {
                return state;
            }
            return {
                ...state,
                content_group_cache_map: populateContentGroupCache(state.content_group_cache_map, currentCourseCode, action.payload)
            }
        default:
            return state
    }
}