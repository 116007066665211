import AvoDestination from './avo-destination';
import AvoInspector from "./avo-inspector";
import Avo from './avo';
import { sendErrorToSentry } from '../ErrorBoundary/sendErrorToSentry';
const getGradeCodeFromFormattedGradeCode = (code) => {
    switch (code) {
      case "kindergarten":
        return "k";
      case "first-grade":
        return "1";
      case "second-grade":
        return "2";
      case "third-grade":
        return "3";
      case "fourth-grade":
        return "4";
      case "fifth-grade":
        return "5";
    }
  };

const getCurrentGradeCode = () => {
    try {
        const allowedRoutes = ['curriculum'];
        const pathInfo = window.location.pathname.split('/');
        if(allowedRoutes.includes(pathInfo.pop())){
            const gradeParamIdx = pathInfo.findIndex((val) => val === 'grades');

            if(gradeParamIdx < 0){
                return null;
            }

            const gradeName = pathInfo[gradeParamIdx + 1]; 
            const grade = getGradeCodeFromFormattedGradeCode(gradeName);

            if(!grade) {
                return null;
            }
            return grade;
        }
    } catch (e){
        console.error(e);
    }
    return null;
}

const trackAnalyticsEventInterceptor = window.trackAnalyticsEvent;
window.trackAnalyticsEvent = (eventName, properties={}, ...args) => {
    try {
        let productFlow = sessionStorage.getItem('product_flow');
        if(productFlow && properties){
            properties['Product Flow'] = productFlow;
        }
        trackAnalyticsEventInterceptor(eventName, properties, ...args);
    } catch(e) {
        sendErrorToSentry("trackAnalyticsEventInterceptor failed", {eventName, properties})
    }
}

const CapitalizeFirstLetter = (string = "") => {
    let res = ``;
    try {
     res = string?.charAt(0).toUpperCase() + string?.slice(1);
    } catch (error) {
      console.error("Error in capitalizeFirstLetter(). Error: ", error);
    }
    return res;
  }

var avoInitProps = {
    klassGrade: window.KLASS_GRADE?.code ?? null,
    currentGrade: window.KLASS_GRADE?.code ?? null,
    subjectCode: window.KLASS_DETAIL?.id ? "math": '',
    klass: window.KLASS_DETAIL,
};

export const subscribeToAvo = () => {
    const subscribeProps = {};
    subscribeProps.screenHeight = window.top.screen.height;
    const env = APP_MODE === "production" ? 'prod' : 'dev';
    const inspector = APP_MODE === "production" ? null : AvoInspector;
    try {
        //Check if analytics has been disabled by the user.
        Avo.initAvo({ env, inspector }, { screenHeight: subscribeProps.screenHeight }, {}, AvoDestination);
    } catch (e) {
        console.error(e)
    }
}

export var dispatchAvoEvent = ({ eventName, props, teacherCoreProperties = true, userCoreProperties = true, studentCoreProperties=false }, initProps = avoInitProps) => {
    if (eventName) {

        let teacherCoreProps = {}, studentCoreProps = {}, userCoreProps = {}

        // add teacher core properties
        if (teacherCoreProperties) {
            let klass_grade = initProps?.klassGrade;
            let current_grade = initProps?.currentGrade;
            teacherCoreProps = {
                grade: klass_grade?.toLowerCase() || "NA",
                teacherType: initProps?.teacherType || "Basic",
                isGradeDifferent: !(current_grade?.toString().toLowerCase() === klass_grade?.toString().toLowerCase()),
                class_: initProps?.klass?.name || initProps?.klass?.klassName || "",
                subject: initProps?.subjectCode || "math"
            }
        }

        if(studentCoreProperties) {
            let klass_grade = initProps?.klassGrade;
            let current_grade = initProps?.currentGrade;
            studentCoreProps = {
                grade: klass_grade?.toLowerCase() || "NA",
                mode: "school",
                mathGrade: current_grade?.toLowerCase() || "NA",
                elaGrade: current_grade?.toLowerCase() || "NA"
            }
        }

        // add user core properties (already being passed)
        if (userCoreProperties) {
            userCoreProps = {
                platform: window?.eventInitProps?.platform || initProps?.platform || "web",
                userType: window?.eventInitProps?.userType || initProps?.userType ||"Teacher",
                signupFlow: "classroom_linked"
            }
        }

        props = {
            ...teacherCoreProps,
            ...studentCoreProps,
            ...userCoreProps,
            ...props,
        }

        try {
            Avo[eventName](props);
            console.group("SCHOOL UI EVENT DISPATCHED")
            console.log(eventName);
            console.log(props);
            console.groupEnd()
        } catch (e) {
            //  if for some reason events are going through
            console.group("SCHOOL UI EVENT DISPATCH FAILED")
            console.log(eventName);
            console.log(props);
            console.warn("Avo Error", e?.message)
            console.groupEnd()
        }
    }
}


