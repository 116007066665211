"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showSurveyQuestionsAbtest = exports.showSplashCommunityAbTest = exports.showAddParentEmailScreenAbTest = exports.newConsentFlow = exports.isSummerLearningWithoutDiscountVariant = exports.isPtlInvitationVariant = exports.isOneClickAssignmentVariant = exports.isOldObwOnNewDashboardVariant = exports.isNewTeacherOnboardingVariant = exports.isNewAddSchool = exports.isNPSImprovements = exports.isMathFactAdaptiveDefaultQuestionVariant = exports.isLiveClassesByTeacherVariant = exports.isIATransitionVariant = exports.isIAExistingTeachersUpdated2024 = exports.isHomeWidgetAndAccessPageVariant = exports.isHomeAccessRevampPageVariant = exports.isHomeAccessCopyImprovementVariant = exports.isFtueVariant = exports.isDashboardRevampVariant = exports.isDRSFlutterTeacher = exports.isContinueReadingRailVariant = exports.isClassReportRevampVariant = exports.isBookRecommendationVariant = exports.isBookLibraryP3Variant = exports.isBookLibraryCcssTagVariant = exports.isBookCollectionsVariant = exports.isAssignmentExpiryVariant = exports.hasGridReportVariant = exports.hasCommonPasswordVariant = exports.hasClassHealthMeterVariant = exports.hasAssignmentThroughGridReportVariant = exports.hasAssessmentPitchPageVariant = exports.enableHomeAccessButtonVariant = exports.dashboardMigrationAbTest = void 0;
//Common mapping file to map Ab test conditions from their respective segments

var hasAssessmentPitchPageVariant = function hasAssessmentPitchPageVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::AssesmentPitchPage"];
  if (!abtestSegment || abtestSegment == 'control') return false;else return true;
};
exports.hasAssessmentPitchPageVariant = hasAssessmentPitchPageVariant;
var hasClassHealthMeterVariant = function hasClassHealthMeterVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::ClassHealthMeter"];
  if (!abtestSegment || abtestSegment == 'Class Health Meter - Control') return false;else return true;
};
exports.hasClassHealthMeterVariant = hasClassHealthMeterVariant;
var hasGridReportVariant = function hasGridReportVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::GridReports"];
  if (!abtestSegment || abtestSegment.includes('Control')) return false;else return true;
};
exports.hasGridReportVariant = hasGridReportVariant;
var hasAssignmentThroughGridReportVariant = function hasAssignmentThroughGridReportVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::AssignmentThroughGridReport"];
  if (!abtestSegment || abtestSegment == 'control') return false;else return true;
};
exports.hasAssignmentThroughGridReportVariant = hasAssignmentThroughGridReportVariant;
var hasCommonPasswordVariant = function hasCommonPasswordVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::CommonPassword"];
  if (!abtestSegment || abtestSegment == 'control') return false;else return true;
};

//Note: This ab test corresponds to isRemoteLearning variable in react app
exports.hasCommonPasswordVariant = hasCommonPasswordVariant;
var isHomeWidgetAndAccessPageVariant = function isHomeWidgetAndAccessPageVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::HomeWidgetAndAccessPage"];
  if (!abtestSegment || abtestSegment == 'control') return false;else return true;
};
exports.isHomeWidgetAndAccessPageVariant = isHomeWidgetAndAccessPageVariant;
var showSurveyQuestionsAbtest = function showSurveyQuestionsAbtest(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::Survey"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};

// abtest concluded
exports.showSurveyQuestionsAbtest = showSurveyQuestionsAbtest;
var enableHomeAccessButtonVariant = function enableHomeAccessButtonVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::HomeAccessButtonName"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.enableHomeAccessButtonVariant = enableHomeAccessButtonVariant;
var isPtlInvitationVariant = function isPtlInvitationVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::PtlInvitation"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isPtlInvitationVariant = isPtlInvitationVariant;
var isHomeAccessRevampPageVariant = function isHomeAccessRevampPageVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::HomeAccessRevampPage"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isHomeAccessRevampPageVariant = isHomeAccessRevampPageVariant;
var isMathFactAdaptiveDefaultQuestionVariant = function isMathFactAdaptiveDefaultQuestionVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::MathFactAdaptiveDefaultQuestion"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isMathFactAdaptiveDefaultQuestionVariant = isMathFactAdaptiveDefaultQuestionVariant;
var isDashboardRevampVariant = function isDashboardRevampVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::DashboardRevamp"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isDashboardRevampVariant = isDashboardRevampVariant;
var showSplashCommunityAbTest = function showSplashCommunityAbTest(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::CircleCommunity"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.showSplashCommunityAbTest = showSplashCommunityAbTest;
var showAddParentEmailScreenAbTest = function showAddParentEmailScreenAbTest(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::AddParentEmailScreen"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.showAddParentEmailScreenAbTest = showAddParentEmailScreenAbTest;
var dashboardMigrationAbTest = function dashboardMigrationAbTest(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::ShowNewTeacherDashboard"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.dashboardMigrationAbTest = dashboardMigrationAbTest;
var isSummerLearningWithoutDiscountVariant = function isSummerLearningWithoutDiscountVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::SummerLearningWithoutDiscount"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isSummerLearningWithoutDiscountVariant = isSummerLearningWithoutDiscountVariant;
var isOneClickAssignmentVariant = function isOneClickAssignmentVariant(params) {
  var abtestSegment = (params === null || params === void 0 ? void 0 : params["Teacher:OneClickAssignment"]) || (params === null || params === void 0 ? void 0 : params["Teacher::OneClickAssignment"]);
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isOneClickAssignmentVariant = isOneClickAssignmentVariant;
var isFtueVariant = function isFtueVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::FTUE"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isFtueVariant = isFtueVariant;
var isLiveClassesByTeacherVariant = function isLiveClassesByTeacherVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::LiveClassesByTeacher"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isLiveClassesByTeacherVariant = isLiveClassesByTeacherVariant;
var isOldObwOnNewDashboardVariant = function isOldObwOnNewDashboardVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::OldObwOnNewDashboard"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isOldObwOnNewDashboardVariant = isOldObwOnNewDashboardVariant;
var isDRSFlutterTeacher = function isDRSFlutterTeacher(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::DRSFlutter"];
  if (abtestSegment && abtestSegment === 'Flutter') return true;else return false;
};
exports.isDRSFlutterTeacher = isDRSFlutterTeacher;
var isNPSImprovements = function isNPSImprovements(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::NPSImprovements"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isNPSImprovements = isNPSImprovements;
var isHomeAccessCopyImprovementVariant = function isHomeAccessCopyImprovementVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::HomeAccessCopyImprovement"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isHomeAccessCopyImprovementVariant = isHomeAccessCopyImprovementVariant;
var isBookLibraryP3Variant = function isBookLibraryP3Variant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::BookLibrary"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isBookLibraryP3Variant = isBookLibraryP3Variant;
var newConsentFlow = function newConsentFlow(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::CookieManager"];
  if (!abtestSegment || abtestSegment === 'control') return false;else return true;
};
exports.newConsentFlow = newConsentFlow;
var isNewTeacherOnboardingVariant = function isNewTeacherOnboardingVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::NewTeacherOnboarding"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isNewTeacherOnboardingVariant = isNewTeacherOnboardingVariant;
var isNewAddSchool = function isNewAddSchool(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::AddSchool"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isNewAddSchool = isNewAddSchool;
var isContinueReadingRailVariant = function isContinueReadingRailVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::ContinueReadingRail"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isContinueReadingRailVariant = isContinueReadingRailVariant;
var isBookCollectionsVariant = function isBookCollectionsVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::BookCollectionPhase1"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isBookCollectionsVariant = isBookCollectionsVariant;
var isAssignmentExpiryVariant = function isAssignmentExpiryVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::AssignmentExpiry"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isAssignmentExpiryVariant = isAssignmentExpiryVariant;
var isIATransitionVariant = function isIATransitionVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::IAExistingTeachers2024"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isIATransitionVariant = isIATransitionVariant;
var isBookRecommendationVariant = function isBookRecommendationVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::BookRecommendation"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isBookRecommendationVariant = isBookRecommendationVariant;
var isIAExistingTeachersUpdated2024 = function isIAExistingTeachersUpdated2024(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::IAExistingTeachersUpdated2024"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isIAExistingTeachersUpdated2024 = isIAExistingTeachersUpdated2024;
var isClassReportRevampVariant = function isClassReportRevampVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::ClassReportRevamp"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isClassReportRevampVariant = isClassReportRevampVariant;
var isBookLibraryCcssTagVariant = function isBookLibraryCcssTagVariant(params) {
  var abtestSegment = params === null || params === void 0 ? void 0 : params["Teacher::BookLibraryCcssTags"];
  if (!abtestSegment || abtestSegment === 'Control') return false;else return true;
};
exports.isBookLibraryCcssTagVariant = isBookLibraryCcssTagVariant;