import Fonts from "baseStyles/enums/fonts";
import { useSuiStyles, SuiStylesType } from "baseStyles/useSuiStyles";
import Device from "baseStyles/enums/device";
import { ButtonStylesType } from "./types";

type buttonStyleType = {
    height: string;
    padding?: string;
    shadowWidth?: number;
}

type StylesType = {
    [k in ButtonStylesType]?: buttonStyleType;
}

const Styles: SuiStylesType<{}, StylesType> = {
    [Device.types.Desktop]: () => {
        return {
            [ButtonStylesType.XL]: {
                height: "68px",
                shadowWidth: 6,
                // padding: "21px 50px"
            },
            [ButtonStylesType.L]: {
                height: "58px",
                shadowWidth: 4,
                // padding: "16px 38px"
            },
            [ButtonStylesType.M]: {
                height: "48px",
                shadowWidth: 4,
                // padding: "11px 28px"
            },
            [ButtonStylesType.S]: {
                height: "40px",boxShadow: "0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset",
                shadowWidth: 3,
                // padding: "8px 22px"
            },
            [ButtonStylesType.XS]: {
                height: "48px",boxShadow: "0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset",
                width: "112px",
                shadowWidth: 3,
            },
        }
    },
    [Device.types.Tablet]: () => {
        return {
            [ButtonStylesType.XL]: {
                height: "68px",
                // padding: "22px 50px"
            },
            [ButtonStylesType.L]: {
                height: "52px",
                // padding: "14px 32px"
            },
            [ButtonStylesType.M]: {
                height: "48px",
                // padding: "14px 28px"
            },
            [ButtonStylesType.S]: {
                height: "38px",
                // padding: "9px 22px"
            },
            [ButtonStylesType.XS]: {
                height: "48px",boxShadow: "0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset",
                width: "112px",
                shadowWidth: 3,
            },
        }
    }
}

const ButtonStyles = useSuiStyles(Styles);

export default ButtonStyles;