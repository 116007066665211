import { GlobalSpinner } from '../../config/common';
import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import BackArrowGreySvg from '../../assets/common/backArrowGrey.svg'
import IconBookMark from 'TEACHER_ASSETS/images/worksheets/icon_bookmark.svg';
import IconDownloadedList from 'TEACHER_ASSETS/images/worksheets/icon_downloaded_list.svg';
import IconBookmarkedList from 'TEACHER_ASSETS/images/worksheets/icon_bookmarked_list.svg';
import { sendErrorToSentry } from '../teacher_app_container/ErrorBoundary/sendErrorToSentry';

const PDFViewer = ({
    file = null,
    title = null,
    subtitle = null,
    isSaved = false,
    showSave = true,
    onSave = () => { },
    onUnsave = () => { },
    onDownload = () => { },
    onBack = () => { },
    isMobileView = false
}) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const escFunction = (event) => {
        if (event.key === "Escape") {
            onBack();
        }
    }

    useEffect(() => {
        GlobalSpinner.show();
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        GlobalSpinner.hide();
    }

    const onDocumentLoadError = (error) => {
        GlobalSpinner.hide();
        showFlashMessage('Unable to load worksheet', 'error');
        sendErrorToSentry(error, {
            file: file
        })
    }

    const renderThumbnail = useMemo(() => {
        let thumbnails = [];
        for (let i = 1; i <= numPages; i++) {
            thumbnails.push(
                <div className={`thumbnail ${i == pageNumber ? 'active' : ''}`}>
                    <div className={`page`} onClick={() => setPageNumber(i)}>
                        <Page width={145} height={234} pageNumber={i} />
                    </div>
                    <div className='info'>{i}/{numPages}</div>
                </div>
            )
        }
        return thumbnails;
    }, [numPages, pageNumber])

    return (
        <div className='app-pdf-viewer'>
            <div className='pdf-header'>
                <div className='header-info'>
                    <div className='back-button' onClick={onBack}><BackArrowGreySvg /></div>
                    <div className='details'>
                        <div className='title'>{title || 'Untitled'}</div>
                        {subtitle && <div className='subtitle'>{subtitle}</div>}
                    </div>
                </div>
                <div className='actions'>
                    {showSave && <div className='save-button' onClick={isSaved ? onUnsave : onSave}>{isSaved ? <><IconBookmarkedList /> Saved</> : <><IconBookMark /> Save</>}</div>}
                    <a onClick={onDownload}><div className='download-button'><IconDownloadedList /> Download</div></a>
                </div>
            </div>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                {
                    numPages > 0 ? (<>
                        {!isMobileView ? <div className="thumbnails">
                            {renderThumbnail}
                        </div> : null}
                        <div className="preview">
                            <Page width={600} height={700} pageNumber={pageNumber} />
                        </div>
                    </>) : null
                }
            </Document>
        </div>
    )
}

export default PDFViewer;