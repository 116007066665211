"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.feedbackSeedData = void 0;
var _ReduxConstant = _interopRequireDefault(require("../../config/ReduxConstant"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var INITIAL_STATE = [];
var feedbackSeedData = function feedbackSeedData() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _ReduxConstant["default"].SET_FEEDBACK_SEED_DATA:
      return action.payload || [];
    default:
      return state;
  }
};
exports.feedbackSeedData = feedbackSeedData;