// these values are only used in each request as common param
import {CommonMessages, PLAYABLE_SERVICE_URL, USERS_PATH_PREFIX} from "../../utils/constants";
import history from "../components/teacher_app_container/history"; //@todo: make sure this would not error out for old app
import { throwApiError, throwApiResposeReadingError } from "../components/teacher_app_container/ErrorBoundary/APIError";
import BaseUrls from "./baseUrls";
import Store from "../store";
import { SubjectCodes } from "printableWorksheets/state";
import { getSourceFromPath } from "../utils/commonHelpers";
import { AbTestMapping } from "./class_left_nav_container/common";
import API_CALLER from "../service/api";

if(!window.isLeftnavFlag){
  // var teacher_current_klass_id = document.getElementById('teacher_current_klass_id').value;
  // var teacher_current_grade_code = document.getElementById('teacher_current_grade_code').value;

  // these values are only used in each events used for analytics by product team
  // var klass_name = document.getElementById('teacher_current_klass_name_id').value;
  // var grade_name = document.getElementById('teacher_current_grade_name_id').value;
  // var is_grade_diff = document.getElementById('teacher_is_current_grade_different_id').value;
}

/**
 * This function can used for navigation between path
 * @param {Object} event - event of DOM callback
 * @param {String} path - path where to navigate
 * @param {boolean} replace - Replaces the current entry on the history stack if true
 */
const navigateToPath = (event, path, replace = false, state={}) => {
  let pathToRouteTo = path;
  if(sessionStorage){
    let path = window.location.pathname;
    let pathArray = path?.split('/');
    const pathToStore =  state?.fromPage || pathArray?.[pathArray.length - 1];
    sessionStorage.setItem('previous_path', pathToStore);
  }

  const endpoint = path.split('/').pop();
  if(endpoint === "springboard"){
    const pathArr = path.split('/');
    const indexOfGrades = pathArr.indexOf('grades');
    if(indexOfGrades > -1){
      pathArr.splice(indexOfGrades, 2);
      pathToRouteTo = pathArr.join('/');
    }
  }

  if (window.isLeftnavFlag) {
    if(replace){
      history.replace(pathToRouteTo, state)
    }
    else {
      history.push(pathToRouteTo, state);
    }
    event && event.preventDefault();
  }
};

var loadingSpinnerCount = 0;
var Spinner = {
  show: () => {
    loadingSpinnerCount++;
    var element = document.getElementById('ajax-spinner-id') || document.getElementById('spinner');
      if (element) {
        if (!element.hasAttribute('data-test-id')){
          element.setAttribute('data-test-id', 'bubble-wait-icon')
        }
        element.style.display = 'block';
      }
  },
  hide: () => {
    loadingSpinnerCount--;
    if(loadingSpinnerCount <= 0) {
      loadingSpinnerCount = 0;
      var element = document.getElementById('ajax-spinner-id') || document.getElementById('spinner');
      if (element) {
        element.style.display = 'none';
      }
    }
  }
}

var GradeAccordingTOCurriculum = function(curriculum_code, grade_code) {

  const curriculumToGradeMapping = {
    "uk": {
      "K" : "Year 1",
      "k" : "Year 1",
      "1" : "Year 2",
      "2" : "Year 3",
      "3" : "Year 4",
      "4" : "Year 5",
      "5" : "Year 6"
    },
    "aus": {
      "K" : "Kindergarten",
      "k" : "Kindergarten",
      "1" : "Year 1",
      "2" : "Year 2",
      "3" : "Year 3",
      "4" : "Year 4",
      "5" : "Year 5"
    },
    "ccss": {
      "K" : "Kindergarten",
      "k" : "Kindergarten",
      "1" : "Grade 1",
      "2" : "Grade 2",
      "3" : "Grade 3",
      "4" : "Grade 4",
      "5" : "Grade 5"
    }
  };
  if (curriculum_code == "uk" || curriculum_code == "aus"){
    return curriculumToGradeMapping[curriculum_code][grade_code];
  }
  else {
    return curriculumToGradeMapping["ccss"][grade_code];
  }
}

var DateFormating = function (inpDate, dateRange, dateFormat, rangeFormat){
  let monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  if(dateFormat == undefined){
    // 2019-09-02T23:59:59+05:30  >>  2019-09-02T23:59:59
    // 2019-09-02T23:59:59Z  >>  2019-09-02T23:59:59
    var currentDatetime = inpDate  || new Date();
    if(typeof inpDate === "string") {
      inpDate = inpDate.substring(0, 19);
      currentDatetime = new Date(JSON.parse('"' + inpDate + '"'));
    }

    var months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    if(!dateRange){
      var formattedDate = months[currentDatetime.getMonth()] + " " + currentDatetime.getDate() + ", " + currentDatetime.getFullYear();

      return formattedDate;
    }
    else {
      var rangeDatetime = dateRange || new Date();
      if(typeof dateRange === "string") {
        dateRange = dateRange.substring(0, 19);
        rangeDatetime = new Date(JSON.parse('"' + dateRange + '"'));
      }
      var formatedDateRange = months[currentDatetime.getMonth()] + " " + currentDatetime.getDate() + " to " + months[rangeDatetime.getMonth()] + " " + rangeDatetime.getDate() + ", " + rangeDatetime.getFullYear();
      if(rangeFormat === "snakeCase"){
        formatedDateRange = monthArr[currentDatetime.getMonth()] + " " + currentDatetime.getDate() + " to " + monthArr[rangeDatetime.getMonth()] + " " + rangeDatetime.getDate() + ", " + rangeDatetime.getFullYear();
      }

      return formatedDateRange;
    }
  }
  else if(dateFormat == "dd Mon"){
    return inpDate.getDate() + " " + monthArr[inpDate.getMonth()];
  }
  else if(dateFormat === "MON DD"){
    return monthArr[inpDate.getMonth()].toUpperCase() + " " + inpDate.getDate();
  }

  else if(dateFormat === "Mon dd"){
    return monthArr[inpDate.getMonth()] + " " + inpDate.getDate();
  }
  else if(dateFormat == "Mon dd, yyyy"){
    let currentDatetime = new Date(JSON.parse('"' + inpDate + '"'));
    return monthArr[currentDatetime.getMonth()] + " " + currentDatetime.getDate() + ", " + currentDatetime.getFullYear();
  }
  else if(dateFormat == "yyyy-mm-dd"){
    const dt = new Date(inpDate);
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    return dt.getFullYear() + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + dd;
  }
  else if(dateFormat == "dd-mm-yyyy"){
    const dt = new Date(inpDate);
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    return dd + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + dt.getFullYear() ;
  }
  else if(dateFormat === "dd Mon YYYY"){
    const dt = new Date(inpDate);
    return dt.getDate() + " " + monthArr[dt.getMonth()] +  " " + dt.getFullYear();
  }
  else if(dateFormat === "dd ord Mon YYYY"){
    const dt = new Date(inpDate);
    return getOrdinalSuffix(dt.getDate()) + " " + monthArr[dt.getMonth()] +  " " + dt.getFullYear();
  }
  else if(dateFormat == "dd ord Mon"){
    return getOrdinalSuffix(inpDate.getDate()) + " " + monthArr[inpDate.getMonth()];
  }
  else if(dateFormat == "dd Mon HH:MM"){
    return `${inpDate.getDate()} ${monthArr[inpDate.getMonth()]}, ${("0"+(new Date().getHours()%12)).slice(-2)}:${("0"+new Date().getMinutes()).slice(-2)} ${new Date().getHours() > 11 ? 'PM' : 'AM'}`;
  }
  else if(dateFormat == "Mon dd HH:MM"){
    return `${monthArr[inpDate.getMonth()]} ${inpDate.getDate()}, ${("0"+(new Date().getHours()%12)).slice(-2)}:${("0"+new Date().getMinutes()).slice(-2)} ${new Date().getHours() > 11 ? 'PM' : 'AM'}`;
  }
  else if(dateFormat == "dd ord Mon HH:MM sm"){
    return `${getOrdinalSuffix(inpDate.getDate()) + " " + monthArr[inpDate.getMonth()]}, ${("0"+(inpDate.getHours()%12)).slice(-2)}:${("0"+inpDate.getMinutes()).slice(-2)} ${inpDate.getHours() > 11 ? 'pm' : 'am'}`;
  }
  else if(dateFormat == "mm-dd-yyyy") {
    const dt = new Date(inpDate);
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    return (mm <= 9 ? '0' + mm : mm) + '-' + dd + '-' + dt.getFullYear() ;
  }
}

// Common API to get the list of assigned ab_tests for a user.

let getAssignedAbTestsForUser = (dataObj) => {
  apiCaller({
    path: USERS_PATH_PREFIX +'/users/'+ dataObj.user_id +'/assigned_ab_tests',
    callback: dataObj.callback,
    errorCallback: dataObj.errorCallback,
    dontShowAjaxSpinner: true,
  });
};

let urlShorteningServiceFetchShortUrl = (dataObj) => {
  const params = {long_url: dataObj.long_url};
  apiCaller({
    method: 'post',
    path: BaseUrls.base_api_gateway_url + '/short_url_service/public/api/v1/create',
    params,
    callback: dataObj.callback,
    errorCallback: dataObj.errorCallback,
    extServiceFlag: true
  });
};
let urlShorteningServiceFetchShortUrlAsync = async (dataObj) => {
  const params = {long_url: dataObj.long_url};
  let newDataObj = {
    params,
    extServiceFlag: true,
    dontShowAjaxSpinner: dataObj?.dontShowAjaxSpinner
  }

    let resp = await API_CALLER.postExternalServiceAPI(BaseUrls.base_api_gateway_url + '/short_url_service/public/api/v1/create', newDataObj);
  return resp.short_url;
};


/**
 * This function calculates the week dates for a particular date
 *
 * @param   {Date?} date Date for which to find the week. Optional, if not provided defaults to today
 * @returns {Array<Date>} Array containing dates for the week
 */
var getCurrentWeek = (date) => {
  let dateRange = [];

  let curr = date ? new Date(date) : new Date();
  let first;
  if(curr.getDay() == 0){
    first = curr.getDate() - 6;
  }
  else {
    first = curr.getDate() - curr.getDay() +1;
  }
  let firstday = new Date(curr.setDate(first));
  let lastday = new Date(curr.setDate(firstday.getDate() + 6));

  let tmpDate = firstday;
  while(tmpDate.getTime() <= lastday.getTime()) {
    dateRange.push(new Date(tmpDate));
    tmpDate.setDate(tmpDate.getDate() + 1);
  }

  return dateRange;
}

/**
 * This function calculates difference between date2 and date1 (by days)
 * @note If no second argument is passed it defaults to today
 *
 * @param {Date/DateString} date1 first date to compare
 * @param {Date/DateString?} date2 second date to compare. Defaults to today
 * @returns {Number} difference date2 minus date1
 */
var dateDiffInDays = (date1, date2 = new Date()) => {
  let d1 = date1 ? new Date(date1) : new Date();
  let d2 = date2 ? new Date(date2) : new Date();
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

var dateDiffInMinutes = (date1, date2) => {
  let d1 = date1 ? new Date(date1) : new Date();
  let d2 = date2 ? new Date(date2) : new Date();
  const _MS_PER_MIN = 1000 * 60;

  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate(), d1.getHours(), d1.getMinutes());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate(), d2.getHours(), d2.getMinutes());

  return Math.floor((utc2 - utc1) / _MS_PER_MIN);
}

/**
 * This function returns 0 indexed weekday number starting from Mon ending Sunday
 *
 * @param {Date} date Date for which to calculate day number
 */
var getDayFromDate = (date) => {
  let dayN = date.getDay();
  return dayN == 0 ? 6 : dayN - 1;
}

// convert array to object
Array.prototype.arrayToObj = function(key){
  var randomObj = {}, errorFlag = false;
  this.map(function(item){
    if(typeof(item[key]) !== 'undefined'){
      Object.assign(randomObj, {[item[key]]: item} );
    }
    else {
      errorFlag = true;
    }
  });
  if(errorFlag) {
    console.error("Key dosen't match.");
  }
  return randomObj;
}

// time formating from sec to hh:mm or mm:ss

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  if(hours > 0) {
    return hours+'h '+minutes+'m';
  }
  return minutes+'m '+seconds+'s';
}

if(!String.prototype.capitalize) {
  String.prototype.capitalize = function() {
    return this.replace(/(^|\s)([a-z])/g, function(e, t, n) {
        return t + n.toUpperCase()
    })
  }
}

if (!Array.prototype.hasOwnProperty("chunk_inefficient")) {
  Object.defineProperty(Array.prototype, "chunk_inefficient", {
    value: function (chunkSize) {
      var array = this;
      return [].concat.apply(
        [],
        array.map(function (elem, i) {
          return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
        })
      );
    },
  });
}

var previewPlayable = function(params) {
  const state = Store.getState();
  launchPlayable(params , state.teacherProfile.teacher.id , params.LO_id);
}

var AnalyticsEvents = (name, properties, sendGA) => {
  properties = properties || {};
  var eventProperties = {};
  Object.keys(properties).map(function(key){ eventProperties[key] = properties[key]});

  //In case of left nav React app, we will fetch following variables from store rather than hidden inputs
  if(window.isLeftnavFlag && !["/classes/add"].includes(window.location.pathname)){
    const state = Store.getState();
    var klass_name = state.currentKlass.klass.klassName;
    var klass_grade = state.currentKlass.klass.gradeCode;
    var current_grade = state.currentKlass.current_course && state.currentKlass.current_course.grade_code;
    var grade_name = klass_grade?.toLowerCase()
    var is_grade_diff = !(current_grade?.toString().toLowerCase() === klass_grade?.toString().toLowerCase())
  }

  eventProperties['Variation'] = 'left nav';
  eventProperties['Class'] = klass_name;
  
  const productFlow = sessionStorage.getItem('product_flow');
  if(productFlow){
    eventProperties['Product Flow'] = productFlow;
  }

  //If Grade property not supplied from React component we set it to default value (from Rails)
  if(!eventProperties['Grade']) {
    eventProperties['Grade'] = grade_name;
  }
  if(!eventProperties['Is Grade Different']){
    eventProperties['Is Grade Different'] = is_grade_diff;
  }

  trackAnalyticsEvent(name, eventProperties, sendGA);
}

/**
 * This function is utility to create get url with query parameters
 *
 * @param {String} url - url
 * @param {Object} data - object of key value that will be used as query parameter
 * Example :
 * appendQueryParams("https://xyz.com", {key1: value1, key2: value2})
 *
 * it will return :
 * https://xyz.com?key1=value1&key2=value2
 */
var appendQueryParams = (url = "", data = {}) => {
  if (url) {
    if (data && typeof data === "object") {
      let paramsArr = [];
      Object.keys(data).map((key) => {
        paramsArr.push(`${key}=${data[key]}`)
      })
      let paramsStr = paramsArr.join("&");
      url += `${url.includes('?') ? '&' : '?'}${paramsStr}`;
    }
  }
  return url;
}


/**
 * This function can used to define defferent action at UI when there is error code in APi response
 * @param {Object} apiResponse - Error Information
 * @param {String} responseBody - If there is any response from backend for the error
 */
 const handleResponseError = (apiResponse, responseBody) => {
  let errorMessage = '';
  let reloadFlag = false;

  switch(apiResponse.status) {
    case 401:
      reloadFlag = true;
      break;
    case 403: // will handle
    case 402: // will handle
    case 500: // will handle
    default:
      errorMessage = CommonMessages.commonErrorMessage;
  }

  if(responseBody) {
    const message = responseBody.error || responseBody.message;
    if(typeof message === 'string') {
      errorMessage = message;
    }
  }

  if(errorMessage) {
    showFlashMessage(errorMessage, 'error');
  }

  if(reloadFlag) {
    window.location.reload(true);
  }
}

var apiCaller = async function(config){

  const EXT_SERVICE_MODE = 'cors';
  const EXT_SERVICE_CREDENTIALS = 'include';

  var dummyData = config.dummyData || {};
  var url = config.path;
  var methodType = config.method || 'get';
  var params = config.params || {};
  var append_klass_id = config.append_klass_id || false;
  const extServiceFlag = config.extServiceFlag;

  if(window.isLeftnavFlag){
    const state = Store.getState();
    var teacher_current_grade_code = state.currentKlass?.current_course?.grade_code?.toString().toUpperCase();
    var teacher_current_klass_id = state.currentKlass?.klass?.id;
  }
  url +=  (url.includes('?') ? '&': '?')  + 'selected_grade_code=' + teacher_current_grade_code;
  url +=  (append_klass_id ? '&klass_id=' + teacher_current_klass_id : '');

  var csrfToken = document.querySelector('meta[name=csrf-token]').content;

  var successCallback = config.callback;
  // var errorCallback = config.errorCallback || function(){showFlashMessage('An error occured, please try again later.', 'error');};
  var errorCallback = config.errorCallback || function(){};

  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  }

  var requestConfig = {
    method: methodType,
    credentials: "same-origin",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-CSRF-Token': csrfToken
    }
  }

  if (extServiceFlag) {
    requestConfig['mode'] = EXT_SERVICE_MODE;
    requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
  }

  if(methodType != 'get' && !!params){
    requestConfig.body = JSON.stringify(params);
  }

  if (!config.dontShowAjaxSpinner) {
    Spinner.show();
  }
  if(Object.keys(dummyData).length > 0 && dummyData.constructor === Object){
    successCallback && successCallback(dummyData);
  } else {
    let response = {};
    let parsedResponse = {};
    try {
      response = await fetch(url, requestConfig)
      parsedResponse = await response.json();
    } catch(e) {
      // throwApiResposeReadingError(e, response);
    }
    let isRequestSuccessful = checkStatus(response);

    if(isRequestSuccessful){
      if (!config.dontShowAjaxSpinner) {
        Spinner.hide();
      }
      successCallback && successCallback(parsedResponse)
    } else {
      if (!config.dontShowAjaxSpinner) {
        Spinner.hide();
      }
      handleResponseError(response, parsedResponse);
      errorCallback && errorCallback(parsedResponse)
      // throwApiError(response);
    }
  }
}

var fileUploadApiCaller = async function(config){
  const state = Store.getState();
  var dummyData = config.dummyData || {};
  var url = config.path;
  url +=  (url.includes('?') ? '&': '?') + 'klass_id=' + state.currentKlass.klass.id + '&grade_code=' + state.currentKlass.klass.current_course.grade_code;

  var csrfToken = document.querySelector('meta[name=csrf-token]').content;

  var successCallback = config.callback;
  var errorCallback = config.errorCallback || function(){};

  function status(response) {
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  }

  function json(response) {
    return response.json()
  }

  var formData = new FormData();
  var fileField = document.getElementById(config.selectorElmId);
  formData.append(config.selectorVariable, fileField.files[0]);
  if (config.source){
    formData.append('source', config.source);
    formData.append('assignment_id', config.assignment_id);
  }


  let requestConfig = {
    method: 'post',
    credentials: "same-origin",
    body: formData,
    headers: {
      'X-CSRF-Token': csrfToken
    }
  }

  if (!config.dontShowAjaxSpinner) {
    Spinner.show();
  }

  if(Object.keys(dummyData).length > 0 && dummyData.constructor === Object){
    successCallback && successCallback(dummyData);
  } else {
    let response = await fetch(url, requestConfig)
    let success = status(response)
    let json_response = await response.json()
    if(success){
      if (!config.dontShowAjaxSpinner) {
        Spinner.hide();
      }
      successCallback && successCallback(json_response)
    }else{
      if (!config.dontShowAjaxSpinner) {
        Spinner.hide();
      }
      errorCallback && errorCallback(json_response);
    }
  }
}

var launchPlayable = function (params , teacherId , loId) {
  const gamePlayableData = getCookie('game_playable_url')? JSON.parse(getCookie('game_playable_url')): {};
  const worksheetPlayableData = getCookie('worksheet_playable_url')? JSON.parse(getCookie('worksheet_playable_url')): {};
  let prefix = '';
  const isGameLevelCluster = params.PlayableData.entity_type === 'GameLevelCluster';
  const isWorksheet = params.PlayableData.entity_type === 'Worksheet';
  if(isGameLevelCluster || isWorksheet){
    prefix = BaseUrls.base_api_gateway_url + PLAYABLE_SERVICE_URL;
  }
  let path = `${prefix}/iframes/`;
  path += (params.PlayableData.entity_type === 'Worksheet' ? 'worksheet_playable' : 'game_playable' )+ '?learning_objective_id=' + loId + '&playable_id=' + params.PlayableData.id + '&click_source=curriculum';
  path += `&userId=${teacherId}&guardian_id=${''}&meta_user_session_id=${isGameLevelCluster ? (gamePlayableData?.meta_user_session_id || "") : (isWorksheet ? (worksheetPlayableData?.meta_user_session_id || "") : '')}&widget=true`
  SPWidget.launchWidgetWithPath(path, params.PlayableData.title);
}

/**
 * This function can used to set a document cookie with a particular age
 * @param {String} name - Name of the cookie to set
 * @param {String} value - Value of the cookie
 * @param {Number} maxAge - Specifies the cookie expiration (in seconds) from the current moment
 */
var setCookie = (name, value, maxAge) => {
  document.cookie = name + "=" + value + "; max-age=" + maxAge + "; path=/";
}

/**
 * This function can used to get a document cookie by its name
 * @param {String} name - Name of the cookie to get
 */
var getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * This function will expire the provided cookie
 *
 * @param {String} name - Name of the cookie to remove
 */
var deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

/**
 * Function to round off time in seconds to nearest minute
 *
 * @param {Number} timeInSeconds
 */
var roundoffTimeSpent = (timeInSeconds) => {
  let timeInMinutes = Math.floor(timeInSeconds / 60);
  let secondsLeft = timeInSeconds % 60;

  if (secondsLeft >= 30 && timeInMinutes > 0) {
    timeInMinutes++;
  }

  return timeInMinutes;
};

const getCanvasWidth = () => {
  return document.getElementsByClassName('global')[0].offsetWidth
}

const getOrdinalSuffix = (i) => {
  let j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}

const getDateByDays = (days = 0) => {
  return new Date(new Date().getTime() + (days * 24 * 60 * 60 * 1000))
}

// returns true if user sign ups from All Worksheets/Math WS/ELA WS/WS Detail
export const hasSignedUpFromWs = () => {
  const signupPageUrl = sessionStorage.getItem('signup_page_url') || '';
  // There can be 5 types of possible values when user sign ups from Worksheets pages -
  // 1. s/math-worksheets/identify-bigger-or-smaller
  // 2. s/ela-worksheets/find-the-word-look
  // 3. worksheets
  // 4. ela-worksheets
  // 5. math-worksheets

  if(signupPageUrl.includes('worksheets') && !JSON.parse(localStorage.getItem('wsPreviewShownViaOnboarding'))) {
    return true;
  }
  else {
    return false;
  }
}

export const fetchSubjectFromSignupUrl = () => {
  const signupPageUrl = sessionStorage.getItem('signup_page_url') || '';
  if(signupPageUrl.includes('math')) {
    return SubjectCodes.MATH;
  }
  else {
    return SubjectCodes.ELA;
  }
}

// returns true only for the 1st time if user sign ups from worksheet detail page
export const isSignedUpFromWsDetail = () => {
  const signupPageUrl = sessionStorage.getItem('signup_page_url') || '';
  if(signupPageUrl.includes('worksheets') && (signupPageUrl.includes('s/') || localStorage.getItem("clickedWsFromListPage")) && JSON.parse(localStorage.getItem('viaWsOnboarding'))) { //viaWsOnboarding is set true in web-ui repo
    return true;
  }
  return false;
}

export const canDownloadWs = () => {
  return JSON.parse(localStorage.getItem('canDownloadWs'));
}

export const freeWsDownloadLeft = () => {
  return JSON.parse(localStorage.getItem('showWsCountText')) ? localStorage.getItem('free_ws_download_left') : 'unlimited';
}

export const totalWsDownloadCount = () => {
  return JSON.parse(localStorage.getItem('total_ws_download_count') || '2');
}

export const isFlutterUser = () => {
  if(JSON.parse(localStorage.getItem("isFlutterUser"))) {
    return true;
  }
  return false;
}

 // function for awating
export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const checkLiveClassEligibility = (teacher, abTestData) => {
  return (teacher?.country_code === 'US' || teacher?.country_code === 'CA') && AbTestMapping.isLiveClassesByTeacherVariant(abTestData?.assigned_ab_tests || {});
}

export const isNameInvalid = (name) => {
  return !name || (typeof name == "string" && name?.trim() == "")
}

export const removeEmptyKeys = (object) => {
  Object.keys(object).forEach(key => {
    if (object[key] === undefined || object[key] === null)  {
      delete object[key];
    }
  });
  return object;
}

export const scrollElementIntoView = (elementId) => {
  setTimeout(() => {
    const elem = document.getElementById(elementId);
    if(elem){
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    }
  } , 100)
}

export const GlobalSpinner = Spinner;
export const DateFormat = DateFormating;
export const SendAnalytics = AnalyticsEvents;
export const AppendQueryParams = appendQueryParams;
export const ApiCaller = apiCaller;
export const FileUploadApiCaller = fileUploadApiCaller;
export const GradeTOCurriculum = GradeAccordingTOCurriculum;
export const PreviewPlayable = previewPlayable;
export const LaunchPlayable = launchPlayable;
export const SetCookie = setCookie;
export const GetCookie = getCookie;
export const DeleteCookie = deleteCookie;
export const GetCurrentWeek = getCurrentWeek;
export const DateDiffInDays = dateDiffInDays;
export const GetDayFromDate = getDayFromDate;
export const RoundOffTimeSpent = roundoffTimeSpent;
export const GetAssignedAbTestsForUser = getAssignedAbTestsForUser;
export const UrlShorteningServiceFetchShortUrl = urlShorteningServiceFetchShortUrl;
export const UrlShorteningServiceFetchShortUrlAsync = urlShorteningServiceFetchShortUrlAsync;
export const NavigateToPath = navigateToPath;
export const GetCanvasWidth = getCanvasWidth
export const DateDiffInMinutes = dateDiffInMinutes;
export const GetDateByDays = getDateByDays;
export const IsNameInvalid = isNameInvalid;

export default apiCaller;
