import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    selectedPageName: "teacher_home",
    selectedPageParentName: "teacher_home",
    klassProfile: {}
}


export const selectedCurrentData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_CLASS_HIDE_COMMON_PASSWORD_POPUP":
            // @TDSM fix this
            return {
                ...state,
                klassProfile: {
                    ...state.klassProfile,
                    klass: {
                        ...state.klassProfile.klass,
                        show_common_password_popup: false
                    }
                }
            }
        case "SET_SELECTED_PAGE":
            return {
                ...state,
                selectedPageName: action.payload.selectedPageName,
                selectedPageParentName: action.payload.selectedPageParentName
            }
        default:
            return state
    }
}