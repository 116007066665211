import _ from 'lodash';

const MathFactReportingInitialState = {
    studentWiseFactDetailLoading: false,
    studentWiseFactDetail: {},
    studentOperatorWiseCountLoading: false,
    studentOperatorWiseCount: {},
    dateWiseStudentActivityLoading: false,
    dateWiseStudentActivity: [],
    dateWiseProficientFactCountLoading: false,
    dateWiseProficientFactCount: {}
}

class MathFactReportingModel {
    static __instance__ = new MathFactReportingModel();
    studentWiseFactDetailLoading = MathFactReportingInitialState.studentWiseFactDetailLoading;
    studentWiseFactDetail = MathFactReportingInitialState.studentWiseFactDetail;
    studentOperatorWiseCountLoading = MathFactReportingInitialState.studentOperatorWiseCountLoading;
    studentOperatorWiseCount = MathFactReportingInitialState.studentOperatorWiseCount;
    dateWiseStudentActivityLoading = MathFactReportingInitialState.dateWiseStudentActivityLoading;
    dateWiseStudentActivity = MathFactReportingInitialState.dateWiseStudentActivity;
    dateWiseProficientFactCountLoading = MathFactReportingInitialState.dateWiseProficientFactCountLoading;
    dateWiseProficientFactCount = MathFactReportingInitialState.dateWiseProficientFactCount;

    constructor() {}

    static get singleInstance() {
        if(!this.__instance__) {
            this.__instance__ = new MathFactReportingModel();
        }
        return this.__instance__;
    }

    static setStudentWiseFactDetail(data) { this.singleInstance.studentWiseFactDetail = _.merge(this.singleInstance.studentWiseFactDetail, data); return this.singleInstance;}
    static studentOperatorWiseCount(data) { this.singleInstance.studentOperatorWiseCount = _.merge(this.singleInstance.studentOperatorWiseCount, data); return this.singleInstance;}
    static dateWiseStudentActivity(data) { this.singleInstance.dateWiseStudentActivity = data; return this.singleInstance;}
    static dateWiseProficientFactCount(data) { this.singleInstance.dateWiseProficientFactCount = data; return this.singleInstance;}

    static toggleStudentWiseFactDetailLoading(value) { this.singleInstance.studentWiseFactDetailLoading = value; return this.singleInstance;}
    static toggleStudentOperatorWiseCountLoading(value) { this.singleInstance.studentOperatorWiseCountLoading = value; return this.singleInstance;}
    static toggleDateWiseStudentActivityLoading(value) { this.singleInstance.dateWiseStudentActivityLoading = value; return this.singleInstance;}
    static toggleDateWiseProficientFactCountLoading(value) { this.singleInstance.dateWiseProficientFactCountLoading = value; return this.singleInstance;}

    static initialState() {
        this.__instance__ = null;
        return this.singleInstance;
    }
}

export default MathFactReportingModel;