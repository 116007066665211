import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    courses: [],
    curriculums: [],
    subjects: [],
    grades: [],
};

const modifyCurriculumCourses = (coursesList = [], gradeList = [], subjectList = []) => {
    const modifiedCourses = coursesList.map(course => ({
        ...course,
        grade_code: gradeList.find(gradeInfo => gradeInfo.id === course.grade_id)?.code,
        subject_code: subjectList.find(subjectInfo => subjectInfo.id === course.subject_id)?.code,
    }))
    return modifiedCourses
};

const modifyCurriculums = (curriculum) => {
    let modifiedCurriculumData = {
        ...curriculum,
        title: curriculum.title + " Standards",
    }
    // common core milestones
    let commonCoreMilestones = {
        ...curriculum,
        code: "milestone",
        title:"Common Core Milestone"
    }
    // texas, florida, uk and aus users milestones
    let otherMilestones = {
        ...curriculum,
        code: "milestone",
        title: "Milestones"
    }
    modifiedCurriculumData = [
        modifiedCurriculumData,
        commonCoreMilestones,
        otherMilestones
    ]
    return modifiedCurriculumData
};

const modifyCurriculumGrades = (GradesList) => {
    const modifiedCurriculumsGrades = GradesList.map(grades => ({
        ...grades,
        code: grades.code.toUpperCase(),
        // id: @ask not same 
        name: grades.title,
        slug: "grade-" + grades.code
    }))
    return modifiedCurriculumsGrades
};

export const curriculumSeedData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_CURRICULUM_SEED_DATA:
            return {
                ...state,
                ...action.payload,
                subjects: action.payload.subjects.reverse(),
                courses: modifyCurriculumCourses(action.payload.courses, action.payload.grades, action.payload.subjects),
                curriculums: modifyCurriculums(action.payload.curriculum),
                grades: modifyCurriculumGrades(action.payload.grades)
            };
        case ReducerConstants.MODIFY_COURSES_SEED_DATA:
            return {
                ...state,
                courses: [
                    ...state.courses,
                    ...modifyCurriculumCourses(action.payload, state.grades, state.subjects)
                ]
            };
        default:
            return state
    }
}