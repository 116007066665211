import { fontGraphie } from "../../../../src/teacherDashboard/styles/fonts";
import styled, { keyframes, css } from "styled-components";

interface Props {
    isOpen: boolean
    isFlutterUser: boolean
}

const slideUpAnimation = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDownAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const PreviewOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #090A4AB2 0%, #020533 100%);;
  z-index: 110;
`;

export const PreviewWrapper = styled.div<Pick<Props, 'isOpen' | 'isFlutterUser'>>`
    background-color: #141975;
    width: 100%;
    height: 100%;
    border-radius: 24px 24px 0px 0px;
    position: relative;
    top: ${props => props.isOpen ? (props.isFlutterUser ? '0' : '142px') : '100%'};
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    padding: 58px 10% 14%;
    overflow: auto;
    ${props => props.isOpen && css`
        animation: ${slideUpAnimation} 0.3s ease-in-out forwards;
    `
    }
    &.slide-down {
        animation: ${slideDownAnimation} 0.3s ease-out backwards;
    }
    &::-webkit-scrollbar {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`;

export const ButtonText = styled.div`
    display: none;
    ${fontGraphie(14, 20, 4)};
    color: #ffffff;
    padding: 8px 16px;
    background: #090A4A;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
`;

export const CloseButton = styled.div<Pick<Props, 'isOpen'>>`
    position: absolute;
    right: 32px;
    top: ${props => props.isOpen ? 'calc(12px)' : '-100%'};
    text-align: right;
    cursor: pointer;
    z-index: 1;
    svg {
        &:hover {
            background: #090A4A;
            border-radius: 40px;
        }
    }
    &:hover {
        ${ButtonText} {
            display: block;
        }
    }
`;

export const WSDetails = styled.div`
    display: flex;
`;

export const WSTextWrapper = styled.div`
    flex: 0 0 60%;
`;

export const WSImageWrapper = styled.div`
    flex: 0 0 40%;
    text-align: right;
`;

export const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* box-shadow: inset 0 0 0 7px #090a4a; */
    border-radius: 10px;
`;

export const WSImage = styled.div`
    width: 292px;
    height: 376px;
    border-radius: 10px;
    margin-left: auto;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
`;

export const WSTitle = styled.div`
    ${fontGraphie(32, 42, 7)};
    color: #ffffff;
    margin-bottom: 6px;
`;

export const WSDescription = styled.div`
    ${fontGraphie(16, 24, 4)};
    color: #ffffff;
    margin-bottom: 40px;
`;

export const FreeWSCount = styled.div`
    width: fit-content;
    ${fontGraphie(14, 20, 7)};
    background: #69D5C1;
    border-radius: 8px;
    padding: 4px 8px;
    color: #141975;
    margin-bottom: 8px;
`;

export const CtaButton = styled.div`
    ${fontGraphie(18, 26, 6)};
    color: #090A4A;
    background: #EDEDF9;
    box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 32px;
    width: 232px;
    text-align: center;
    padding: 16px 38px;
    margin-bottom: 46px;
    cursor: pointer;
    &:hover {
        background: #BDBFF9;
    }
`;

export const TagsCategory = styled.div`
    ${fontGraphie(20, 26, 6)};
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 8px;
`;

export const TagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
`;

export const TagName = styled.div`
    ${fontGraphie(14, 16, 4)};
    color: #BDBFF9;
    padding: 10px 16px;
    border: 1px solid #7678DF;
    border-radius: 38px;
    margin: 0 8px 8px 0;
    text-transform: capitalize;
`;