import React, { MouseEventHandler } from "react";
import { Colors } from "baseStyles/enums/colors";
import Fonts from "baseStyles/enums/fonts";

// Enum representing different styles of text
export enum TextStylesTypes {
  HS1 = "HS1",
  HS2 = "HS2",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  NORMAL = "NORMAL",
  SMALL = "SMALL",
  CAPTION = "CAPTION",
  FOOTER = "FOOTER",
}

// Type representing the properties of a text component
export type TextPropsType = {
  width?: number;
  fontSize?: Fonts.Size;
  fontWeight?: Fonts.Weight;
  lineHeight?: number;
  letterSpacing?: number;
  color?: string;
  cta?: boolean;
  disabled?: boolean;
  center?: boolean;
  emphasis?: boolean;
  hoverColor?: string;
  lines?: number;
  opacity?: number;
  textAlign?: string;
  type?: TextStylesTypes;
  fullWidth?: boolean;
  notSelectable?: boolean;
  maxWidth?: number;
  whiteSpace?: string;
  textDecoration?: string;
  dataTestId?: string;
  inlineMaxWidth?: string;
  noUnderline?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
}

// Type representing an object that maps each text style to a React functional component
export type TextComponentsType = {
  [K in TextStylesTypes]: React.FC<TextPropsType>;
};

