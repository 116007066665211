import React from "react";
import { assetPath } from "../../utils/school-ui-helpers";
import NoWorksheetImage from "TEACHER_ASSETS/images/worksheet/no-worksheet.png";
import BackArrow from '../../assets/common/backArrowGrey.svg'
import LazyLoadImage from "../common/LazyLoadImage";

const WorksheetsNotFound = ({

}) => {
  return (
    <div className="">
      <div className="empty-saved-worksheets-body">
        <LazyLoadImage src={assetPath(NoWorksheetImage)} alt="No Worksheet" height="116" width="116" />
        <div className="main-text">ELA/ Reading is not available for Grade 3, 4, and 5</div>
        <p>Change grade to download ELA worksheets for Kindergarten, Grade 1, and Grade 2.</p>
      </div>
    </div>
  )
}

export default WorksheetsNotFound;
