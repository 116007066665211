// avo integrated events

export const AVO_EVENT_NAMES = {
  visitedDashboardTab: "visitedDashboardTab",
  viewedMfWalkthroughPopup: "viewedMfWalkthroughPopup",
  viewedMfTrackCustomPage: "viewedMfTrackCustomPage",
  viewedMfTrackAdaptivePage: "viewedMfTrackAdaptivePage",
  viewedMfStudentProgressPage: "viewedMfStudentProgressPage",
  viewedMfSettingsPopup: "viewedMfSettingsPopup",
  viewedMfModifyPracticePage: "viewedMfModifyPracticePage",
  viewedMfClassProgressPage: "viewedMfClassProgressPage",
  navClickedStudentReport: "navClickedStudentReport",
  navClickedMfTrackFluency: "navClickedMfTrackFluency",
  navClickedMfNewPractice: "navClickedMfNewPractice",
  navClickedClassReport: "navClickedClassReport",
  interactedMfWalkthroughPopup: "interactedMfWalkthroughPopup",
  interactedMfTrackAdaptivePage: "interactedMfTrackAdaptivePage",
  interactedMfStudentProgressPage: "interactedMfStudentProgressPage",
  interactedMfSettingsPopup: "interactedMfSettingsPopup",
  interactedMfModifyPracticePage: "interactedMfModifyPracticePage",
  interactedMfClassProgressPage: "interactedMfClassProgressPage",
  downloadedWorksheet: "downloadedWorksheet",
  clickedWorksheetTab: "clickedWorksheetTab",
  appliedWorksheetFilters: "appliedWorksheetFilters",
  clearedWorksheetFilters: "clearedWorksheetFilters",
  interactedMfCustomReportPage: "interactedMfCustomReportPage",
  interactedMfTrackCustomPage: "interactedMfTrackCustomPage",
  interactedMfStudentSelectionPopup: "interactedMfStudentSelectionPopup",
  interactedMfCustomModeSettingsPopup: "interactedMfCustomModeSettingsPopup",
  interactedMfNewPracticePage: "interactedMfNewPracticePage",
  interactedWorksheetListingPage: "interactedWorksheetListingPage",
  previewedWorksheet: "previewedWorksheet",
  savedWorksheet: "savedWorksheet",
  viewedDownloadHistoryWorksheetPage: "viewedDownloadHistoryWorksheetPage",
  viewedSavedWorksheetPage: "viewedSavedWorksheetPage",
  viewedWorksheetListingPage: "viewedWorksheetListingPage",
  visitedOnboardingWelcome: "visitedOnboardingWelcome",
  interactedTeacherOnboarding: "interactedTeacherOnboarding",
  interactedAssignmentSuccessfullyAssignedPage: "interactedAssignmentSuccessfullyAssignedPage",
  viewedMfSuccessfullyAssignedPage: "viewedMfSuccessfullyAssignedPage",
  interactedMfSuccessfullyAssignedPage: "interactedMfSuccessfullyAssignedPage",
  interactedWorksheetSearchResultsPage: "interactedWorksheetSearchResultsPage",
  interactedWorksheetSearchDropdown: "interactedWorksheetSearchDropdown",
  viewedWorksheetSearchResultsEndPage: "viewedWorksheetSearchResultsEndPage",
  viewedWorksheetSearchResultsPage: "viewedWorksheetSearchResultsPage",
  viewedWorksheetSearchDropdown: "viewedWorksheetSearchDropdown",
  interactedNudgebyTeacher: "interactedNudge",
  viewedNudgebyTeacher: "viewedNudge",
  interactedPrintReportPopup: "interactedPrintReportPopup",
  interactedStudentProgress: "interactedStudentProgress",
  viewedTeacherDashboard: "viewedTeacherDashboard",
  interactedTeacherDashboard: "interactedTeacherDashboard",
  viewedOnboardingStepCompletionAnimation: "viewedOnboardingStepCompletionAnimation",
  interactedLeftNavigationMenu: "interactedLeftNavigationMenu",
  interactedSplashcommunityDiscoverabilityPopUp: "interactedSplashcommunityDiscoverabilityPopUp",
  viewedSplashcommunityDiscoverabilityPopUp: "viewedSplashcommunityDiscoverabilityPopUp",
  interactedNewClassAdditionPage: "interactedNewClassAdditionPage",
  autoEnrolledForSpringboard: "autoEnrolledForSpringboard",
  viewedSpringboardNewIntro: "viewedSpringboardNewIntro",
  interactedSpringboardNewIntro: "interactedSpringboardNewIntro",
  viewedSpringboardTab: "viewedSpringboardTab",
  interactedSpringboardTab: "interactedSpringboardTab",
  visitedCertificateTabSpringboard: "visitedCertificateTabSpringboard",
  interactedCertificatesPageSpringboard: "interactedCertificatesPageSpringboard",
  viewedCertificate: "viewedCertificate",
  downloadedCertificate: "downloadedCertificate",
  viewedIntroducingCertificatesPopup: "viewedIntroducingCertificatesPopup",
  interactedIntroductoryCertificatesPopup: "interactedIntroductoryCertificatesPopup",
  viewedWeeklyHighlightsPopup: "viewedWeeklyHighlightsPopup",
  interactedWeeklyHighlightsPopup: "interactedWeeklyHighlightsPopup",
  interactedClassroomSettingsPage: "interactedClassroomSettingsPage",
  viewedAddParentEmailsPage: "viewedAddParentEmailsPage",
  interactedAddParentEmailsPage: "interactedAddParentEmailsPage",
  viewedShareDiscountBanner: "viewedShareDiscountBanner",
  interactedShareDiscountBanner: "interactedShareDiscountBanner",
  selectedPrintablesSearch: "selectedPrintablesSearch",
  viewedTiledDashboard: "viewedTiledDashboard",
  interactedPrintablesFilters: "interactedPrintablesFilters",
  // interactedTiledDashboard: "interactedTiledDashboard",
  startedPrintablesSearch: "startedPrintablesSearch",
  // not imported via avo
  emailOfferSent: "emailOfferSent",
  viewedSummerLearningOfferPage: "viewedSummerLearningOfferPage",
  interactedSummerLearningOfferPage: "interactedSummerLearningOfferPage",
  viewedSummerLearningPopUp: "viewedSummerLearningPopUp",
  interactedSummerLearningPopUp: "interactedSummerLearningPopUp",
  interactedLoginScreen: "interactedLoginScreen",
  // referral parent login events
  interactedLoginViaOtpByClassroomLinkedParent: "interactedLoginViaOtpByClassroomLinkedParent",
  interactedClassroomLinkedLoginScreen: "interactedClassroomLinkedLoginScreen",
  viewedClassroomLinkedLoginScreen: "viewedClassroomLinkedLoginScreen",
  viewedLoginViaOtpByClassroomLinkedParent: "viewedLoginViaOtpByClassroomLinkedParent",
  // co teaching events
  interactedProfileIcon: "interactedProfileIcon",
  viewedManageAllClassesPage: "viewedManageAllClassesPage",
  interactedManageAllClassesPage: "interactedManageAllClassesPage",
  viewedAddCoTeacherPopup: "viewedAddCoTeacherPopup",
  interactedAddCoTeacherPopup: "interactedAddCoTeacherPopup",
  viewedCoTeachingPopupByCoTeacher: "viewedCoTeachingPopupByCoTeacher",
  interactedCoTeachingPopupByCoTeacher: "interactedCoTeachingPopupByCoTeacher",
  viewedWelcomePageByNewlyCreatedCoTeacher: "viewedWelcomePageByNewlyCreatedCoTeacher",
  interactedWelcomePageByNewlyCreatedCoTeacher: "interactedWelcomePageByNewlyCreatedCoTeacher",
  viewedCoachmarkB2b: "viewedCoachmarkB2b",
  interactedCoachmarkB2b: "interactedCoachmarkB2b",
  interactedSummerBundleWidgetOnDashboard: "interactedSummerBundleWidgetOnDashboard",
  viewedSummerBundleWidgetOnDashboard: "viewedSummerBundleWidgetOnDashboard",
  // transfer class events
  viewedTransferPageB2b: "viewedTransferPageB2b",
  interactedTransferPageB2b: "interactedTransferPageB2b",
  // feedback events
  interactedHelpIconB2b: "interactedHelpIconB2b",
  interactedFeatureFeedbackPopUpB2b: "interactedFeatureFeedbackPopUpB2b",
  interactedSuggestImprovementsPopupB2b: "interactedSuggestImprovementsPopupB2b",
  clickedOnHelpIconB2b: "clickedOnHelpIconB2b",
  viewedFeatureFeedbackPopUpB2b: "viewedFeatureFeedbackPopUpB2b",
  viewedSuggestImprovementsPopupB2b: "viewedSuggestImprovementsPopupB2b",
  // worksheet preview events
  viewedWorksheetPreview: "viewedWorksheetPreview",
  interactedWorksheetPreview: "interactedWorksheetPreview",
  viewedSubscribePopup: "viewedSubscribePopup",
  interactedSubscribePopup: "interactedSubscribePopup",
  downloadedWorksheetB2c: "downloadedWorksheetB2c",
  // floating action button events
  interactedFabB2b: "interactedFabB2b",
  interactedStudentLoginInstructionsPopupB2b: "interactedStudentLoginInstructionsPopupB2b",
  // previous year classes events
  interactedPromotePopupB2b: "interactedPromotePopupB2b",
  interactedContinueSameClassPopupB2b: "interactedContinueSameClassPopupB2b",
  // auto assignment events 
  viewedCompleteTopicAssignmentPopupB2b: "viewedCompleteTopicAssignmentPopupB2b",
  interactedCompleteTopicAssignmentPopupB2b: "interactedCompleteTopicAssignmentPopupB2b",
  viewedDeleteTopicAssignmentPopupB2b: "viewedDeleteTopicAssignmentPopupB2b",
  interactedDeleteTopicAssignmentPopupB2b: "interactedDeleteTopicAssignmentPopupB2b",
  reachedDailyLimitBackend: "reachedDailyLimitBackend",

  // lesson plans
  viewedLessonPlansListingPageB2b: "viewedLessonPlansListingPageB2b",
  interactedLessonPlansListingPageB2b: "interactedLessonPlansListingPageB2b",
  viewedLessonPlanDetailPageB2b: "viewedLessonPlanDetailPageB2b",
  interactedLessonPlanDetailPageB2b: "interactedLessonPlanDetailPageB2b",
  // live class events 
  viewedReinforcementAtHomeB2b: "viewedReinforcementAtHomeB2b",
  interactedReinforcementAtHomeB2b: "interactedReinforcementAtHomeB2b",
  viewedLiveClassPageB2b: "viewedLiveClassPageB2b",
  interactedLiveClassPageB2b: "interactedLiveClassPageB2b",
  viewedMaxLiveClassLimitReachedPopupB2b: "viewedMaxLiveClassLimitReachedPopupB2b",
  viewedShareLiveClassPopupB2b: "viewedShareLiveClassPopupB2b",
  interactedShareLiveClassPopupB2b: "interactedShareLiveClassPopupB2b",
  reachedDailyLimitBackend: "reachedDailyLimitBackend",
  viewedGooglecleverClassImportScreenB2b: "viewedGooglecleverClassImportScreenB2b",
  interactedGooglecleverClassImportScreenB2b: "interactedGooglecleverClassImportScreenB2b",
  viewedGoogleAccountSelectionScreenB2b: "viewedGoogleAccountSelectionScreenB2b",
  errorMessageDisplayedB2b: "errorMessageDisplayedB2b",
  interactedClassSettings: "interactedClassroomSettingsPage",
  // springboard events
  enrolledClassB2b: "enrolledClassB2b",
  autoEnrolledForSpringboardB2b: "autoEnrolledForSpringboardB2b",
  disenrolledClassB2b: "disenrolledClassB2b",
  interactedSpringboardTabB2b: "interactedSpringboardTabB2b",
  interactedDownloadLetterB2b: "interactedDownloadLetterB2b",
  viewedShareAssignmentPopupB2b: "viewedShareAssignmentPopupB2b",
  interactedShareAssignmentPopupB2b: "interactedShareAssignmentPopupB2b",
  playableClicked: "playableClicked",
  // enhamced assignment events
  viewedSkillSelectionScreenB2b: "viewedSkillSelectionScreenB2b",
  interactedSkillSelectionScreenB2b: "interactedSkillSelectionScreenB2b",
  viewedSkillPreviewScreenB2b: "viewedSkillPreviewScreenB2b",
  interactedSkillPreviewScreenB2b: "interactedSkillPreviewScreenB2b",
  viewedStudentSelectionScreenForSkillsB2b: "viewedStudentSelectionScreenForSkillsB2b",
  interactedStudentSelectionScreenForSkillsB2b: "interactedStudentSelectionScreenForSkillsB2b",
  viewedAssignmentSuccessfullyAssignedPageAvo: "viewedAssignmentSuccessfullyAssignedPage",
  // event analysis and fixes
  interactedSchoolSelectionScreenB2b: "interactedSchoolSelectionScreenB2b",
  visitedTeacherWelcome: "visitedTeacherWelcome",
  googleClassroomcleverClassImportUnsuccessfulB2b: "googleClassroomcleverClassImportUnsuccessfulB2b",
  interactedAddStudentsPageB2b: "interactedAddStudentsPageB2b",
  visitedTeacherOnboardingQuestionB2b: "visitedTeacherOnboardingQuestionB2b",
  visitedOnboardingEndLoadthroughB2b: "visitedOnboardingEndLoadthroughB2b",
  selectedClassesForImport: "selectedClassesForImport",
  clickedStudentsCreated: "clickedStudentsCreated",

  // onboarding enhancements events
  interactedAddStudentsparentEmailPageB2b: "interactedAddStudentsparentEmailPageB2b",
  visitedAddStudentsparentEmailPageB2b: "visitedAddStudentsparentEmailPageB2b",
  visitedNewClass: "visitedNewClass",
  // clickedStudentsCreated: "clickedStudentsCreated",
  // classCreated: "classCreated", -> BE Event
  // selectedClassesForImport: "selectedClassesForImport",
  viewedPostClassSyncPopupB2b: "viewedPostClassSyncPopupB2b",
  viewedSyncConfirmationPopupB2b: "viewedSyncConfirmationPopupB2b",
  interactedReimportedClassesSelectionScreenB2b: "interactedReimportedClassesSelectionScreenB2b",
  interactedSyncConfirmationPopupB2b: "interactedSyncConfirmationPopupB2b",
  interactedPostClassSyncPopupB2b: "interactedPostClassSyncPopupB2b",
  interactedAddReimportedClassNamePageB2b: "interactedAddReimportedClassNamePageB2b",
  // interactedGooglecleverClassImportScreenB2b: "interactedGooglecleverClassImportScreenB2b",
  interactedRosterPage: "interactedRosterPage",
  // syncGoogleClassroom: "syncGoogleClassroom", -> BE Event
  // pending from product
  // syncCleverClasses: "syncCleverClasses", -> BE Event
  viewedAddReimportedClassNamePageB2b: "viewedAddReimportedClassNamePageB2b",
  viewedReimportedClassesSelectionScreenB2b: "viewedReimportedClassesSelectionScreenB2b",
  // interactedManageAllClassesPage: "interactedManageAllClassesPage"
  // interactedNewClassAdditionPage: "interactedNewClassAdditionPage",
  // viewedGooglecleverClassImportScreenB2b: "viewedGooglecleverClassImportScreenB2b",


  // Returning teacher events
  viewedPromotecontinueClassPopupB2b: "viewedPromotecontinueClassPopupB2b",
  interactedPromotecontinueClassPopupB2b: "interactedPromotecontinueClassPopupB2b",
  viewedClassContinuedSuccessfullyPopupB2b: "viewedClassContinuedSuccessfullyPopupB2b",
  viewedTransferPopupB2b: "viewedTransferPopupB2b",
  interactedTransferPopupB2b: "interactedTransferPopupB2b",
  viewedTransferRequestSentPopupB2b: "viewedTransferRequestSentPopupB2b",
  viewedDeleteClassPopupB2b: "viewedDeleteClassPopupB2b",
  interactedDeleteClassPopupB2b: "interactedDeleteClassPopupB2b",
  // IA Revamp Events
  // product-selection-b2b branch 
  clickedProductSelectionB2b: "clickedProductSelectionB2b",
  viewedProductSelectionB2b: "viewedProductSelectionB2b",
  interactedProductSelectionB2b: 'interactedProductSelectionB2b',

  // curriculum-tab-b2b branch
  screenAssessmentRendered: "screenAssessmentRendered",
  screenCurriculumRendered: "screenCurriculumRendered", // click_source not present in avo not integrated
  visitedCurriculumTab: "visitedCurriculumTab", // not getting fired on page render as in control
  clickedClassAssessments: "clickedClassAssessments",
  clickedAssessmentReports: "clickedAssessmentReports",
  // to add new property
  // Interacted MF New Practice Page -> done
  // Viewed Worksheet Listing Page  -> done
  // Nav Clicked MF New Practice -> done
  // Viewed Live Class Page [B2B] -> done
  // Clicked Class Assessments -> done

  // curriculum-reports
  // Viewed MF Track Adaptive Page -> property added
  screenAssignmentRendered: "screenAssignmentRendered", // click_source not present in avo not integrated
  visitedGridReport: "visitedGridReport", // 'Student Count' not present in avo not integrated
  visitedDailyActivityReportTab: "visitedDailyActivityReportTab",
  visitedAssignmentTab: "visitedAssignmentTab",
  visitedReportsTab: "visitedReportsTab",
  // Nav Clicked Class Report -> done
  // Nav Clicked MF Track Fluency -> done
  clickedAssessmentReports: "clickedAssessmentReports",  // -> done
  // header-b2b
  viewedStudentLoginInstructionsPopup: "viewedStudentLoginInstructionsPopup",
  interactedHomeAccessPage: "interactedHomeAccessPage", // different propertiesnot present in avo -> not implemented
  visitedHomeAccess: "visitedHomeAccess",

  // bottom-nav-b2b
  clickedOnProfileIcon: "clickedOnProfileIcon",
  // Viewed Manage All Classes Page -> done
  visitedStudentCenter: "visitedStudentCenter",
  interactedReferSplashlearnPageB2b: "interactedReferSplashlearnPageB2b", // -> will be picked in next phase
  viewedManageRosterPageB2b: "viewedManageRosterPageB2b",
  viewedEditProfilePageB2b: "viewedEditProfilePageB2b",
  interactedEditProfilePageB2b: "interactedEditProfilePageB2b", // will be done later
  viewedClassroomSettings: "viewedClassroomSettings",
  viewedCommunityB2b: "viewedCommunityB2b",
  clickedClassroomSettings: "clickedClassroomSettings", // not integrated as source is missing
  viewedReferSplashlearnPageB2b: "viewedReferSplashlearnPageB2b",
  clickedManageRoster: "clickedManageRoster",

  // curriculum-booklibrary-main-page-events + ia-revamp-curriculumbooklibrary-events
  interactedTeacherResourcesTabB2b: "interactedTeacherResourcesTabB2b",
  viewedCurriculumTabB2b: "viewedCurriculumTabB2b",
  viewedAssessmentsReportPageB2b: "viewedAssessmentsReportPageB2b",
  // Viewed Book Library Assignment Report -> done
  viewedTeacherResourcesTabB2b: "viewedTeacherResourcesTabB2b",
  viewedCurriculumB2b: "viewedCurriculumB2b",
  viewedBookLibraryTabB2b: "viewedBookLibraryTabB2b",
  interactedCurriculumTab: "interactedCurriculumTab",
  viewedCurriculumAssignmentReportPageB2b: "viewedCurriculumAssignmentReportPageB2b",
  viewedMathFactsPageB2b: "viewedMathFactsPageB2b",
  viewedClassReportPageB2b: "viewedClassReportPageB2b",
  // Viewed Book Library
  // Interacted Book Library
  interactedBookLibraryReportTabB2b: "interactedBookLibraryReportTabB2b",
  interactedCurriculumB2b: "interactedCurriculumB2b",
  interactedBookLibraryTabB2b: "interactedBookLibraryTabB2b",
  viewedStudentReportPageB2b: "viewedStudentReportPageB2b",
  viewedBookLibraryReportTabB2b: "viewedBookLibraryReportTabB2b",
  viewedCurriculumReportTabB2b: "viewedCurriculumReportTabB2b",
  viewedMathFactsReportPageB2b: "viewedMathFactsReportPageB2b",
  viewedAssessmentsPageB2b: "viewedAssessmentsPageB2b",
  interactedCurriculumReportTabB2b: "interactedCurriculumReportTabB2b",

  // returning teacher class transfer events
  viewedWelcomePageByTransferredTeacherb2b: "viewedWelcomePageByTransferredTeacherb2b",
  interactedClassTransferFailedByTransferringTeacherB2b: "interactedClassTransferFailedByTransferringTeacherB2b",
  interactedClassTransferFailedByTransferredTeacherB2b: "interactedClassTransferFailedByTransferredTeacherB2b",
  viewedClassTransferFailedPopupByTransferredTeacherB2b: "viewedClassTransferFailedPopupByTransferredTeacherB2b",
  viewedClassTransferFailedPopupByTransferringTeacherB2b: "viewedClassTransferFailedPopupByTransferringTeacherB2b",
  viewedTransferSuccessfulPopupByTransferringTeacherB2b: "viewedTransferSuccessfulPopupByTransferringTeacherB2b",
  interactedWelcomePageByTransferredTeacherb2b: "interactedWelcomePageByTransferredTeacherb2b",
  viewedClassTransferPopupByTransferredTeacherb2b: "viewedClassTransferPopupByTransferredTeacherb2b",
  interactedClassTransferPopupByTransferredTeacherB2b: "interactedClassTransferPopupByTransferredTeacherB2b",
  interactedTransferSuccessfulPopupByTransferringTeacherB2b: "interactedTransferSuccessfulPopupByTransferringTeacherB2b",

  interactedClassroomReport: "interactedClassroomReport",
  interactedStudentReport: "interactedStudentReport",

  // obw events
  getStartedWidgetVisibility: "getStartedWidgetVisibility",
  interactedGetStartedWidget: "interactedGetStartedWidget",

  // assignment expiry 
  selectedAssignmentDueDateB2b: "selectedAssignmentDueDateB2b",
  viewedDatePickerCalendarB2b: "viewedDatePickerCalendarB2b",

  //PTL SignUp Improvements
  viewedPtlStudentLinkedSuccessfullyPopup: "viewedPtlStudentLinkedSuccessfullyPopup",
  interactedAddreplaceStudentProfileScreen: "interactedAddreplaceStudentProfileScreen",
  viewedAddreplaceStudentProfileScreen: "viewedAddreplaceStudentProfileScreen",
  ptlLinkingAttempted: "ptlLinkingAttempted",
  viewedPtlParentSignupScreen: "viewedPtlParentSignupScreen",
  interactedPtlParentSignupScreen: "interactedPtlParentSignupScreen",
  viewedErrorOnPtlParentSignupScreen: "viewedErrorOnPtlParentSignupScreen",

  // passwordless login
  viewedStudentLoginPasswordSettingsPageB2b: "viewedStudentLoginPasswordSettingsPageB2b",
  savedStudentLoginPasswordSettingB2b: "savedStudentLoginPasswordSettingB2b",
  interactedStudentLoginPasswordSettingsPageB2b: "interactedStudentLoginPasswordSettingsPageB2b",

  // student center events
  viewedClassSelectionViaStudentExperience: "viewedClassSelectionViaStudentExperience",
  interactedStudentSelectionViaStudentExperience: "interactedStudentSelectionViaStudentExperience",
  interactedClassSelectionViaStudentExperience: "interactedClassSelectionViaStudentExperience",
  viewedTiledDashboardViaStudentExpereince: "viewedTiledDashboardViaStudentExpereince",
  viewedStudentSelectionViaStudentExperience: "viewedStudentSelectionViaStudentExperience"
}