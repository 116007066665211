import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = []

export const teacherSurveySeedData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_SURVEY_SEED_DATA:
            return  action.payload || []
        default:
            return state
    }
}