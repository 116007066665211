import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {};

export const contentWiseLoSeedData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_CONTENT_WISE_LO_SEED_DATA:
            return action.payload || {};
        default:
            return state
    }
}