"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSuiStyles = void 0;
var _AppMediaProvider = require("baseStyles/AppMediaProvider");
var _ThemeProvider = require("baseStyles/ThemeProvider");
var _device = _interopRequireDefault(require("baseStyles/enums/device"));
var _react = require("react");
var _data = _interopRequireDefault(require("utility/data"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var mediaStyleOrder = [_device["default"].types.Desktop, _device["default"].types.Tablet, _device["default"].types.Mobile, _device["default"].types.Mobile];
var useSuiStyles = function useSuiStyles(styles) {
  // console.log("useSuiStyles Init");

  return function (props) {
    var _useAppMediaProvider = (0, _AppMediaProvider.useAppMediaProvider)(),
      currentDevice = _useAppMediaProvider.currentDevice;
    var _useSuiTheme = (0, _ThemeProvider.useSuiTheme)(),
      theme = _useSuiTheme.theme,
      themeName = _useSuiTheme.themeName;

    // console.log("useSuiStyles", props, currentDevice, theme, themeName);

    var desktopStyles = (0, _react.useMemo)(function () {
      var _styles$desktop;
      return (_styles$desktop = styles.desktop) === null || _styles$desktop === void 0 ? void 0 : _styles$desktop.call(styles, _objectSpread(_objectSpread({}, props), {}, {
        theme: theme,
        themeName: themeName
      }));
    }, [props, theme, currentDevice]);
    var currentDeviceStyles = (0, _react.useMemo)(function () {
      var _styles$currentDevice;
      return (_styles$currentDevice = styles[currentDevice]) === null || _styles$currentDevice === void 0 ? void 0 : _styles$currentDevice.call(styles, _objectSpread(_objectSpread({}, props), {}, {
        theme: theme,
        themeName: themeName
      }));
    }, [props, theme, currentDevice]);
    var finalStyles = (0, _react.useMemo)(function () {
      var currentOrder = mediaStyleOrder.findIndex(function (item) {
        return item === currentDevice;
      });
      var data = desktopStyles;
      for (var i = 1; i <= currentOrder; i++) {
        var _styles$mediaStyleOrd;
        data = _data["default"].deepMerge(data, (_styles$mediaStyleOrd = styles[mediaStyleOrder[i]]) === null || _styles$mediaStyleOrd === void 0 ? void 0 : _styles$mediaStyleOrd.call(styles, _objectSpread(_objectSpread({}, props), {}, {
          theme: theme,
          themeName: themeName
        })));
      }
      return data;
    }, [desktopStyles, currentDeviceStyles]);
    return finalStyles;
  };
};
exports.useSuiStyles = useSuiStyles;