interface IGradeTitleMap {
    [key: string]: string;
  };

export const GRADE_TITLE_MAP: IGradeTitleMap = {
    "Pre-K": "Pre-K",
    "K": "Kindergarten",
    "1": "Grade 1",
    "2": "Grade 2",
    "3": "Grade 3",
    "4": "Grade 4",
    "5": "Grade 5"
  }