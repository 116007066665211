import axios from "axios";

const BASE_API_GATEWAY_URL = APP_URLS?.base_api_gateway_url;
const CMS_API_GATEWAY_URL = APP_URLS?.cms_base_api_gateway_url;
const CMS_PATH_PREFIX = "cms-service/public/api/v1";
const SCHOOL_BE_PATH_PREFIX = "/school-service/api/v1";

const COMMON_HEADERS = {
    "X-CSRF-Token": document
    .querySelector("meta[name=csrf-token]")
    ?.getAttribute("content"),
    "X-Requested-With": "XMLHttpRequest",
};

export interface IFetchAllSearchNodesParams {
    subjectCode: string;
    gradeCode: string;
    dontShowAjaxSpinner?: boolean;
}

export interface IFetchNodesByIdParams {
    searchNodeIds: Array<number>;
    pageNumber: number;
    pageSize: number;
    dontShowAjaxSpinner?: boolean;
}

export interface IFetchAllSavedNodes {
    userId : string;
    pageNumber: number;
    pageSize: number;
}

export interface IGetAllWorksheets {
    nodeId : string;
    pageNumber?: number;
    pageSize?: number;
}

const fetchAllSearchNodes = async ({
    subjectCode,
    gradeCode,
}: IFetchAllSearchNodesParams): Promise<any> => {

    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-nodes`
    return axios.post(url, {
        grade_code: gradeCode,
        subject: subjectCode,
        content_type: "Worksheets"
    }, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true,
    })
    .then((res) => res.data);
};

const fetchSavedWorksheets = async ({
    userId,
    pageNumber,
    pageSize
}: IFetchAllSavedNodes): Promise<any> => {

    const url =  `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-collection-entities`;
    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        params: {
            pageNumber,
            pageSize
        },
        withCredentials: true
    })
    .then((res) => res.data);
}

const fetchNodesByIds = async ({
    searchNodeIds,
    pageNumber,
    pageSize,
}: IFetchNodesByIdParams): Promise<void> => {

    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-contents/by-search-nodes`
    return axios.post(url, {search_node_ids: searchNodeIds, page_size: pageSize, page_number: pageNumber}, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    }).then((res) => res.data);
}

const getSearchResults = async ({
    params,
    callback = () => {},
    errorCallback = () => {}
}: any): Promise<void> => {
    
    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-contents/search`;
    
    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        params: params,
        withCredentials: true
    })
    .then((res) => callback(res.data))
    .catch((err) => errorCallback(err));
};

const postUserSearches = async ({
    userId,
    params,
    callback = () => {},
    errorCallback = () => {}
}: any): Promise<void> => {
    
    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-search-entities`;

    return axios.post(url, params, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    })
    .then((res) => callback(res.data))
    .catch((err) => errorCallback(err));
};

const getRecentUserSearches = async ({
    userId,
    params,
    callback = () => {},
    errorCallback = () => {}
}: any): Promise<void> => {

    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-search-entities`;
    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        params: params,
        withCredentials: true
    })
    .then((res) => callback(res.data))
    .catch((err) => errorCallback(err));
};

const getWorksheetsByIds = async ({
    params,
    callback = () => {},
    errorCallback = () => {}
}: any): Promise<void> => {
    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-contents/worksheet-search`;

    return axios.post(url, params, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    })
    .then((res) => callback(res.data))
    .catch((err) => errorCallback(err));
};

const getTrendingWorksheets = async ({
    params,
    callback = () => {},
    errorCallback = () => {}
}: any): Promise<any> => {
    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-contents/trending-searches`;

    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        params: params,
        withCredentials: true
    })
    .then((res) => callback(res.data))
    .catch((err) => errorCallback(err));
}

const postSaveUserDownload = async ({
    userId,
    params,
    callback = () => {}
}: any): Promise<void> => {

    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-downloads`
    return axios.post(url, params, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    }).then((res) => callback(res?.data));
}

const postUserSavedCollection = async ({
    userId,
    params,
    callback = () => {}
}: any): Promise<void> => {

    const url =`${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-collection-entities`;
    return axios.post(url, params, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    }).then((res) => res?.data);
}
const deleteUserCollection = async ({
    userId,
    id,
    callback = () => {}
}: any): Promise<void> => {
    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-collection-entities/${id}`;
    return axios.delete(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    }).then((res) => res?.data);
}

const getAllWorksheets = async ({
    nodeId,
}: IGetAllWorksheets): Promise<any> => {
    let id = parseInt(nodeId);
    return await fetchNodesByIds({
        searchNodeIds: [id],
        pageNumber: 0,
        pageSize: 1000
    });
}

const getWorksheetsByEntityIds = async ({params}: any): Promise<any> => {
    const url = `${BASE_API_GATEWAY_URL}/${CMS_PATH_PREFIX}/search-contents`;

    return axios.post(url, params, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    }).then((res) => res.data);
}

const getUserDownloadList = async ({
    userId,
    page_number,
    page_size,
    created_date_sort,
    created_date_gte
}: any): Promise<any> => {
    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-downloads`;
    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        params: {
            page_number,
            page_size,
            created_date_sort,
            created_date_gte
        },
        withCredentials: true
    })
    .then((res) => res.data);
};

const getUserDownloadsCount = async ({userId}: any): Promise<any> => {
    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-downloads/count`;

    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    })
    .then((res) => res.data);
};

const getUserSavedCount = async ({userId}: any): Promise<any> => {
    const url = `${BASE_API_GATEWAY_URL}${SCHOOL_BE_PATH_PREFIX}/users/${userId}/user-collection-entities/count`;
    return axios.get(url, {
        headers: {
            ...COMMON_HEADERS,
        },
        withCredentials: true
    })
    .then((res) => res.data);
};

export {
    getSearchResults,
    postUserSearches,
    getRecentUserSearches,
    getWorksheetsByIds,
    getTrendingWorksheets,
    postSaveUserDownload,
    postUserSavedCollection,
    deleteUserCollection,
    fetchAllSearchNodes,
    fetchNodesByIds,
    fetchSavedWorksheets,
    getAllWorksheets,
    getWorksheetsByEntityIds,
    getUserDownloadList,
    getUserDownloadsCount,
    getUserSavedCount
}