import { AppendQueryParams, GetCookie } from '../../teacherDashboard/teacher/config/common';
import KlassCodeLoginIcon from './../assets/student-klasscode-login.png';
import QRCodeLoginIcon from './../assets/student-qr-login.png';

export interface IRememberMeKlassDetail {
  klass_code: string,
  klass_name: string,
  last_login: string,
  klass_id: string
}


export const LOGIN_TYPE = [
  {
    label: "Class code",
    path: "#student-klasscode?fromPage=Login method selection screen",
    icon: KlassCodeLoginIcon
  },
  {
    label: "QR code",
    path: "#student-qrcode?fromPage=Login method selection screen",
    icon: QRCodeLoginIcon
  }
]

export const clever_login_url = () => {
  // @ts-ignore
  const webBaseURL = window?.CLEVER_RIDIRECT_URL?.replace(/\&amp;/gi,'&');
  const params = {
    timeZone: GetCookie("timezone"),
    landing_page_url_key: sessionStorage.getItem("landing_page_url") || 'NA'
  }
  return AppendQueryParams(webBaseURL, params)
}