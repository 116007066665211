export enum ThemesName {
    default = "default",
    blue = "blue",
    grey = "grey",
}

const colorWithOpacity = (colorHex: string, opacaity: number) => {
    return `${colorHex}${parseInt(String(opacaity * 100), 16)}`
}

const DefaultTheme = {
    primaryBackground: "#FFFFFF",
    secondaryBackground: "#F7F7FA",
    popupSecondary: "#F7F7FA",
    backgroundShade: "#EDEDF9",
    backgroundActive: colorWithOpacity("#BDBFF9", 0.6),
    backgroundHover: colorWithOpacity("#9D9FFC", 0.1),
    backgroundHover2: colorWithOpacity("#7678DF", 0.2),

    backButtonBackground: "#FFFFFF",

    dropdownBackground: "#FFFFFF",

    border: "#CACAD8",
    borderActive: "#4F52C3",

    colorPrimary: "#1A1A1A",
    colorSecondary: "#484848",
    colorTertiary: "#767676",

    contentSecondary: "#1A1A1A",

    colorLink: "#4F52C3",
    colorLinkHover: "#34389F",
    colorLinkDisabled: colorWithOpacity("#1A1A1A", 0.5),
    
    svgFillPrimary: "#141975",

    checkboxFillColor: "#4F52C3",
    checkboxEmptyColor: "#8F8F9B",

    ctaSecondary: "#BDBFF9",

    radioButtonDefaultBorderColor: "#8F8F9B",
    radioButtonBackgroundColor: "#E5E5FD",
    radioButtonNewBorderColor: "#4f52c3",
    radioButtonBoxShadowColor: "rgba(156, 158, 252, 0.1)",
    radioButtonSelectedBorderColor: "#797CDB",
    radioButtonInnerBackgroundColor: "#5052c3",

    secondaryFillButton: "#BDBFF9",
    secondaryFillButtonHover: "#c9cbff",
    secondaryFillTextColor: "#141975",

    primaryFillButton: "#4F52C3",
    primaryFillButtonHover: "#34389F",
    primaryFillTextColor: "#FFFFFF",
}

const BlueTheme = {
    primaryBackground: "#181B64",
    secondaryBackground: "#34389F",
    popupSecondary: "#262981",
    backgroundShade: "#090A4A",
    
    backgroundActive: colorWithOpacity("#4F52C3", 0.6),
    backgroundHover: colorWithOpacity("#7678DF", 0.1),
    backgroundHover2: "#181B64",

    backButtonBackground: "#4F52C3",

    dropdownBackground: "#262981",

    border: "#4F52C3",
    borderActive: "#4F52C3",

    colorPrimary: "#FFFFFF",
    colorSecondary: "#CACAD8",
    colorTertiary: "#A2A2AD",

    contentSecondary: "#BDBFF9",

    colorLink: "#FFFFFF",
    colorLinkHover: "#FFFFFF",
    colorLinkDisabled: "#D9D9D9",

    svgFillPrimary: "#FFFFFF",

    checkboxFillColor: "#9D9FFC",
    checkboxEmptyColor: "#9D9FFC",

    ctaSecondary: "#4F52C3",

    radioButtonDefaultBorderColor: "#8F8F9B",
    radioButtonBackgroundColor: "transparent",
    radioButtonNewBorderColor: "#BDBFF9",
    radioButtonBoxShadowColor: "rgba(156, 158, 252, 0.1)",
    radioButtonSelectedBorderColor: "#9D9FFC",
    radioButtonInnerBackgroundColor: "#FFFFFF",

    secondaryFillButton: "#4F52C3",
    secondaryFillButtonHover: "#34389F",
    secondaryFillTextColor: "#FFFFFF",

    primaryFillButton: "#BDBFF9",
    primaryFillButtonHover: "#c9cbff",
    primaryFillTextColor: "#141975",
}

const GreyTheme = {
    primaryBackground: "#D9D9D9",
    secondaryBackground: "#F7F7FA",
    popupSecondary: "#F7F7FA",
    backgroundShade: "#EDEDF9",
    backgroundActive: colorWithOpacity("#BDBFF9", 0.6),
    backgroundHover: colorWithOpacity("#9D9FFC", 0.1),
    backgroundHover2: colorWithOpacity("#7678DF", 0.2),

    backButtonBackground: "#FFFFFF",

    dropdownBackground: "#FFFFFF",

    border: "#CACAD8",
    borderActive: "#4F52C3",

    colorPrimary: "#1A1A1A",
    colorSecondary: "#484848",
    colorTertiary: "#767676",

    contentSecondary: "#1A1A1A",

    colorLink: "#4F52C3",
    colorLinkHover: "#34389F",
    colorLinkDisabled: colorWithOpacity("#1A1A1A", 0.5),
    
    svgFillPrimary: "#141975",

    checkboxFillColor: "#4F52C3",
    checkboxEmptyColor: "#8F8F9B",

    ctaSecondary: "#BDBFF9",

    radioButtonDefaultBorderColor: "#8F8F9B",
    radioButtonBackgroundColor: "#E5E5FD",
    radioButtonNewBorderColor: "#4f52c3",
    radioButtonBoxShadowColor: "rgba(156, 158, 252, 0.1)",
    radioButtonSelectedBorderColor: "#797CDB",
    radioButtonInnerBackgroundColor: "#5052c3",

    secondaryFillButton: "#BDBFF9",
    secondaryFillButtonHover: "#c9cbff",
    secondaryFillTextColor: "#141975",

    primaryFillButton: "#4F52C3",
    primaryFillButtonHover: "#34389F",
    primaryFillTextColor: "#FFFFFF",
}


export const Themes = {
    [ThemesName.default]: DefaultTheme,
    [ThemesName.blue]: BlueTheme,
    [ThemesName.grey]: GreyTheme,
}


export type ITheme = typeof DefaultTheme;