import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    toggleYIRPopupValue: false
};

export const commonPopupState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.TOGGLE_YIR_POPUP:
            return {
                ...state,
                toggleYIRPopupValue: action.payload
            };
        default:
            return state
    }
}