import { AVO_EVENT_NAMES } from "avo/eventNames";

export const EventNames = {
  shareYIR: 'Share story via',
  shareYIRScreenShot: 'YIR Screenshot Captured',
  shareYIRLinkFetched: 'YIR Image CDN Link Fetched',
  allStoriesShownYIR: 'Completed Year in Review Story',
  viewedYIR: 'Viewed Year In Review Share Screen',
  visitedTeacherDashboard: 'Visited Teacher Dashboard',
  interactedClassSettings: 'Interacted Classroom Settings Page',
  goToSpringboard: 'Clicked Springboard Tab',
  enrollSpringboardClass: 'Enrolled class',
  disenrollSpringboardClass: 'Disenrolled class',
  referredEducator: 'Referred Educator',
  interactedAdminInviteForm : 'Interacted Admin invite form',
  clickedCreateNewClass: 'Clicked Create New Class',
  clickedManageClassLinks: 'Clicked Manage Class Links',
  syncGoogleClassroom: 'Sync Google Classroom',
  interactedProfilePane: 'Interacted Profile Pane',
  clickedMoveOldClass: 'Clicked Move Old class',
  interactedAssignmentTab: 'Interacted Assignment Tab',
  interactedDetailedAssignmentReport: 'Interacted Detailed Assignment Report',
  copyShareableLink: 'Copy shareable link',
  interactedVerifyPopup: 'Interacted verify popup',
  interactedCookieDetailScreen: 'Interacted new cookie detail page',
  successfullyAssigned: 'Successfully Assigned',
  getStartedWidgetVisibility: 'Get started widget visibility',
  clickedClassestab: 'Clicked Classes Tab',
  interactedTeacherResourses: 'Interacted Teacher Resources',
  copyLoginInstruction: 'Copy Login Instructions',
  bannerClicked: "Clicked Banner CTA",
  bannerShown: "Banner Shown",
  visitedNewKlass: "Visited New Class",
  visitedAddStudent: "Visited Add Students",
  ClickedStudentsCreated: "Clicked Students Created",
  visitedAddStudentsDetails: "Visited Add Students Details",
  interactedAdditionStudent: "Interacted Addition Student",
  viewedAssignmentSuccessfullyAssignedPage: "Viewed Assignment Successfully Assigned Page",
  emailOfferSentWithoutAvo: "Email Offer Sent", // as this is not coming in avo pull

  ...AVO_EVENT_NAMES
}