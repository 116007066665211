import React from "react";
import { assetPath } from "../../utils/school-ui-helpers";
import NoBookmarkImage from "TEACHER_ASSETS/images/worksheet/no-bookmark.png";
import BackArrow from '../../assets/common/backArrowGrey.svg'
import LazyLoadImage from "../common/LazyLoadImage";

const EmptySavedWorksheets = () => {
  return (
    <div className="">
      <div className="empty-saved-worksheets-body">
        <LazyLoadImage src={assetPath(NoBookmarkImage)} alt="No bookmark" height="116" width="116" />
        <div className="main-text">No saved worksheets to show</div>
        <p>Save worksheets to easily find them here.</p>
      </div>
    </div>
  )
}

export default EmptySavedWorksheets;
