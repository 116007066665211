import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    message: "Welcome to Settings",
  };

export const settingsData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state
    }
}