import axios from "axios";

const BASE_API_GATEWAY_URL = APP_URLS?.base_api_gateway_url

const WEB_SERVICE_ENDPOINT = "web-service/apig/sessions";

const COMMON_HEADERS = {
    "X-CSRF-Token": document.querySelector('meta[name=csrf-token]')?.getAttribute("content"),
    "X-Requested-With": "XMLHttpRequest",
};


const ShowSpinner = (flag: boolean) => {
  const spinner =  document.getElementById('spinner') || document.getElementById('ajax-spinner-id');
  if(spinner) {
    spinner.style.display = flag ? 'block' : 'none';
  }
}


const INVALID_TOKEN_ERROR_MSG = "Something went wrong! Reload page or Open url in new tab.";
const klassCodeLogin = async (klass_code: string, via_saved_klass: boolean, token: string) => {
  ShowSpinner(true);
  let res: any = {};

  if(window?.turnstile_enabled_features?.klass_code_login && !token) {
    ShowSpinner(false);
    showFlashMessage(INVALID_TOKEN_ERROR_MSG, "error");
    throw new Error();
  }

  const searchParams = new URLSearchParams();
  searchParams.append('klass_code', klass_code);
  via_saved_klass && searchParams.append('via_saved_klass', `${via_saved_klass}`);
  token && searchParams.append('cf-turnstile-token', token);

  try {
    res = await axios.post(`/klass_code_login`, searchParams, {
      headers: {
          ...COMMON_HEADERS,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    if(e?.response?.status === 406) {
      showFlashMessage(INVALID_TOKEN_ERROR_MSG, "error");
      if(e?.response?.data?.code == "TOKEN_REQUIRED") {
        window.location.reload();
      }
    }
    ShowSpinner(false);
    throw e;
  }
  return res;
}


const qrCodeLogin = async (token: string) => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(`/${WEB_SERVICE_ENDPOINT}/qr/login`, {token}, {
      headers: COMMON_HEADERS,
        withCredentials: true,
    });
    ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
}


const remeberStudentLogin = async (student_id: string) => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(`/klass/${student_id}/student_login`, {via_remember_me_flow: true}, {
      headers: {
          ...COMMON_HEADERS,
      }
  }).then((res) => res.data);
  ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
}

const remeberKlassStudentLogin = async (student_id: string) => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(`/klass/${student_id}/student_login`, {via_logged_in_klass_remember_me_flow: true}, {
      headers: {
          ...COMMON_HEADERS,
      }
  }).then((res) => res.data);
  ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
}

const passwordlessKlassStudentLogin = async (student_id: string) => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.post(`/klass/${student_id}/student_login`, {}, {
      headers: {
          ...COMMON_HEADERS,
      }
  }).then((res) => res.data);
  ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
}

const ptlStudentValidation = async (student_id: string, referral_type: string, referrer_code: string) => {
  ShowSpinner(true);
  let res: any = {};
  try {
    res = await axios.get(`/ptl-setup/student-validation?student_id=${student_id}&referral_type=${referral_type}&referrer_code=${referrer_code}`, {
      headers: {
          ...COMMON_HEADERS,
      }
  }).then((res) => res.data);
  ShowSpinner(false);
  } catch (e) {
    console.error(e);
    ShowSpinner(false);
    throw e;
  }
  return res;
}

export const APIS = {
  remeberStudentLogin,
  klassCodeLogin,
  qrCodeLogin,
  remeberKlassStudentLogin,
  ptlStudentValidation,
  passwordlessKlassStudentLogin
};
