import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    adaptive: [],
    test: [],
    length: 0
};

export const assignedFactPracticesToKlass = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_ASSIGNED_FACT_PRACTICES_TO_KLASS:
            return action.payload || INITIAL_STATE
        default:
            return state
    }
}