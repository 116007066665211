const path = require('path');
const urlJoin = require('url-join');

const PROD = false
export function assetPath(relativePath){
  if(!relativePath)
    return ""
  let manifestPath = relativePath;
  let url;
  if(window.location.hostname === 'localhost'){
    url = urlJoin(path.join('', manifestPath));
  }
  else {
    url = manifestPath;
  }
  return url;
}
