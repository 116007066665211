import { isFlutterUser } from "apps/teacher/config/common";
import { AVO_EVENT_NAMES } from "avo/eventNames";
import { dispatchAvoEvent } from "avo/index";

// teacher-dashboard specific events
// downloadedWorksheet (Done)
// viewedWorksheetSearchResultsPage (Done)
// viewedWorksheetSearchDropdown (Done)
// interactedWorksheetSearchDropdown (Done)
// clickedWorksheetTab (Done)
// interactedWorksheetListingPage (Done)
// previewedWorksheet (Done)
// savedWorksheet (Done)
// viewedDownloadHistoryWorksheetPage (Done)
// viewedSavedWorksheetPage (Done)
// viewedWorksheetListingPage (Done)
// interactedWorksheetSearchResultsPage (Done)
// viewedWorksheetSearchResultsEndPage (Done)

// student-dashboard specific 
// interactedTiledDashboard (NOT AVAILABLE IN MAIN BRANCH)
// viewedTiledDashboard (Done)
// selectedPrintablesSearch (Done)
// interactedPrintablesFilters (Done)
// startedPrintablesSearch (Done)


interface ViewedTiledDashboardProps {
    dashboardVersion: string; 
    tabCode: string; 
    grade: string; 
    from: string; 
    worksheetCode: string
};

interface DownloadedWorksheetProps {
    worksheetName: string;
    worksheetGrade: string;
    worksheetCategory: string;
    worksheetSubcategory: string;
    from: string;
}

interface SelectedPrintablesSearchProps {
    searchTerm: string;
    searchResultSelected: string;
    grade: string;
}

interface InteractedPrintablesFiltersProps {
    clickedOn: string;
    grade: string;
    newTopicSelected?: string;
    newGradeSelected?: string;
}

interface StartedPrintablesSearchProps {
    searchTerm: string;
    countOfSearchResults: string;
    grade: string;
}

interface AppliedWorksheetFiltersProps {
    worksheetFilterValue: string;
    worksheetGrade: string;
}

declare let window: any;

const dispatchAvoEventWrapper = (params: any) => {
    if(isFlutterUser()){
        const avoInitProps = {
            klassGrade: window.parent?.parent?.KLASS_GRADE?.code,
            currentGrade: window.parent?.parent?.KLASS_GRADE?.code,
            subjectCode: window.parent?.parent?.KLASS_DETAIL?.id,
            klass: window.parent?.parent?.KLASS_DETAIL,
            teacherType: "Primary", 
        };
        dispatchAvoEvent(params, avoInitProps);
    } else {
        dispatchAvoEvent(params);
    }
}

const clickedWorksheetTab = ({worksheetGrade}: {worksheetGrade: string}): void => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.clickedWorksheetTab, props: {worksheetGrade}});
}

const viewedTiledDashboard = (props: ViewedTiledDashboardProps): void => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedTiledDashboard, props});
}

const downloadedWorksheet = (props: DownloadedWorksheetProps): void => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.downloadedWorksheet, props});
};

const interactedWorksheetSearchDropdown = (props: {clickedOn: string}): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.interactedWorksheetSearchDropdown, props});
};

const viewedWorksheetSearchDropdown = (): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.viewedWorksheetSearchDropdown, props: {}});
};

const viewedWorksheetSearchResultsPage = (): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.viewedWorksheetSearchResultsPage, props: {}});
};

const selectedPrintablesSearch = (props: SelectedPrintablesSearchProps): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.selectedPrintablesSearch, props});
}

const interactedPrintablesFilters = (props: InteractedPrintablesFiltersProps): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.interactedPrintablesFilters, props});
}

const startedPrintablesSearch = (props: StartedPrintablesSearchProps): void => {
    dispatchAvoEventWrapper({ eventName: AVO_EVENT_NAMES.startedPrintablesSearch, props});
}

const appliedWorksheetFilters = (props: AppliedWorksheetFiltersProps) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.appliedWorksheetFilters, props});
}

const clearedWorksheetFilters = (props: {
    worksheetGrade: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.clearedWorksheetFilters, props});
}

const interactedWorksheetListingPage = (props: {
    clickedOn: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.interactedWorksheetListingPage, props});
}

const previewedWorksheet = (props: {
    worksheetName: string;
    worksheetGrade: string;
    worksheetCategory: string;
    worksheetSubcategory: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.previewedWorksheet, props});
}

const savedWorksheet = (props: {
    worksheetGrade: string;
    worksheetCategory: string;
    worksheetSubcategory: string;
    from: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.savedWorksheet, props});
}

const viewedDownloadHistoryWorksheetPage = (props: {
    worksheetGrade: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedDownloadHistoryWorksheetPage, props});
}

const viewedSavedWorksheetPage = (props: {
    worksheetGrade: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedSavedWorksheetPage, props});
}

const viewedWorksheetListingPage = (props: {
    worksheetGrade: string;
    freeWorksheetsLeftCount: string;
    fromPage: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedWorksheetListingPage, props});
}

const interactedWorksheetSearchResultsPage = (props: {
    clickedOn: string;
    maxWorksheetViewed: string;
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.interactedWorksheetSearchResultsPage, props})
}

const viewedWorksheetSearchResultsEndPage = () => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedWorksheetSearchResultsEndPage, props: {}});
}

const viewedWorksheetPreview = (props: {
    from: string,
    freeWorksheetsLeftCount: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedWorksheetPreview, props});
}

const interactedWorksheetPreview = (props: {
    clickedOn: string,
    freeWorksheetsLeftCount: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.interactedWorksheetPreview, props});
}

const viewedSubscribePopup = (props: {
    from: string
    freeWorksheetsLeftCount: string,
    contentType: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.viewedSubscribePopup, props});
}

const interactedSubscribePopup = (props: {
    clickedOn: string,
    freeWorksheetsLeftCount: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.interactedSubscribePopup, props});
}

const downloadedWorksheetB2c = (props: {
    worksheetName: string,
    worksheetCode: string
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.downloadedWorksheetB2c, props});
}               
const reachedDailyLimitBackend = (props: {
    contentName: string,
    contentCode: string,
    contentType: string,
    from: string,
}) => {
    dispatchAvoEventWrapper({eventName: AVO_EVENT_NAMES.reachedDailyLimitBackend, props});
}
const PrintableWorksheetsEvents = {
    clickedWorksheetTab,
    viewedTiledDashboard,
    downloadedWorksheet,
    interactedWorksheetSearchDropdown,
    viewedWorksheetSearchDropdown,
    viewedWorksheetSearchResultsPage,
    selectedPrintablesSearch,
    interactedPrintablesFilters,
    startedPrintablesSearch,
    appliedWorksheetFilters,
    clearedWorksheetFilters,
    interactedWorksheetListingPage,
    previewedWorksheet,
    savedWorksheet,
    viewedSavedWorksheetPage,
    viewedDownloadHistoryWorksheetPage,
    viewedWorksheetListingPage,
    interactedWorksheetSearchResultsPage,
    viewedWorksheetSearchResultsEndPage,
    viewedWorksheetPreview,
    interactedWorksheetPreview,
    viewedSubscribePopup,
    interactedSubscribePopup,
    downloadedWorksheetB2c,
    reachedDailyLimitBackend
}

export default PrintableWorksheetsEvents;