import { fontGraphie } from "../../../../src/teacherDashboard/styles/fonts";
import styled from "styled-components";

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #090A4AB2 0%, #020533 100%);;
  z-index: 110;
`;

export const PopupWrapper = styled.div`
    background-color: #34389F;
    color: #ffffff;
    width: 660px;
    height: 450px;
    border-radius: 24px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 40px 30px 16px;
`;

export const CrossButton = styled.div`
    position: absolute;
    top: 0;
    right: -44px;
    text-align: right;
    cursor: pointer;
`;

export const PopupHeader = styled.div`
    ${fontGraphie(32, 42, 7)};
    text-align: center;
    margin-bottom: 30px;
`;

export const PopupDetailsWrapper = styled.div`
    display: flex;
`;

export const WSImageWrapper = styled.div`
    flex: 0 0 48%;
    text-align: right;
`;

export const WSImage = styled.div`
    width: 216px;
    height: 216px;
    margin-right: auto;
    box-sizing: border-box;
    img {
        position: absolute;
        left: 68px;
        top: 130px;
        width: 134px;
        height: 174px;
        object-fit: contain;
        object-position: center;
    }
`;

export const TextWrapper = styled.div`
    flex: 0 0 52%;
`;

export const ListHeader = styled.div`
    ${fontGraphie(20, 26, 7)};
    margin-bottom: 8px;
`;

export const ListBody = styled.div`
    margin-bottom: 26px;
`;

export const ListItem = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

export const ListIcon = styled.div`
    margin-right: 20px;
`;

export const ListText = styled.div`
    ${fontGraphie(16, 24, 4)};
    strong {
        ${fontGraphie(18, 26, 7)};
    }
`;

export const CtaButton = styled.div`
    ${fontGraphie(18, 26, 6)};
    color: #090A4A;
    background: #EDEDF9;
    box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 32px;
    text-align: center;
    padding: 11px 38px;
    margin: 0 auto 8px;
    width: fit-content;
    cursor: pointer;
`;

export const FooterText = styled.div`
    text-align: center;
    ${fontGraphie(14, 20, 4)};
    color: #BDBFF9;
`;