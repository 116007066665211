import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {};

export const teacherProfile = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TEACHER_PROFILE":
            return action.payload || INITIAL_STATE;

        case "UPDATE_TEACHER_PROFILE":
            return {
                ...state,
                ...action.payload,
                teacher: {
                    ...(state?.teacher || {}),
                    ...(action?.payload?.teacher || {})
                }
            }

        case ReducerConstants.UPDATE_TEACHER_SCHOOL:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    school: {
                        ...state.teacher.school,
                        name: action.payload.schoolName,
                        zip_code: action.payload.zipCode
                    }
                }

            }
        case "UPDATE_TEACHER_SHOW_GRID_REPORT_POPUP_FLAG":
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    teacher_show_grid_report_popup_flag: false
                }
            }

        case "UPDATE_ASSIGNMENT_LINK_COACHMARK_SHOWN":
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    assignment_link_coachmark_shown: action.payload
                }
            }
        
        case "UPDATE_MATH_FACT_TOOLTIP_SHOWN_IN_CURRENT_SESSION":
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    math_fact_tooltip_shown_in_current_session: action.payload
                }
            }
        
        
        case "SET_TEACHER_REFERRALS": 
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    referrals: action.payload
                }
            }
        default:
            return state
    }
}