import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    message: "Welcome to Assignment",
    studentList: {},
    studentListArr: [],
    allAssignemntList: {},
    selectedAssignemntDetails: {},
    contentGroups: [],
    standardDetails: [],
    playables: []
};

let parseStudentListToArray = (params) => {
    let returnData = Object.keys(params).map(function (individualData) {
        return {
            user_id: individualData,
            first_name: params[individualData].first_name,
            last_name: params[individualData].last_name,
            fullname: params[individualData].first_name + ' ' + params[individualData].last_name
        }
    });
    return returnData;
}

let randomFunction = (assignmentData, newData) => {
    assignmentData.assignment_info[newData.section].tasks = assignmentData.assignment_info[newData.section].tasks.concat(newData.dataArr);
    return assignmentData;
}

export const assignmentData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "INITIALIZE_ASSIGNMENT_DATA":
            return { ...INITIAL_STATE };
        case "SET_ASSIGNMENT_STUDENT_LIST":
            return { ...state, studentList: action.payload, studentListArr: parseStudentListToArray(action.payload.total_students) }
        case "SET_ALL_ASSIGNEMNT_LIST":
            return { ...state, allAssignemntList: action.payload }
        case "SET_SELECTED_ASSIGNEMNT_DETAILS":
            return { ...state, selectedAssignemntDetails: action.payload }
        case "SET_ASSIGNMENT_CONTENT_GROUPS":
            return { ...state, contentGroups: action.payload }
        case "SET_ASSIGNMENT_STANDARD_DETAILS":
            return { ...state, standardDetails: action.payload }
        case "APPEND_TO_ALL_ASSIGNEMNT_LIST":
            return { ...state, allAssignemntList: randomFunction(state.allAssignemntList, action.payload) }
        case "SET_ASSIGNMENT_PLAYABLES":
            return { ...state, playables: action.payload }
        default:
            return state
    }
}