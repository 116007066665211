import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE: any = {}

export interface IActionProps {
  type: string,
  payload: any
}

export const feedbackEligibility = (state = INITIAL_STATE, action: IActionProps) => {
  switch (action.type) {
    case ReducerConstants.SET_FEEDBACK_ELIGIBILITY:
      return action.payload || {}
    default:
      return state
  }
}
