import React, { useMemo, useState } from "react";
import { deferTask } from "../../../common/common_utility";
import Star from '../../assets/common/starWhite.svg'

/**
 * This is the Button component that will be used in school-ui
 * The following are the props that can be passed to the component
 *
 * @property {String} label The label of the button (Ex: Okay, Save, etc...).
 * @property {ButtonTypes} type The type of button to be rendered.
 * @property {Function?} onClick Pass any function to be executed.
 * @property {Boolean} loading This property will set the state of button, Use this to pass state of api loading/resolved. (loading -> disabled).
 * @property {Number} customTimeout This property can be used to supply a custom timeout during which the button will be disabled.
 * @property {Boolean} disabled This property will set the state of button to be disabled.
 * @property {String} className This property will be used to pass extra classNames for the button for additional customization.
 * @property {Object} style If any, this property can be used to pass additional styles to the button.
 * @property {JSX} children If any, this property will be used to pass the children to the component.
 * @property {Boolean} stopPropagation, this property will decide whether to bubble up the event or not.
 * @property {Boolean} shadow, this property will add new shadow properties to the button component.
 *
 * @Note
 * 1) Each instance of the Button component must contains these props:
 *   a) Either `label` or `children` prop to render the text inside button.
 *   b) `type`: a type from ButtonTypes.
 *   c) Either `onClick` or `disabled` prop to either have a functional or disabled button.
 *
 * 2) By default a timeout period of 2 seconds is there, but user can change this via `customTimeout` prop.
 **/


//Default timeout period in milliseconds
export const DEFAULT_TIMEOUT = 2000;

// ButtonTypes
export const ButtonTypes = {
    primary: "primary-cta-button",
    secondary: "secondary-cta-button",
    star: "primary-cta-button star-btn",
    custom: {
      default: "default-cta-button",
      inline: "inline-cta-button",
      sync: "sync-cta-button"
    }
};

// Component
const Button = ({
  label = "",
  type = "",
  onClick = () => {},
  loading = false,
  customTimeout = null,
  disabled = false,
  className = "",
  style = {},
  stopPropagation = true,
  shadow = false,
  id = '',
  children = null, //to use a js component in tsx  if we have not given a prop a default value, it treats the prop as necessary and otherwise optional.  hence in Button.js i gave default value null to children and customTimeOut
  dataTestId = '',
  viewOnly = false
}) => {

  const [isDisabled, setIsDisabled] = useState(false);

  const callAndDisable = (e, callbackFunction = () => {}, timeout) => {
    stopPropagation ? e.stopPropagation() : null;
    e.preventDefault();
    if(!isDisabled && !viewOnly){
      setIsDisabled(true);
      callbackFunction?.();
      const taskToDefer = () => {
        setIsDisabled(false);
      }
      deferTask({taskToDefer: taskToDefer, timeOut: timeout})
    }
  }

  const classesNames = useMemo(() => {
    let classList = [];
    if(type) {
      classList.push(type);
    }
    if(className){
      classList.push(className)
    }
    if (className === "" && type === "") {
      classList.push(ButtonTypes.primary);
    }
    if(isDisabled || loading || disabled) {
      classList.push('disabled')
    }
    if(viewOnly) {
      classList.push("viewOnly");
    }
    if(shadow){
      classList.push('shadow')
    }
    if(shadow && (isDisabled || loading)) {
      classList.push('shadow-active')
    }

    return classList.join(' ');
  }, [type, className, isDisabled, loading, disabled, shadow, viewOnly]);

  return (
    <span
      className={classesNames}
      style={style || {}}
      onClick={(e) => loading ? null : callAndDisable(e, onClick, customTimeout ? customTimeout : DEFAULT_TIMEOUT)}
      id={id}
      data-test-id={dataTestId}
    >
      {label || ""}
      {children}
      {type === ButtonTypes.star ? <div className="button-star-container"><Star/></div> : null}
    </span>
  );
};

export default Button;
