import { useEffect, useState } from "react";

export const useTopWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.top.innerWidth,
        height: window.top.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.top.innerWidth,
                height: window.top.innerHeight,
            });
        };

        window.top.addEventListener('resize', handleResize);

        return () => {
            window.top.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};