import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    showCustomTestReports: null,
    customTestReports: {},
    pastCustomTestReports: {}
};

export const customTestReport = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SHOW_CUSTOM_TEST_REPORTS:
            return {
                ...state,
                showCustomTestReports: action.payload
            };
        case ReducerConstants.SAVE_CUSTOM_TEST_REPORTS:
            return {
                ...state,
                customTestReports: action.payload
            };
        case ReducerConstants.SAVE_PAST_CUSTOM_TEST_REPORTS:
            return {
                ...state,
                pastCustomTestReports: action.payload
            };
        default:
            return state
    }
}