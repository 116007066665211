import { dispatchAvoEvent } from "avo/index";

export const sendEventUsingAvo = (props: any = {}) => {
    const initParams = {
        //@ts-ignore
        klassGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        currentGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        subjectCode: window.KLASS_DETAIL?.id ? "Math" : '', //@ts-ignore
        klass: window.KLASS_DETAIL,
    };

    dispatchAvoEvent(props, initParams);
};