import { deepCopyObject } from "../../../../common/common_utility";

const MathFactSeedDataInitialState = {
    fact_operators: [],
    fact_groupings: [],
    facts: [],
    fact_proficiencies: [],
    fact_proficiencies_map: {}
}

class MathFactSeedDataModel {
    fact_operators = MathFactSeedDataInitialState.fact_operators;
    fact_groupings = MathFactSeedDataInitialState.fact_groupings;
    facts = MathFactSeedDataInitialState.facts;
    fact_proficiencies = MathFactSeedDataInitialState.fact_proficiencies;
    fact_proficiencies_map = MathFactSeedDataInitialState.fact_proficiencies_map

    __computationCache__ = {
        factGroupingByOperatorCode: {},
        factsByGroupingCode: {},
        factsByOperatorCode: {}
    };

    constructor(seedData = MathFactSeedDataInitialState) {
        this.fact_operators = seedData.fact_operators?.sort((a,b)=>a.ordering - b.ordering);
        this.fact_groupings = seedData.fact_groupings?.sort((a,b)=>a.ordering - b.ordering);
        this.facts = seedData.facts?.sort((a,b)=>a.ordering - b.ordering);
        this.fact_proficiencies = seedData.fact_proficiencies;

        if(this.fact_proficiencies && this.fact_proficiencies.length) {
            this.fact_proficiencies_map = this.fact_proficiencies.reduce((acc, item)=> {
                acc[item.id] = item.code;
                return acc;
            }, {})
        }
    }

    static initialState() {
        return deepCopyObject(MathFactSeedDataInitialState);
    }

    getFactGroupingByOperatorCode = (fact_operator_code) => {
        let factGroupingByOperatorCode = [];
        // check value found in cache
        if(this.__computationCache__.factGroupingByOperatorCode[fact_operator_code]) {
            return this.__computationCache__.factGroupingByOperatorCode[fact_operator_code];
        }
        // calculate value and save in cache
        if(this.fact_groupings && this.fact_groupings.length) {
            factGroupingByOperatorCode = this.fact_groupings.filter(fact_group => fact_group.fact_operator_code === fact_operator_code);
            this.__computationCache__.factGroupingByOperatorCode[fact_operator_code] = factGroupingByOperatorCode;
        }

        return factGroupingByOperatorCode;
    }

    getFactsByGroupingCode = (fact_grouping_code) => {
        let factsByGroupingCode = [];
        // check value found in cache
        if(this.__computationCache__.factsByGroupingCode[fact_grouping_code]) {
            return this.__computationCache__.factsByGroupingCode[fact_grouping_code];
        }
        // calculate value and save in cache
        if(this.facts && this.facts.length) {
            factsByGroupingCode = this.facts.filter(fact => fact.fact_grouping_code === fact_grouping_code);
            this.__computationCache__.factsByGroupingCode[fact_grouping_code] = factsByGroupingCode;
        }

        return factsByGroupingCode;
    }

    getFactsByOperatorCode = (fact_operator_code) => {
        let factsByOperatorCode = {};
        // check value found in cache
        if(this.__computationCache__.factsByOperatorCode[fact_operator_code]) {
            return this.__computationCache__.factsByOperatorCode[fact_operator_code];
        }
        // calculate value and save in cache
        const factGrouping = this.getFactGroupingByOperatorCode(fact_operator_code) || [];
        factsByOperatorCode = factGrouping.reduce((acc, group) => {
            acc[group.code] = this.getFactsByGroupingCode(group.code);
            acc["length"] += acc[group.code].length;
            return acc;
        }, {length: 0});
        this.__computationCache__.factsByOperatorCode[fact_operator_code] = factsByOperatorCode;

        return factsByOperatorCode;
    }
}

export default MathFactSeedDataModel;
